import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import axios from "axios";
import signup from "../images/illustration_members.png";
import loader from "../images/loader.gif";
import Cookies from "universal-cookie";
import DataTable from "react-data-table-component";
import PageLoadingComponent from "../components/PageLoadingComponent";
import { addNewMemberLink } from "../constants/analytics";
import { Event } from "../utils/eventTracking";

const secureApi = axios.create({
  withCredentials: true,
});
const cookie = new Cookies();
const uid = cookie.get("uid");

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};
const columns = [
  {
    name: "Email",
    selector: (row: any) => row.email,
    sortable: true,
    center: true,
  },
  {
    name: "Edit Access",
    selector: (row: any) => row.adminAccess,
    center: true,
  },
  {
    name: "Date",
    selector: (row: any) => row.createdAt,
    sortable: true,
    center: true,
  },
];
const PendingMembers = () => {
  const [members, setMembers] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    ReactGA.pageview("/pending-members");
    setShowLoader(true);
    secureApi
      .post(`${process.env.REACT_APP_API_URL}/api/org_member/list`, { uid })
      .then((list) => {
        const membersData = list.data;
        const membersList = membersData.map((item: any) => {
          return {
            email: item.email,
            adminAccess: item.adminAccess ? "Given" : "Not Given",
            createdAt: new Date(item.createdAt).toUTCString(),
          };
        });
        setMembers(membersList);
        setShowLoader(false);
      });
  }, []);

  return (
    <Fragment>
      {showLoader ? (
        <PageLoadingComponent />
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2 h-auto">
            <SideBarType1 image={signup} url="/dashboard" />
          </div>
          <div className="col-span-5 md:col-span-4">
            <div className="w-5/6 h-auto m-auto top-12 p-4">
              <h2 className="text-2xl md:text-3xl text-black font-bold">
                MEMBERS NOT YET JOINED{" "}
              </h2>
              <DataTable
                title=""
                columns={columns}
                data={members}
                pagination={true}
                customStyles={customStyles}
                striped={true}
              />
              <div className="flex w-5/6  md:w-4/6 pt-6">
                <Link
                  to="/add-member"
                  className="underline font-semibold"
                  onClick={() =>
                    Event(
                      addNewMemberLink.category,
                      addNewMemberLink.action,
                      addNewMemberLink.label
                    )
                  }
                >
                  Add New Member
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PendingMembers;
