import {
  ADD_MEMBER,
  GET_MEMBERS,
  LOAD_MEMBERS,
  SHOW_MEMBERS_MODAL,
  SAVE_MEMBERS,
  SUCCESS_MESSAGE,
  ERROR_OCCURED,
  ERROR_MESSAGE_DELETE_MEMBER,
  LOADING_DELETE_MEMBER,
  DELETE_MEMBER,
} from "../constants/members";

const initialState = {
  newMember: {},
  members: [],
  workspaceName: "",
  membersLoading: false,
  creator: false,
  showModal: false,
  saveMembers: false,
  errorMessage: "",
  successMessage: "",
  loadingMemberDelete: false,
  errorDeletingMember: "",
  currentLoggedInMemberEmail: "",
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ADD_MEMBER:
      return {
        ...state,
        newMember: action.payload,
      };
    case GET_MEMBERS:
      return {
        ...state,
        members: action.payload.users,
        workspaceName: action.payload.org_name,
        membersLoading: false,
        saveMembers: false,
        creator: action.payload.creator,
        errorMessage: "",
        successMessage: "",
        errorMessageDeleteMember: "",
        currentLoggedInMemberEmail: action.payload.loggedInUserEmail,
      };
    case LOAD_MEMBERS:
      return {
        ...state,
        membersLoading: !state.membersLoading,
      };
    case SAVE_MEMBERS:
      return {
        ...state,
        saveMembers: !state.saveMembers,
      };
    case SHOW_MEMBERS_MODAL:
      return {
        ...state,
        showModal: !state.showModal,
      };
    case SUCCESS_MESSAGE:
      return {
        ...state,
        errorMessage: "",
        successMessage: action.payload,
        membersLoading: false,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        successMessage: "",
        errorMessage: action.payload,
        membersLoading: false,
        saveMembers: false,
      };
    case LOADING_DELETE_MEMBER:
      return {
        ...state,
        loadingMemberDelete: action.payload,
      };
    case ERROR_MESSAGE_DELETE_MEMBER:
      return {
        ...state,
        errorDeletingMember: action.payload,
        loadingMemberDelete: false,
      };
    case DELETE_MEMBER:
      return {
        ...state,
        members: action.payload,
        loadingMemberDelete: false,
      };
    default:
      return state;
  }
}
