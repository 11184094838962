import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  MEMBERS_FOLDER,
  MEMBERS,
  SAVE_CHANGES,
  DELETE,
  VIEW_ACCESS,
  EDIT_ACCESS,
  CREATOR,
} from "../constants/response";
import {
  getMembers,
  updateFolderMembers,
  deleteFolderWithSheets,
} from "../actions/memberActions";
import { RootState } from "../reducers";
import { SHOW_MEMBERS_MODAL, SAVE_MEMBERS } from "../constants/members";
import { FOLDER_DELETE_CONFIRM } from "../constants/response";

import closeIcon from "../images/close_icon_dark.svg";
import loader from "../images/loader.gif";
import { SELECTED_SHEET } from "../constants/workspace";

const SmallModal: React.FC = () => {
  const dispatch = useDispatch();
  const [groupMembers, setGroupMembers] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  let folderMembersInfo = new Map();
  useEffect(() => {
    getMembers(dispatch);
  }, []);
  let allMembers: any = [];

  const inputChecked = () => {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    for (let i = 0; i < checkboxes.length; i++) {
      const member = checkboxes[i].getAttribute("name");
      allMembers.push(member);
    }
    setGroupMembers(allMembers);
  };
  const users: any = useSelector((state: RootState) => state.member.members);
  const error: string = useSelector(
    (state: RootState) => state.member.errorMessage
  );
  const showModal = useSelector((state: RootState) => state.member.showModal);
  const memberSaving = useSelector(
    (state: RootState) => state.member.saveMembers
  );
  const adminAccess = useSelector(
    (workspace: RootState) => workspace.folder.adminAccess
  );
  const currentLoggedInUserEmail = useSelector(
    (state: RootState) => state.member.currentLoggedInMemberEmail
  );
  const creatorAccess = useSelector(
    (workspace: RootState) => workspace.folder.creatorAccess
  );
  let folders = useSelector(
    (workspace: RootState) => workspace.folder.foldersWithSheets
  );
  let folderId = useSelector(
    (workspace: RootState) => workspace.folder.selectedFolderForMembers
  );
  let folder, folderMembers;
  if (folderId) {
    folder = folders.find((item: any) => item.folder_id === folderId);
    folderMembers = folder.members;
    folderMembers.map((member: string) => {
      folderMembersInfo.set(member, member);
    });
  }
  const saveGroupMembers = async () => {
    dispatch({
      type: SAVE_MEMBERS,
    });
    setButtonDisabled(true);
    const membersDataFolder = {
      members: groupMembers,
      folderId: folderId,
    };
    updateFolderMembers(dispatch, membersDataFolder, folders);
    setButtonDisabled(false);
  };
  const deleteFolder = async () => {
    const result = window.confirm(FOLDER_DELETE_CONFIRM);
    if (result) {
      dispatch({
        type: SAVE_MEMBERS,
      });

      deleteFolderWithSheets(dispatch, folderId, folders);
      const sheetFolderId = {
        folder_id: "",
        sheet_id: "",
      };
      dispatch({
        type: SELECTED_SHEET,
        payload: sheetFolderId,
      });
    }
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-md">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex flex-col items-start justify-between p-3 border-b border-solid border-blueGray-200 rounded-t">
                  <div className="grid grid-cols-12">
                    <div className="col-span-11 ">
                      <h3 className="text-2xl font-semibold text-blue-500">
                        {MEMBERS}
                      </h3>
                    </div>
                    <div className="col-span-1 border-2 border-white">
                      <div className="block ml-auto">
                        <img
                          src={closeIcon}
                          alt="wecest modal close"
                          className="w-8 cursor-pointer p-1"
                          onClick={() => dispatch({ type: SHOW_MEMBERS_MODAL })}
                        />
                      </div>
                    </div>
                  </div>
                  <small className="mt-2 text-gray-700 text-xs">
                    {MEMBERS_FOLDER}
                  </small>
                  <span className="text-red-500 font-medium text-sm">
                    {error}
                  </span>
                </div>
                {users.length ? (
                  <div className="relative p-6 flex-auto">
                    <div className="block h-52 overflow-auto">
                      {users.map((member: any, index: number) => (
                        <div className="grid grid-cols-12 mt-2" key={index}>
                          <div className="col-span-1">
                            <input
                              type="checkbox"
                              name={member.email}
                              defaultChecked={
                                folderMembersInfo.get(member.email)
                                  ? true
                                  : false
                              }
                              disabled={
                                !adminAccess ||
                                member?.email === currentLoggedInUserEmail
                                  ? true
                                  : false
                              }
                              onChange={inputChecked}
                            />
                          </div>
                          <div className="col-span-4">
                            <p className="pr-2 text-sm text-gray-600">
                              {member.first_name + " " + member.last_name}
                            </p>
                          </div>
                          <div className="col-span-6">
                            <p className="pr-2 text-sm text-gray-600">
                              {member.email}
                            </p>
                          </div>
                          <div className="col-span-1">
                            <p className="pr-2 text-gray-700">
                              {member.creator
                                ? CREATOR
                                : member.adminAccess
                                ? EDIT_ACCESS
                                : VIEW_ACCESS}
                            </p>
                          </div>
                        </div>
                      ))}

                      <div className="flex justify-start mt-2">
                        {creatorAccess ? (
                          <Link to="/add-member" className="underline">
                            Add Member
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center ">
                    <img
                      src={loader}
                      alt="wecest members loader"
                      className="w-8 py-5"
                    />
                  </div>
                )}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  {memberSaving ? (
                    <span>
                      <img
                        src={loader}
                        alt="Wecest members loading folder"
                        className="w-7"
                      />
                      <small>Saving..</small>
                    </span>
                  ) : null}
                  {adminAccess ? (
                    <>
                      <button
                        className={`text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 `}
                        type="button"
                        onClick={deleteFolder}
                      >
                        {DELETE}
                      </button>
                      <button
                        className={`bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${
                          buttonDisabled
                            ? "opacity-25 cursor-default"
                            : "cursor-pointer"
                        }`}
                        type="button"
                        onClick={saveGroupMembers}
                        disabled={buttonDisabled}
                      >
                        {SAVE_CHANGES}
                      </button>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default SmallModal;
