import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logoutUser } from "../actions/userActions";
import $ from "jquery";
import ReactGA from "react-ga";

import CardTasks from "../components/CardTasks";
import SelectImageQuote from "../components/selectImageQuote";
import SideBarType2 from "../components/SideBar2";
import MembersModal from "../components/MembersModal";
import NoSheetSelected from "../components/NoSheet";
import { RootState } from "../reducers";
import Cookies from "universal-cookie";
import { updateSheetDescription } from "../actions/folderActions";
import { SEARCH_TEXT } from "../constants/task";
import {
  SHEET_DESCRIPTION_CHANGED,
  SELECTED_SHEET,
} from "../constants/workspace";

import loader from "../images/loader.gif";
import searchIcon from "../images/search_icon.svg";
import loadTasks from "../images/sound-waves.png";
import PlanExpiredComponent from "../components/PlanExpiredComponent";
import PlanUpgradeComponent from "../components/PlanUpgradeComponent";
import PageLoadingComponent from "../components/PageLoadingComponent";

const Dashboard = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [sheetDescription, setSheetDescription] = useState("");
  const [taskDescriptionChanged, setTaskDescriptionChanged] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);

  const description = useSelector(
    (state: RootState) => state.folder.sheetDescription
  );
  const sheetDescriptionChanged = useSelector(
    (state: RootState) => state.folder.sheetDescriptionChanged
  );
  const searchTermValue = useSelector(
    (state: RootState) => state.task.searchTerm
  );
  const { sidebarCollapsed } = useSelector(
    (state: RootState) => state.workspace
  );

  const tasksLoading = useSelector(
    (state: RootState) => state.task.tasksLoading
  );
  const adminAccess = useSelector(
    (workspace: RootState) => workspace.folder.adminAccess
  );
  const folders = useSelector(
    (workspace: RootState) => workspace.folder.foldersWithSheets
  );

  const dataSaving = useSelector(
    (workspace: RootState) => workspace.folder.dataLoader
  );
  const selectedSheet = useSelector(
    (workspace: RootState) => workspace.folder.selectedSheet
  );
  const errorMessage = useSelector(
    (workspace: RootState) => workspace.folder.errorMessage
  );

  const planExpiryDate = useSelector(
    (workspace: RootState) => workspace.folder.planExpiryDate
  );

  const creatorAccess = useSelector(
    (workspace: RootState) => workspace.folder.creatorAccess
  );

  const creatorEmail = useSelector(
    (workspace: RootState) => workspace.folder.creatorEmail
  );
  const noOfTasks = useSelector(
    (workspace: RootState) => workspace.folder.no_of_tasks
  );
  const noOfMembers = useSelector(
    (workspace: RootState) => workspace.folder.no_of_members
  );
  const planType = useSelector(
    (workspace: RootState) => workspace.folder.plan_type
  );

  const [menuOpened, setMenuOpened] = useState(false);
  $(document).mouseup(() => {
    setMenuOpened(false);
  });
  useEffect(() => {
    ReactGA.pageview("/dashboard");
    dispatch({
      type: SELECTED_SHEET,
      payload: "",
    });
    setIsPageLoading(false);
  }, []);

  const dispatchSearchTerm = async () => {
    dispatch({
      type: SEARCH_TEXT,
      payload: searchTerm,
    });
  };

  const userLogout = async () => {
    setIsLoading(true);
    setErrorResponse("");
    const userLoggedout = await logoutUser(dispatch);
    setIsLoading(false);
    if (userLoggedout.status === 200) {
      history.push("/");
    } else {
      setErrorResponse(userLoggedout.message);
    }
  };
  return (
    <Fragment>
      {isPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <div className="grid grid-cols-12 ">
          <div
            className={`col-span-3  sidebar-transition min-h-screen ${
              sidebarCollapsed ? "md:col-span-1" : "md:col-span-2"
            } `}
          >
            <SideBarType2 />
          </div>
          <MembersModal />
          <div
            className={`col-span-9  w-full ${
              sidebarCollapsed ? "md:col-span-11" : "md:col-span-10"
            }`}
            style={{ background: "#f5f5f5" }}
          >
            {!selectedSheet ? (
              <NoSheetSelected />
            ) : (
              <>
                <div className="block w-full h-auto p-3">
                  <>
                    {tasksLoading ? (
                      <div className="flex justify-center items-center h-screen">
                        <PageLoadingComponent />
                      </div>
                    ) : (
                      <>
                        <div className="grid grid-cols-12  z-auto ">
                          <div className="col-span-11">
                            <div className="grid grid-cols-12">
                              <div className="col-span-10 md:col-span-8">
                                <input
                                  className="font-bold text-xs md:text-3xl text-gray-600 uppercase focus:outline-none bg-transparent w-full"
                                  readOnly={!adminAccess}
                                  defaultValue={
                                    !sheetDescriptionChanged
                                      ? description
                                      : sheetDescription
                                  }
                                  onChange={(e) => {
                                    setSheetDescription(e.target.value);
                                    dispatch({
                                      type: SHEET_DESCRIPTION_CHANGED,
                                    });
                                  }}
                                  onBlur={() =>
                                    updateSheetDescription(
                                      {
                                        sheetId: selectedSheet,
                                        sheet_description: sheetDescription,
                                      },
                                      dispatch,
                                      folders
                                    )
                                  }
                                />
                              </div>
                              <div className="sm:col-span-2 md:col-span-1">
                                {dataSaving ? (
                                  <span className="flex text-sm justify-start">
                                    <img
                                      src={loader}
                                      className="w-8 h-8 mr-2"
                                      alt="wecest loader"
                                    />
                                  </span>
                                ) : null}
                              </div>
                              <div className="sm:col-span-0 md:col-span-3">
                                <div className="flex p-2">
                                  <input
                                    type="text"
                                    name="search-text"
                                    className="border-b-2 border-gray-400 bg-transparent w-full focus:outline-none"
                                    placeholder="Search Tasks"
                                    onChange={(e: any) =>
                                      setSearchTerm(e.target.value)
                                    }
                                    onKeyUp={dispatchSearchTerm}
                                  />
                                  {!searchTermValue ? (
                                    <img
                                      src={searchIcon}
                                      alt="search icon wecest"
                                      className="w-6 transition ease-in duration-75"
                                      id="task-search"
                                    />
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <div
                              className="m-auto text-center text-white font-extrabold text-sm md:text-lg w-7 md:w-10 bg-yellow-300 p-1 md:p-2 -mt-1 rounded-3xl cursor-pointer"
                              onClick={() => {
                                setMenuOpened(!menuOpened);
                              }}
                            >
                              H
                            </div>
                          </div>
                        </div>
                        <div
                          className={`flex justify-end  ${
                            !menuOpened ? "hidden" : ""
                          }`}
                        >
                          <div className="block rounded-lg p-4 text-gray-600 shadow-xl bg-white border-gray-300 font-medium z-50 w-48 text-center absolute -mt-4 md:-mt-0 mr-0 md:mr-5">
                            <div className="flex w-full justify-center border-b-2 border-gray-200 py-2">
                              <Link to="/account-info" className="">
                                Account
                              </Link>
                            </div>
                            <div className="flex w-full justify-center border-b-2 border-gray-200 py-2">
                              <Link to="/members" className="">
                                Group Members
                              </Link>
                            </div>
                            {creatorAccess && (
                              <div className="flex w-full justify-center border-b-2 border-gray-200 py-2">
                                <Link to="/add-member">Add Member</Link>
                              </div>
                            )}
                            <div
                              className="flex w-full justify-center pt-2 cursor-pointer"
                              onClick={userLogout}
                            >
                              <span>Logout</span>
                            </div>
                          </div>
                        </div>
                        <CardTasks />
                      </>
                    )}
                  </>
                </div>
              </>
            )}
            {planType !== "FREE" && new Date(planExpiryDate) < new Date() ? (
              <PlanExpiredComponent
                creatorAccess={creatorAccess}
                creatorEmail={creatorEmail}
              />
            ) : null}
            {planType === "FREE" && Number(noOfTasks) > 200 ? (
              <PlanUpgradeComponent
                creatorAccess={creatorAccess}
                creatorEmail={creatorEmail}
              />
            ) : null}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Dashboard;
