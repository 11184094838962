import axios from "axios";
import {
  ADD_FOLDER,
  UPDATE_FOLDER,
  GET_FOLDERS,
  ADD_SHEET,
  SAVE_DATA,
  UPDATE_SHEET,
  DELETE_SHEET,
  ERROR_OCCURED,
} from "../constants/workspace";
import { API_FAILURE, COOKIE_ALTERED } from "../constants/response";
import _ from "lodash";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const secureApi = axios.create({
  withCredentials: true,
});

export const addFolder: any = async (
  dispatch: any,
  foldersWithSheets: any,
  folderData: any
) => {
  try {
    const uid = cookies.get("uid");
    folderData["uid"] = uid;
    dispatch({
      type: SAVE_DATA,
    });

    const {
      data: { folderCreated },
    } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/folder/register`,
      folderData
    );
    delete folderData.uid;
    const folder = foldersWithSheets.find(
      (item: any) => item.f_id === folderCreated.f_id
    );
    folder.folder_id = folderCreated.folder_id;
    folder.folder_name = folderCreated.folder_name;
    folder.members = folderCreated.members;
    folder.f_id = "";
    dispatch({
      type: ADD_FOLDER,
      payload: foldersWithSheets,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const updateFolder: any = async (
  dispatch: any,
  foldersWithSheets: any,
  folderData: any
) => {
  try {
    const uid = cookies.get("uid");
    folderData["uid"] = uid;
    dispatch({
      type: SAVE_DATA,
    });
    const {
      data: { folderUpdated },
    } = await secureApi.patch(
      `${process.env.REACT_APP_API_URL}/api/folder/edit`,
      folderData
    );
    delete folderData.uid;
    const folder = foldersWithSheets.find(
      (item: any) => item.folder_id === folderUpdated.folder_id
    );
    folder.folder_name = folderUpdated.folder_name;
    dispatch({
      type: UPDATE_FOLDER,
      payload: foldersWithSheets,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const getFolders: any = async (dispatch: any) => {
  try {
    const uid = cookies.get("uid");
    const {
      data: {
        data,
        adminAccess,
        planExpiryDate,
        creator,
        creatorEmail,
        no_of_tasks,
        plan_type,
        no_of_members,
      },
    } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/folder/list`,
      { uid }
    );
    let sortedFoldersDescendingOrder = data.sort((d1: any, d2: any) =>
      d2.createdAt > d1.createdAt ? 1 : -1
    );
    const folderData = {
      folders: sortedFoldersDescendingOrder,
      adminAccess,
      planExpiryDate,
      creator,
      creatorEmail,
      no_of_tasks,
      plan_type,
      no_of_members,
    };
    dispatch({
      type: GET_FOLDERS,
      payload: folderData,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const newSheet: any = async (
  dispatch: any,
  foldersWithSheets: any,
  sheetData: any
) => {
  try {
    const uid = cookies.get("uid");
    sheetData["uid"] = uid;
    sheetData["description"] = "Task's List";
    dispatch({
      type: SAVE_DATA,
    });

    const { data } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/sheet/register`,
      sheetData
    );

    delete sheetData.uid;
    const folder = foldersWithSheets.find(
      (item: any) => item.folder_id === sheetData.folder_id
    );
    const sheets = folder.Sheets;
    let sheet = sheets.find(
      (item: any) => item.s_id === data.sheetCreated.s_id
    );

    sheet.sheet_id = data.sheetCreated.sheet_id;
    sheet.sheet_name = data.sheetCreated.sheet_name;
    sheet.s_id = "";

    dispatch({
      type: ADD_SHEET,
      payload: foldersWithSheets,
    });
  } catch (error) {
    console.log(error);
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const updateSheet = async (
  dispatch: any,
  sheetData: any,
  folders: any
) => {
  try {
    const uid = cookies.get("uid");
    sheetData["uid"] = uid;
    dispatch({
      type: SAVE_DATA,
    });

    const {
      data: { sheetUpdated },
    } = await secureApi.patch(
      `${process.env.REACT_APP_API_URL}/api/sheet/edit`,
      sheetData
    );
    delete sheetData.uid;
    const folder = folders.find(
      (item: any) => item.folder_id === sheetUpdated.folder_id
    );
    const sheets = folder.Sheets;
    sheets.find((item: any) =>
      item.sheet_id === sheetUpdated.sheet_id
        ? (item.sheet_name = sheetUpdated.sheet_name)
        : null
    );
    dispatch({
      type: UPDATE_SHEET,
      payload: folders,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const deleteSheet = async (
  dispatch: any,
  sheetId: string,
  folderId: string,
  folders: any
) => {
  try {
    const uid = cookies.get("uid");
    dispatch({
      type: SAVE_DATA,
    });
    const { data } = await secureApi.delete(
      `${process.env.REACT_APP_API_URL}/api/sheet/delete/${sheetId}`,
      {
        data: { uid },
      }
    );
    const folder = folders.find((item: any) => item.folder_id === folderId);
    const sheets = folder.Sheets;
    sheets.find((item: any, index: number) =>
      item.sheet_id === sheetId ? sheets.splice(index, 1) : null
    );
    dispatch({
      type: DELETE_SHEET,
      payload: folders,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const updateSheetDescription = async (
  sheetData: any,
  dispatch: any,
  folders: any
) => {
  try {
    const uid = cookies.get("uid");
    dispatch({
      type: SAVE_DATA,
    });
    sheetData["uid"] = uid;

    const {
      data: { sheetUpdated },
    } = await secureApi.patch(
      `${process.env.REACT_APP_API_URL}/api/sheet/edit`,
      sheetData
    );
    const folder = folders.find(
      (item: any) => item.folder_id === sheetUpdated.folder_id
    );
    const sheets = folder.Sheets;
    sheets.forEach((item: any) =>
      item.sheet_id === sheetUpdated.sheet_id
        ? (item.sheet_description = sheetUpdated.sheet_description)
        : null
    );
    dispatch({
      type: UPDATE_SHEET,
      payload: folders,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};
