import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import Navbar from "../components/Navbar";
import { verifyUserLoggedIn } from "../actions/userActions";
import { RootState } from "../reducers";

import workingImage from "../images/illustration_work.svg";
import meetingImage from "../images/illustration_meeting.svg";
import workflowImage from "../images/workflow.gif";
import thumbnailImage from "../images/thumbnail.png";
import PricingComponent from "../components/Pricing";
import TalkToUs from "../components/TalktoUs";
import Footer from "../components/FooterComponent";
import { CURRENT_PAGE_LOADING } from "../constants/user";
import PageLoadingComponent from "../components/PageLoadingComponent";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { Event } from "../utils/eventTracking";
import {
  iconHoveredHomepage,
  openDashboard,
  tryForFree,
  dashboardFlowImage,
  getDetailInformationFeatures,
} from "../constants/analytics";
import axios from "axios";

const cookie = new Cookies();
const HomePage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    dispatch({
      type: CURRENT_PAGE_LOADING,
    });
    ReactGA.pageview("/");
    verifyUserLoggedIn(dispatch);
    const queryParamString: string = location?.search.substring(1) || "";
    if (queryParamString) {
      const cookieName = queryParamString.split("=").pop();
      cookie.set("discount", cookieName);
    }
  }, []);

  // Check whether user is loggedin
  const userLoggedin = useSelector((state: RootState) => state.user.isLoggedin);
  const currentPageLoading = useSelector(
    (state: RootState) => state.user.currentPageLoading
  );

  return (
    <>
      {currentPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <Fragment>
          <Navbar userLoggedin={userLoggedin} />
          <div className="flex flex-wrap bg-wecest-pink w-full h-screen ">
            <div className="w-full lg:w-4/6 xl:w-4/6 2xl:w-4/6">
              <div className="flex flex-col md:mt-4 mt-8 lg:mt-0 xl:mt-0 2xl:mt-0 lg:h-screen xl:h-screen 2xl:h-screen justify-center px-8 ">
                <span className="text-4xl lg:text-8xl md:text-7xl xl:text-8xl 2xl:text-8xl font-bold text-gray-100 ">
                  <span> We help you </span>
                  <span>
                    <img
                      src={workingImage}
                      alt="working illustration"
                      className="md:w-64 lg:w-64 xl:w-64 2xl:w-64 w-20 inline-block"
                    />
                  </span>{" "}
                  <span className="text-white ">Save time</span> in your
                  <span className="lg:leading-snug leading-normal">
                    {" "}
                    Daily work!
                  </span>
                </span>
                {userLoggedin ? (
                  <Link
                    to="/dashboard"
                    className="button-type-1 lg:mt-12 lg:mb-4 xl:mt-12 xl:mb-4 2xl:mt-12 2xl:mb-4 mt-8 transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                    onClick={() =>
                      Event(
                        openDashboard.category,
                        openDashboard.action,
                        openDashboard.label
                      )
                    }
                  >
                    Go to Workspace
                  </Link>
                ) : (
                  <Link
                    to="/signup"
                    className="button-type-1 lg:mt-12 lg:mb-4 xl:mt-12 xl:mb-4 2xl:mt-12 2xl:mb-4 mt-8 transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                    onClick={() =>
                      Event(
                        tryForFree.category,
                        tryForFree.action,
                        tryForFree.label
                      )
                    }
                  >
                    Try For Free
                  </Link>
                )}
              </div>
            </div>
            <div className="w-full lg:w-2/6">
              <div className="flex justify-center items-center lg:h-screen xl:h-screen 2xl:h-screen">
                <img
                  src={meetingImage}
                  alt="meeting illustration"
                  className="lg:w-96 lg:mr-16 xl:w-96 xl:mr-16 2xl:w-96 2xl:mr-16"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-4 h-full md:h-screen">
              <div className="bg-audotask-gold h-full py-20 md:py-0">
                <div className="flex flex-col justify-center items-center h-full w-full p-2">
                  <h1 className="font-light text-3xl md:text-6xl  text-light-gray italic">
                    Communicate
                  </h1>
                  <h1 className="font-medium text-5xl md:text-6xl text-center text-light-gray pt-2 leading-snug py-8">
                    Your Thoughts
                  </h1>
                  <h1 className="font-medium text-5xl md:text-6xl text-center text-light-gray -mt-6 md:-mt-4">
                    With Clarity
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-8">
              <div className="grid grid-cols-12">
                <div className="row-span-full  col-span-4 col-start-1 self-center">
                  <div className="bg-audotask-gold h-full md:h-screen"></div>
                </div>
                <div className="row-span-full col-span-8 col-start-2 col-end-12">
                  <div className="flex items-center h-full">
                    <img
                      src={workflowImage}
                      alt="worflow image audotask"
                      className="w-full md:shadow-lg h-64 md:h-4/6 -mt-12 md:mt-0"
                      onMouseOver={() =>
                        Event(
                          dashboardFlowImage.category,
                          dashboardFlowImage.action,
                          dashboardFlowImage.label
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Perfect for teams working remotely */}
          <div className="block h-auto md:h-screen w-full">
            <div className="flex justify-start p-6 py-5">
              <h1 className="text-light-gray text-3xl md:text-6xl font-medium">
                Perfect For The Teams Working Remotely
              </h1>
            </div>
            <div className="flex justify-center w-full h-full p-6 ">
              <iframe
                width="90%"
                height="90%"
                src="https://www.youtube-nocookie.com/embed/D6i-xmRWExg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer;"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <PricingComponent />
          <div className="flex justify-center p-5">
            <Link
              to="/features"
              className="underline cursor-pointer"
              onClick={() => {
                Event(
                  getDetailInformationFeatures.category,
                  getDetailInformationFeatures.action,
                  getDetailInformationFeatures.label
                );
              }}
            >
              Get Detailed Information about Features Here
            </Link>
          </div>
          <TalkToUs />
          <Footer />
        </Fragment>
      )}
    </>
  );
};

export default HomePage;
