import React, { useEffect, useState } from "react";
import tooltipIcon from "../images/tooltip-icon.png";
import ReactTooltip from "react-tooltip";

interface tooltipProps {
  text: string;
  label: string;
}

const TooltipComponent: React.FC<tooltipProps> = ({ text, label }) => {
  return (
    <>
      <img
        src={tooltipIcon}
        alt="audotask tooltip icon"
        className="w-5 cursor-pointer"
        data-tip
        data-for={label}
      />
      <ReactTooltip
        id={label}
        place="right"
        effect="solid"
        border={true}
        type="light"
        className="tooltip"
      >
        <p>{text}</p>
      </ReactTooltip>
    </>
  );
};

export default TooltipComponent;
