// Labels for High, medium & Low priority

import React, { useEffect, useState } from "react";

interface labelType {
  labelText?: string;
}

const LabelType: React.FC<labelType> = ({ labelText }) => {
  const [labelColor, setLabelColor] = useState("");
  useEffect(() => {
    switch (labelText) {
      case "Low Priority":
        setLabelColor("bg-yellow-500");
        break;
      case "Medium Priority":
        setLabelColor("bg-purple-500");
        break;
      case "High Priority":
        setLabelColor("bg-red-400");
        break;
      default:
        break;
    }
  });
  return (
    <>
      {labelText ? (
        <div className={`rounded-full py-1 md:py-2 px-6 ${labelColor}`}>
          <span className="font-bold text-white text-sm">{labelText}</span>
        </div>
      ) : null}
    </>
  );
};

export default LabelType;
