import React, { useEffect, useState } from "react";
import logo from "../images/icon_white.png";
import verifiedBadge from "../images/verified_badge.png";

interface logoStyle {
  logoColor: string;
  textColor: string;
}

const CustomLogoComponent: React.FC<logoStyle> = ({ logoColor, textColor }) => {
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex w-full text-gray-500 items-center">
          <h1 className="text-2xl md:text-3xl font-bold ">AUD</h1>
          <span
            className={`flex justify-center items-center rounded-3xl md:text-3xl w-10 h-10 p-2 ${logoColor}`}
          >
            <img
              src={logo}
              className="spin-animation cursor-pointer"
              alt="wecest logo"
            />
          </span>
          <h1 className="text-2xl md:text-3xl font-bold">TASK</h1>
          <h1 className={`text-2xl md:text-3xl font-bold ${textColor}`}>
            &nbsp;PRO+
          </h1>
        </div>
        <div>
          <img src={verifiedBadge} alt="audotask verified" className="w-full" />
        </div>
      </div>
    </>
  );
};

export default CustomLogoComponent;
