import React, { Fragment, ReactElement, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";

import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import checkEmail from "../utils/isEmail";
import { API_FAILURE } from "../constants/response";

import ForgotPasswordImage from "../images/illustration_forgotpassword.svg";
import loader from "../images/loader.gif";
import {
  forgotPassword,
  accountVerifyLinkonForgotPasswordPage,
} from "../constants/analytics";
import { Event } from "../utils/eventTracking";

const ForgotPassword: React.FC = (): ReactElement => {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [
    forgotPasswordSuccessNotification,
    setforgotPasswordSuccessNotification,
  ] = useState(false);
  const [
    forgotPasswordFailedNotification,
    setforgotPasswordFailedNotification,
  ] = useState(false);

  const [forgotPasswordResponse, setforgotPasswordResponse] = useState("");

  useEffect(() => {
    checkEmail(email) ? setButtonDisabled(false) : setButtonDisabled(true);
  });
  useEffect(() => {
    ReactGA.pageview("/forgot-password");
  }, []);

  const validateEmail = (e: any) => {
    setEmail(e.target.value);
    const emailCorrect = checkEmail(email);
    if (emailCorrect) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const sendPasswordResetLink = async () => {
    try {
      Event(
        forgotPassword.category,
        forgotPassword.action,
        forgotPassword.label
      );
      setforgotPasswordFailedNotification(false);
      setforgotPasswordSuccessNotification(false);
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/forgot-password`,
        { email }
      );
      setIsLoading(false);
      setforgotPasswordSuccessNotification(true);
      setforgotPasswordResponse(data.message);
    } catch (error) {
      const message = "";
      setIsLoading(false);
      setforgotPasswordFailedNotification(true);
      setforgotPasswordResponse(message);
    }
  };

  return (
    <Fragment>
      <div className="grid grid-cols-6">
        <div className="col-span-1 md:col-span-2">
          <SideBarType1 image={ForgotPasswordImage} url="/" />
        </div>
        <div className="col-span-5 md:col-span-4">
          <div className="w-full h-screen flex flex-col justify-center items-center top-12 p-4">
            <h2 className="text-xl md:text-3xl text-black font-bold">
              RESET YOUR PASSWORD
            </h2>
            <p className="text-lg text-center text-gray-700 font-bold pt-4">
              We will send you secure password reset link on your email address
            </p>
            <div className="flex flex-wrap w-5/6  md:w-4/6  pt-12">
              <label htmlFor="" className="text-xl font-medium text-gray-500">
                Email
              </label>

              <input
                type="text"
                className="border-2 border-gray-200  p-2 w-full focus:outline-none focus:ring focus:border-blue-400"
                placeholder="Enter email address..."
                value={email}
                onChange={validateEmail}
                onSelect={validateEmail}
                name="email"
              />
            </div>
            <div className="flex w-5/6  md:w-4/6">
              <button
                className={`button-type-2 mt-2 w-full focus:outline-none  ${
                  buttonDisabled || isLoading
                    ? "cursor-default opacity-50"
                    : "cursor-pointer"
                }`}
                disabled={buttonDisabled || isLoading}
                onClick={sendPasswordResetLink}
              >
                SEND EMAIL
              </button>
            </div>
            <div className="flex w-5/6  md:w-4/6 pt-6">
              {!isLoading ? (
                <Link
                  to="/verify-account"
                  className="underline font-semibold"
                  onClick={() =>
                    Event(
                      accountVerifyLinkonForgotPasswordPage.category,
                      accountVerifyLinkonForgotPasswordPage.action,
                      accountVerifyLinkonForgotPasswordPage.label
                    )
                  }
                >
                  Need to Verify Account ? Click here
                </Link>
              ) : (
                <img src={loader} alt="wecest loader" className="w-12 m-auto" />
              )}
            </div>
            <div className="flex w-5/6  md:w-4/6 pt-6">
              {forgotPasswordSuccessNotification ? (
                <SuccessResponse response={forgotPasswordResponse} />
              ) : forgotPasswordFailedNotification ? (
                <FailureResponse response={forgotPasswordResponse} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
