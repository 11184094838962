import React from "react";
import { Link } from "react-router-dom";
import upgradePlanImage from "../images/audotask-upgrade.svg";

interface planUpgradeProps {
  creatorAccess: boolean;
  creatorEmail: string;
}

const PlanUpgradeComponent: React.FC<planUpgradeProps> = ({
  creatorAccess,
  creatorEmail,
}) => {
  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-full my-6 mx-auto max-w-sm md:max-w-4xl h-auto">
        <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-white outline-none focus:outline-none p-12">
          <div className="flex justify-center items-center flex-col">
            <img
              src={upgradePlanImage}
              alt="Plan Upgrade Image"
              className="w-full"
            />
            <div className="block mt-7 text-sm">
              <p className="text-gray-700">We know you’re loving AudoTask!</p>
              <p className="mt-2 text-gray-700">
                You have exhausted the Free Tier Limit. Please consider
                upgrading to PRO plan and enjoy Audotask without any hassles and
                worry.
              </p>
              <p className="mt-2 text-gray-700">
                User with the Creator Access(account from which workspace was
                created initially) for the workspace can upgrade to the PRO plan
                for entire workspace. For your workspace it’s{" "}
                <span className="underline font-medium text-wecest-pink">
                  {creatorEmail || ""}
                </span>
              </p>
              <p className="mt-2 text-gray-700">
                For any support or queries, please
                <Link
                  to="/contact-support"
                  className="underline font-medium text-wecest-pink px-1"
                >
                  contact us here
                </Link>
              </p>
              {creatorAccess ? (
                <Link
                  className={`button-type-2 w-56 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-5`}
                  to="/payment"
                >
                  UPGRADE TO PRO PLAN
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanUpgradeComponent;
