import React from "react";
import parseHtml from "html-react-parser";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { getDateTime } from "../utils/getDate&Time";

import labelType from "./Label";

import {
  CLOSE_TASK_DETAILS_MODAL,
  EDIT_TASK_MODAL_OPEN,
} from "../constants/task";

import loader from "../images/loader.gif";
import closeIcon from "../images/close_icon_dark.svg";
import editIcon from "../images/edit_icon.svg";
import LabelType from "./Label";
import ReactTooltip from "react-tooltip";
import { Event } from "../utils/eventTracking";
import {
  audioListenTaskDetailsModalOpen,
  taskEditIconClick,
  taskInfoModalCloseIconClick,
} from "../constants/analytics";

const TaskDetails: React.FC = ({}) => {
  const dispatch = useDispatch();
  const taskDetailsLoading = useSelector(
    (state: RootState) => state.task.taskDetailsLoading
  );
  const adminAccess = useSelector(
    (workspace: RootState) => workspace.folder.adminAccess
  );
  const taskDetails = useSelector((state: RootState) => state.task.currentTask);

  const taskMembers = (membersString: string) => {
    const members: any = membersString?.slice(1, -1).replace(/['"]+/g, "");
    const membersList: any = members?.split(",");

    return (
      <div className="flex flex-wrap justify-start items-center">
        {members.length && members.charAt(0) ? (
          <div
            className="block p-2 pl-3 pr-3 bg-green-400 text-xs text-white rounded-2xl text-center font-bold ml-1 cursor-pointer"
            data-tip
            data-for="members"
          >
            {members.length ? members.charAt(0).toUpperCase() : ""}
          </div>
        ) : (
          <p>Not yet assigned</p>
        )}

        <ReactTooltip
          id="members"
          place="right"
          effect="solid"
          border={true}
          type="light"
          className="tooltip-2"
        >
          {membersList.map((member: any) => (
            <p key={member}>{member}</p>
          ))}
        </ReactTooltip>

        {membersList.length > 1 ? (
          <>
            <span
              className="block p-2 pl-3 pr-3 bg-yellow-400 text-xs text-white rounded-2xl text-center font-bold ml-1 cursor-pointer"
              data-tip
              data-for="emails"
            >
              +{membersList.length - 1} More
            </span>
            <ReactTooltip
              id="emails"
              place="right"
              effect="solid"
              border={true}
              type="light"
              className="tooltip-2"
            >
              {membersList.map((member: any) => (
                <p key={member}>{member}</p>
              ))}
            </ReactTooltip>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-sm md:max-w-3xl  h-auto">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="grid grid-cols-12  p-3 pl-5 rounded-t ">
              <div className="col-span-10">
                <h1 className="text-2xl font-bold text-gray-600">
                  {taskDetails.task_name}
                </h1>
              </div>
              <div className="col-span-2">
                <div className="flex justify-around">
                  {adminAccess ? (
                    <img
                      src={editIcon}
                      alt="wecest modal close"
                      className="w-8 cursor-pointer p-1"
                      onClick={() => {
                        Event(
                          taskEditIconClick.category,
                          taskEditIconClick.action,
                          taskEditIconClick.label
                        );
                        dispatch({
                          type: EDIT_TASK_MODAL_OPEN,
                        });
                      }}
                    />
                  ) : null}
                  <img
                    src={closeIcon}
                    alt="wecest modal close"
                    className="w-8 cursor-pointer p-1"
                    onClick={() => {
                      Event(
                        taskInfoModalCloseIconClick.category,
                        taskInfoModalCloseIconClick.action,
                        taskInfoModalCloseIconClick.label
                      );
                      dispatch({
                        type: CLOSE_TASK_DETAILS_MODAL,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {taskDetailsLoading ? (
              <div className="flex justify-center items-center py-32">
                <img
                  src={loader}
                  alt="wecest task details loader"
                  className="w-16"
                />
              </div>
            ) : (
              <>
                <div className=" m-3">
                  <span className="text-md font-bold text-gray-600 ">
                    Description
                  </span>

                  <div className="block h-40 bg-gray-100 p-3 overflow-auto">
                    <span>{parseHtml(taskDetails.description)}</span>
                  </div>
                  <div className="flex pt-5 md:pt-4">
                    <LabelType labelText={taskDetails.priority} />
                  </div>
                  <div className="flex flex-wrap justify-start items-center pt-3">
                    <span className="text-md font-bold text-gray-600 ">
                      Assigned To:
                    </span>
                    <span className="ml-1 text-sm font-medium text-gray-700">
                      {taskDetails?.assignedTo?.length
                        ? taskMembers(taskDetails.assignedTo)
                        : null}
                    </span>
                  </div>
                  <div className="flex flex-wrap justify-start items-center pt-3">
                    <span className="text-md font-bold text-gray-600 ">
                      Due Date:
                    </span>
                    <span className="ml-1 text-sm font-medium text-gray-700">
                      {taskDetails.due_date
                        ? getDateTime(taskDetails.due_date)
                        : "Not Available"}
                    </span>
                  </div>
                  <div className="flex justify-start items-center pt-2">
                    <span className="text-md font-bold text-gray-600 ">
                      Created By:
                    </span>
                    <span className="ml-1 text-sm font-medium text-gray-700">
                      {taskDetails.email}
                    </span>
                  </div>
                  <div className="flex justify-start items-center pt-2">
                    <span className="text-md font-bold text-gray-600 ">
                      Notify on:
                    </span>
                    <span className="ml-1 text-sm font-medium text-gray-700">
                      {taskDetails.notify_date
                        ? getDateTime(taskDetails.notify_date)
                        : "Not Available"}
                    </span>
                  </div>
                  {taskDetails.updated_by ? (
                    <div className="flex justify-start items-center pt-2">
                      <span className="text-md font-bold text-gray-600 ">
                        Last Updated By:
                      </span>
                      <span className="ml-1 text-sm font-medium text-gray-700">
                        {taskDetails.updated_by}
                      </span>
                    </div>
                  ) : null}
                  {taskDetails.updated_by ? (
                    <div className="flex justify-start items-center pt-2">
                      <span className="text-md font-bold text-gray-600 ">
                        Last Updated On:
                      </span>
                      <span className="ml-1 text-sm font-medium text-gray-700">
                        {getDateTime(taskDetails.updatedAt)}
                      </span>
                    </div>
                  ) : null}

                  {taskDetails.s3_bucket_files.length ? (
                    <div className="flex justify-center pt-2 pb-2">
                      <audio
                        src={taskDetails.s3_bucket_files[0]}
                        controls
                        controlsList="nodownload"
                        className="w-full"
                        onPlay={() =>
                          Event(
                            audioListenTaskDetailsModalOpen.category,
                            audioListenTaskDetailsModalOpen.action,
                            audioListenTaskDetailsModalOpen.label
                          )
                        }
                      ></audio>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default TaskDetails;
