import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import TalkToUs from "../components/TalktoUs";
import Footer from "../components/FooterComponent";
import TooltipComponent from "../components/Tooltip";
import {
  UNLIMITED_FOLDER_SHEETS_TOOLTIP,
  PRIORITY_SUPPORT_TOOLTIP,
  UNLIMITED_TASKS_TOOLTIP,
  USER_IN_WORKSPACE_TOOLTIP,
  STORAGE_TOOLTIP,
  CUSTOM_REMINDERS_TOOLTIP,
  CHANGE_MEMBER_ACCESS_TOOLTIP,
} from "../constants/tooltip";

import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import PageLoadingComponent from "../components/PageLoadingComponent";

import DashboardImage from "../images/dashboard_thumbnail.png";
import folderIllustartion from "../images/illustration_folder.png";
import membersIllustration from "../images/illustration_members.png";
import ballonsIllustration from "../images/illustration_ballons.png";
import peopleIllustration from "../images/illustration_people.png";
import greenTick from "../images/green_tick.png";
import redCross from "../images/close_icon_red.png";
import { useSelector, useDispatch } from "react-redux";
import { CURRENT_PAGE_LOADING } from "../constants/user";
import { verifyUserLoggedIn } from "../actions/userActions";
import { RootState } from "../reducers";
import {
  freePlanGetStartedFeaturesPage,
  proPlanGetStartedFeaturesPage,
  watchAudotaskTutorialClickedFeaturesPage,
} from "../constants/analytics";
import { Event } from "../utils/eventTracking";
import ModalComponent from "../components/ModalComponent";
import TutorialCompoenent from "../components/TutorialComponent";

const FeaturesPage = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    ReactGA.pageview("/features");
    dispatch({
      type: CURRENT_PAGE_LOADING,
    });
    verifyUserLoggedIn(dispatch);
  }, []);
  // Check whether user is loggedin
  const userLoggedin = useSelector((state: RootState) => state.user.isLoggedin);
  const currentPageLoading = useSelector(
    (state: RootState) => state.user.currentPageLoading
  );
  function toggleModelState() {
    setShowModal(!showModal);
  }
  return (
    <>
      {currentPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <>
          <Navbar userLoggedin={userLoggedin} />
          {showModal && (
            <ModalComponent
              component={
                <TutorialCompoenent toggleModelState={toggleModelState} />
              }
            />
          )}
          <div className="flex flex-col items-center w-full h-auto p-12">
            <h1 className="text-3xl md:text-4xl text-center text-light-gray font-semibold">
              AUDOTASK FEATURES
            </h1>
            <p className="mt-3 text-gray2 text-center font-medium">
              Create tasks more easily by adding Your Voice To the tasks and
              Never Forget Anything
            </p>
            <img
              src={DashboardImage}
              alt="Audotask Dashboard Image"
              className="mt-5 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 hover:shadow-2xl w-full md:w-4/6 cursor-pointer"
              onClick={() => {
                Event(
                  watchAudotaskTutorialClickedFeaturesPage.category,
                  watchAudotaskTutorialClickedFeaturesPage.action,
                  watchAudotaskTutorialClickedFeaturesPage.label
                );
                toggleModelState();
              }}
            />
          </div>
          <div className="flex flex-row flex-wrap w-full h-auto p-5 md:p-10 bg-audotask-gold">
            <div className="flex flex-row md:flex-col flex-wrap md:flex-1 justify-center items-center">
              <img
                src={folderIllustartion}
                alt="audotask folder illustration"
                className="w-4/6"
              />
            </div>
            <div className="flex justify-center flex-row md:flex-col flex-wrap md:flex-1">
              <h1 className="font-bold text-gray-600 text-4xl mt-4">
                Create Tasks With Your Voice
              </h1>
              <p className="py-4 text-gray-600 text-justify">
                You can Create Tasks, and record your voice to the task with
                just one click and start speaking. Once you have recorded you
                can press stop button and your recording will be added to that
                particular task.
              </p>
            </div>
          </div>

          <div className="flex flex-row flex-wrap-reverse w-full h-auto p-5 md:p-10 bg-white">
            <div className="flex flex-row md:flex-col flex-wrap md:flex-1 items-start justify-center">
              <h1 className="font-bold text-gray-600 text-4xl mt-4">
                Create Folders & Add Team Members
              </h1>
              <p className="py-4 text-gray-600 text-justify">
                You can create unlimited folders & add your team members to that
                folder. Inside folder, you can create unlimited sheets where you
                will add your tasks.
              </p>
            </div>
            <div className="flex justify-center items-end flex-row md:flex-col flex-wrap md:flex-1">
              <img
                src={peopleIllustration}
                alt="audotask folder illustration"
                className="w-4/6"
              />
            </div>
          </div>

          <div className="flex flex-row flex-wrap w-full h-auto p-5 md:p-10 bg-white">
            <div className="flex flex-row md:flex-col flex-wrap md:flex-1 items-center justify-center">
              <img
                src={membersIllustration}
                alt="audotask folder illustration"
                className="w-4/6 h-5/6"
              />
            </div>
            <div className="flex justify-center flex-row md:flex-col flex-wrap md:flex-1">
              <h1 className="font-bold text-gray-600 text-4xl mt-4">
                Assign Tasks To Team Members
              </h1>
              <p className="py-4 text-gray-600 text-justify">
                You can assign tasks to your team members and add the priority
                to it if you want. As soon as you create the task we will notify
                that team member and all other members in the folder via an
                email to their mailbox.
              </p>
            </div>
          </div>

          <div
            className="flex flex-row flex-wrap-reverse w-full h-auto p-5 md:p-10 "
            style={{ background: "#32FFC8" }}
          >
            <div className="flex flex-row md:flex-col flex-wrap md:flex-1 items-start justify-center">
              <h1 className="font-bold text-gray-600 text-4xl mt-4">
                Custom Reminders
              </h1>
              <p className="py-4 text-gray-600 text-justify">
                You can schedule at what time that person should be sent an
                email regarding a particular task, and we will deliver it to
                their mailbox at that time.
              </p>
            </div>
            <div className="flex justify-center items-end flex-row md:flex-col flex-wrap md:flex-1">
              <img
                src={ballonsIllustration}
                alt="audotask folder illustration"
                className="w-4/6 max-h-72"
              />
            </div>
          </div>
          <div className="flex flex-col items-center w-full h-auto p-12">
            <h1 className="text-3xl md:text-4xl text-center text-light-gray font-semibold hidden md:block">
              FEATURES COMPARISON
            </h1>
            <div className=" grid-cols-4 h-auto mt-12 w-4/6 hidden md:grid">
              <div className="col-span-2">
                <div className="flex justify-center items-center w-full p-12"></div>
                <div className="flex flex-col justify-start items-end w-full px-5">
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Unlimited Folders & Sheets
                    </span>
                    <TooltipComponent
                      text={UNLIMITED_FOLDER_SHEETS_TOOLTIP}
                      label="unlimitedFolders"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Priority Support
                    </span>
                    <TooltipComponent
                      text={PRIORITY_SUPPORT_TOOLTIP}
                      label="support"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Unlimited Tasks
                    </span>
                    <TooltipComponent
                      text={UNLIMITED_TASKS_TOOLTIP}
                      label="unlimitedTasks"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Users in a Workspace
                    </span>
                    <TooltipComponent
                      text={USER_IN_WORKSPACE_TOOLTIP}
                      label="usersInWorkspace"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Storage
                    </span>
                    <TooltipComponent text={STORAGE_TOOLTIP} label="storage" />
                  </div>

                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Custom Task Reminders{" "}
                    </span>
                    <TooltipComponent
                      text={CUSTOM_REMINDERS_TOOLTIP}
                      label="customReminder"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Change Member Access
                    </span>
                    <TooltipComponent
                      text={CHANGE_MEMBER_ACCESS_TOOLTIP}
                      label="changeMemberAccess"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex justify-center items-center w-full p-5 bg-gray-100">
                  <h1 className="text-3xl md:text-4xl text-center text-light-gray font-semibold">
                    FREE
                  </h1>
                </div>
                <div className="flex flex-col justify-center items-center p-3 border-2 border-r-0 border-gray-200">
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <span className="p-3">
                    <img
                      src={redCross}
                      alt="audotask green tick features"
                      className="w-4"
                    />
                  </span>
                  <span className="p-3">
                    <img
                      src={redCross}
                      alt="audotask green tick features"
                      className="w-4"
                    />
                  </span>
                  <span className="p-3 text-sm font-semibold text-red-400">
                    Upto 5 members
                  </span>
                  <span className="p-3 text-sm font-semibold text-red-400">
                    Upto 100MB
                  </span>
                  <span className="p-3 text-sm font-semibold text-red-400">
                    Upto 50 Reminders
                  </span>
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <Link
                    to="/dashboard"
                    className="p-3 w-full text-center text-white font-bold rounded-md focus:outline-none orange1 hover:orange2 transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                    onClick={() =>
                      Event(
                        freePlanGetStartedFeaturesPage.category,
                        freePlanGetStartedFeaturesPage.action,
                        freePlanGetStartedFeaturesPage.label
                      )
                    }
                  >
                    Get Started
                  </Link>
                </div>
              </div>
              <div className="col-span-1 shadow-xl">
                <div
                  className="flex justify-center items-center w-full p-5"
                  style={{ background: " #63bdff" }}
                >
                  <h1 className="text-3xl md:text-4xl text-center text-white font-semibold">
                    PRO
                  </h1>
                </div>
                <div className="flex flex-col justify-center items-center p-3 border-2 border-gray-200">
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <span className="p-3 text-sm font-semibold text-green-400">
                    Unlimited
                  </span>
                  <span className="p-3 text-sm font-semibold text-green-400">
                    Unlimited
                  </span>
                  <span className="p-3  text-sm font-semibold text-green-400">
                    Unlimited
                  </span>
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <Link
                    style={{ background: "#32FFC8" }}
                    to="/payment"
                    className="p-3 w-full text-center text-white font-bold rounded-md focus:outline-none  transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                    onClick={() =>
                      Event(
                        proPlanGetStartedFeaturesPage.category,
                        proPlanGetStartedFeaturesPage.action,
                        proPlanGetStartedFeaturesPage.label
                      )
                    }
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-0 md:mt-10 ">
              <Link
                to="/pricing"
                onClick={() =>
                  Event(
                    "PRICING_PLANS",
                    "See Pricing plans link clicked on Features page",
                    "FEATURESPAGE"
                  )
                }
              >
                <span className="underline">See Pricing of Plans Here</span>
              </Link>
            </div>
          </div>

          <TalkToUs />
          <Footer />
        </>
      )}
    </>
  );
};

export default FeaturesPage;
