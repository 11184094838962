import React, { Fragment, useEffect } from "react";
import Navbar from "../components/Navbar";
import ReactGA from "react-ga";
import PricingComponent from "../components/Pricing";
import TalkToUs from "../components/TalktoUs";
import Footer from "../components/FooterComponent";
import FAQComponent from "../components/Faq";
import TooltipComponent from "../components/Tooltip";
import {
  UNLIMITED_FOLDER_SHEETS_TOOLTIP,
  PRIORITY_SUPPORT_TOOLTIP,
  UNLIMITED_TASKS_TOOLTIP,
  USER_IN_WORKSPACE_TOOLTIP,
  STORAGE_TOOLTIP,
  CUSTOM_REMINDERS_TOOLTIP,
  CHANGE_MEMBER_ACCESS_TOOLTIP,
} from "../constants/tooltip";

import thinkingIllustration from "../images/thinking_illustration.png";
import greenTick from "../images/green_tick.png";
import redCross from "../images/close_icon_red.png";
import { useDispatch, useSelector } from "react-redux";
import { CURRENT_PAGE_LOADING } from "../constants/user";
import { verifyUserLoggedIn } from "../actions/userActions";
import { RootState } from "../reducers";
import PageLoadingComponent from "../components/PageLoadingComponent";
import { Link } from "react-router-dom";
import { Event } from "../utils/eventTracking";
import {
  freePlanGetStartedPricingPage,
  proPlanGetStartedPricingPage,
} from "../constants/analytics";

const PricingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.pageview("/pricing");
    dispatch({
      type: CURRENT_PAGE_LOADING,
    });
    verifyUserLoggedIn(dispatch);
  }, []);

  // Check whether user is loggedin
  const userLoggedin = useSelector((state: RootState) => state.user.isLoggedin);
  const currentPageLoading = useSelector(
    (state: RootState) => state.user.currentPageLoading
  );
  return (
    <>
      {currentPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <>
          <Navbar userLoggedin={userLoggedin} />
          <PricingComponent />

          <div className="flex flex-col items-center w-full h-auto p-12">
            <h1 className="text-3xl md:text-4xl mt-10 text-center text-light-gray font-semibold hidden md:block">
              FEATURES COMPARISON
            </h1>
            <div className=" grid-cols-4 h-auto mt-12 w-4/6 hidden md:grid">
              <div className="col-span-2">
                <div className="flex justify-center items-center w-full p-12"></div>
                <div className="flex flex-col justify-start items-end w-full px-5">
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Unlimited Folders & Sheets
                    </span>
                    <TooltipComponent
                      text={UNLIMITED_FOLDER_SHEETS_TOOLTIP}
                      label="unlimitedFolders"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Priority Support
                    </span>
                    <TooltipComponent
                      text={PRIORITY_SUPPORT_TOOLTIP}
                      label="support"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Unlimited Tasks
                    </span>
                    <TooltipComponent
                      text={UNLIMITED_TASKS_TOOLTIP}
                      label="unlimitedTasks"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Users in a Workspace
                    </span>
                    <TooltipComponent
                      text={USER_IN_WORKSPACE_TOOLTIP}
                      label="usersInWorkspace"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Storage
                    </span>
                    <TooltipComponent text={STORAGE_TOOLTIP} label="storage" />
                  </div>

                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Custom Task Reminders{" "}
                    </span>
                    <TooltipComponent
                      text={CUSTOM_REMINDERS_TOOLTIP}
                      label="customReminder"
                    />
                  </div>
                  <div className="flex p-1 py-3">
                    <span className="text-sm font-semibold text-gray-600 pr-2">
                      Change Member Access
                    </span>
                    <TooltipComponent
                      text={CHANGE_MEMBER_ACCESS_TOOLTIP}
                      label="changeMemberAccess"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex justify-center items-center w-full p-5 bg-gray-100">
                  <h1 className="text-3xl md:text-4xl text-center text-light-gray font-semibold">
                    FREE
                  </h1>
                </div>
                <div className="flex flex-col justify-center items-center p-3 border-2 border-r-0 border-gray-200">
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <span className="p-3">
                    <img
                      src={redCross}
                      alt="audotask green tick features"
                      className="w-4"
                    />
                  </span>
                  <span className="p-3">
                    <img
                      src={redCross}
                      alt="audotask green tick features"
                      className="w-4"
                    />
                  </span>
                  <span className="p-3 text-sm font-semibold text-red-400">
                    Upto 5 members
                  </span>
                  <span className="p-3 text-sm font-semibold text-red-400">
                    Upto 100MB
                  </span>
                  <span className="p-3 text-sm font-semibold text-red-400">
                    Upto 50 Reminders
                  </span>
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <Link
                    to="/dashboard"
                    className="p-3 w-full text-center text-white font-bold rounded-md focus:outline-none orange1 hover:orange2 transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                    onClick={() =>
                      Event(
                        freePlanGetStartedPricingPage.category,
                        freePlanGetStartedPricingPage.action,
                        freePlanGetStartedPricingPage.label
                      )
                    }
                  >
                    Get Started
                  </Link>
                </div>
              </div>
              <div className="col-span-1 shadow-xl">
                <div
                  className="flex justify-center items-center w-full p-5"
                  style={{ background: " #63bdff" }}
                >
                  <h1 className="text-3xl md:text-4xl text-center text-white font-semibold">
                    PRO
                  </h1>
                </div>
                <div className="flex flex-col justify-center items-center p-3 border-2 border-gray-200">
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <span className="p-3 text-sm text-center font-semibold text-green-400">
                    Depends on the PRO plan
                  </span>
                  <span className="p-3 text-sm font-semibold text-green-400">
                    Unlimited
                  </span>
                  <span className="p-3  text-sm font-semibold text-green-400">
                    Unlimited
                  </span>
                  <span className="p-3">
                    <img
                      src={greenTick}
                      alt="audotask green tick features"
                      className="w-6"
                    />
                  </span>
                  <Link
                    style={{ background: "#32FFC8" }}
                    to="/payment"
                    className="p-3 w-full text-center text-white font-bold rounded-md focus:outline-none  transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                    onClick={() =>
                      Event(
                        proPlanGetStartedPricingPage.category,
                        proPlanGetStartedPricingPage.action,
                        proPlanGetStartedPricingPage.label
                      )
                    }
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row flex-wrap w-full h-auto p-5 md:p-10 bg-audotask-gold">
            <div className="flex flex-row md:flex-col flex-wrap md:flex-1 justify-center items-center">
              <img
                src={thinkingIllustration}
                alt="audotask folder illustration"
                className="w-4/6"
              />
            </div>
            <div className="flex justify-center flex-row md:flex-col flex-wrap md:flex-1">
              <h1 className="font-bold text-gray-600 text-4xl md:text-5xl mt-4">
                Your Mind is For Having Ideas Not Holding Them !
              </h1>
            </div>
          </div>
          <FAQComponent />

          <TalkToUs />
          <Footer />
        </>
      )}
    </>
  );
};

export default PricingPage;
