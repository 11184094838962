import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import publicIp from "public-ip";

import CustomLogoComopnent from "../components/CustomLogo";
import Select from "react-select";
import SideBarType1 from "../components/SideBar1";
import ForgotPasswordImage from "../images/illustration_forgotpassword.svg";
import plusIcon from "../images/plus_icon.svg";
import minusIcon from "../images/minus_icon.png";
import paymentFailedIcon from "../images/payment_failed.png";
import creditCardIcon from "../images/credit-card.png";
import lockIcon from "../images/secure_lock.png";
import verifiedBadge from "../images/verified_badge.png";
import guardIcon from "../images/shield_icon.png";
import countriesList from "../constants/countries";
import { verifyCookie } from "../actions/paymentActions";
import { RootState } from "../reducers";
import Cookies from "universal-cookie";
import Loader from "../components/LoaderComponent";
import PageLoadingComponent from "../components/PageLoadingComponent";
import {
  PAYMENT_PAGE_LOADING,
  PRO_PLAN_ACTIVE,
  ERROR_HAPPENED,
} from "../constants/payments";
import proPlanActiveImage from "../images/pro-plan-active.png";
import { Event } from "../utils/eventTracking";
import {
  paymentButtonClick,
  paymentSuccessContactSupportClick,
  paymentFailureContactSupportClick,
  addProMembersToCurrentPlan,
} from "../constants/analytics";

const cookie = new Cookies();
declare global {
  interface Window {
    Razorpay: any;
  }
}
const secureApi = axios.create({
  withCredentials: true,
});

const initialState = {
  noOfUsers: 1,
  price: 36,
  plan: "year",
  country: "",
  paymentProcessing: false,
  paymentSuccess: null,
};
let transactionDate: any;
let paymentId: string;
let amount: string;
const uid = cookie.get("uid");
let paymentPageStateChanges = false;
const PaymentPage: React.FC = () => {
  const [state, setState] = useState(initialState);
  const [pageLoading, setPageLoading] = useState(true);
  const [proPlanActive, setProPlanActive] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    ReactGA.pageview("/payment");
    secureApi
      .post(
        `${process.env.REACT_APP_API_URL}/api/organisation/payment-status`,
        { uid }
      )
      .then((res) => {
        const {
          data: { result },
          status,
        } = res;
        dispatch({
          type: ERROR_HAPPENED,
          payload: "",
        });
        if (result === true && status === 200) {
          setProPlanActive(true);
          setPageLoading(false);
        } else {
          verifyCookie(dispatch);
          setPageLoading(false);
        }
      })
      .catch((err: any) => {
        dispatch({
          type: ERROR_HAPPENED,
          payload:
            err.response.data ||
            "Something went wrong, Please Refresh Page or try later!",
        });
        setPageLoading(false);
      });
  }, []);

  useEffect(() => {
    const savePaymentUserInfoToMailingList = async () => {
      if (!paymentPageStateChanges) {
        try {
          const { data, status } = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/support/payment-user`,
            {
              uid,
            }
          );
        } catch (err) {}
      }
      paymentPageStateChanges = true;
    };
    savePaymentUserInfoToMailingList();
  }, [state.noOfUsers, state.country, state.plan]);

  useEffect(() => {
    if (state.plan) {
      let currentPrice;
      switch (state.plan) {
        case "month":
          currentPrice = 5.99 * state.noOfUsers;
          handleChange("price", currentPrice);
          return;
        case "quarter":
          currentPrice = 4.99 * state.noOfUsers * 3;
          handleChange("price", currentPrice);
          return;
        case "year":
          currentPrice = 3.99 * state.noOfUsers * 12;
          handleChange("price", currentPrice);
          return;
        default:
          return;
      }
    }
  }, [state.plan, state.noOfUsers]);

  const cookieData = useSelector(
    (state: RootState) => state.payment.cookieData
  );

  const errorHappened = useSelector(
    (state: RootState) => state.payment.errorMessage
  );

  let paymentOptions: { label: string; value: string }[] = [];
  countriesList.forEach((country: any) => {
    paymentOptions.unshift({
      label: country.name,
      value: country.name,
    });
  });
  paymentOptions.sort((p1: any, p2: any) => (p1 > p2 ? 1 : -1));

  function loadScript(src: string) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const displayRazorpay = async () => {
    try {
      Event(
        paymentButtonClick.category,
        paymentButtonClick.action,
        paymentButtonClick.label
      );
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      await handleChange("paymentProcessing", true);

      if (!res) {
        alert("Payment Couldn't be started. Are you online?");
        //handleChange("paymentProcessing", false);
        return;
      }
      const discountCookie = cookie.get("discount") || null;
      const pricingData = {
        users: state.noOfUsers,
        planType: state.plan,
        discount: discountCookie,
        uid,
      };
      const result = await secureApi.post(
        `${process.env.REACT_APP_API_URL}/api/payment/orders`,
        pricingData
      );

      if (!result) {
        alert("Server error. Are you online?");
        //handleChange("paymentProcessing", false);
        return;
      }
      const { amount, id: order_id, currency } = result.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: "AudoTask",
        description: "Payment for your AudoTask Pro Plan",
        image: { creditCardIcon },
        order_id: order_id,

        handler: async function (response: any) {
          try {
            const userIp = "await publicIp.v4()";
            const reqdata = {
              orderCreationId: order_id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              users: state.noOfUsers,
              planType: state.plan,
              discount: discountCookie,
              amount,
              country: state.country,
              uid,
              paymentUserIp: userIp || "",
              addMoreUsersToWorkspace: false,
            };
            const { data, status } = await secureApi.post(
              `${process.env.REACT_APP_API_URL}/api/payment/success`,
              reqdata
            );
            data["status"] = status;
            await handleChange("paymentProcessing", false);
            await handleChange("paymentSuccess", data);
          } catch (err) {
            const { status } = err?.response || 500;
            const message =
              err?.response?.data?.msg ||
              "Something went wrong! Please try again";
            let data: any = {
              status,
              message,
              plan: state.plan,
              price: planCharges(),
            };
            await handleChange("paymentProcessing", false);
            await handleChange("paymentSuccess", data);
          }
        },
        theme: {
          color: "#9024b2",
        },
        notify: {
          sms: true,
          email: true,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      const { status } = error?.response || 500;
      const message =
        error?.response?.data?.msg || "Something went wrong! Please try again";
      let data: any = {
        status,
        message,
        plan: state.plan,
        price: planCharges(),
      };
      await handleChange("paymentProcessing", false);
      await handleChange("paymentSuccess", data);
    }
  };

  const handleUsersCountChange = (name: string, value: Number) => {
    if (value > 0) {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const handleChange = async (name: string, value: any) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const logoColor =
    state.plan === "quarter"
      ? "bg-quarterly-pink"
      : state.plan === "month"
      ? "bg-blue-400"
      : state.plan === "year"
      ? "bg-yearly-green"
      : "orange1";

  const textColor =
    state.plan === "quarter"
      ? "text-quarterly-pink"
      : state.plan === "month"
      ? "text-blue-400"
      : state.plan === "year"
      ? "text-yearly-green"
      : "text-yellow-400";

  const buttonDisabledStatus =
    !state.noOfUsers || !state.country || !state.plan;

  const planCharges = () =>
    (cookieData?.expiry_date &&
      new Date(cookieData?.expiry_date) > new Date()) ||
    (cookieData?.discount && !cookieData?.expiry_date)
      ? (
          state.price -
          state.price * (Number(cookieData?.discount) / 100)
        ).toFixed(2) + "$"
      : state.price.toFixed(2) + "$";

  const quarterlyPlanCalculation = () => (
    <span>( 4.99$ Per Month/User * 3 Months )</span>
  );
  const yearlyPlanCalculation = () => (
    <span>( 3.99$ Per Month/User * 12 Months )</span>
  );
  const monthlyPlanCalculation = () => (
    <span>( 5.99$ Per Month/User * 1 Month )</span>
  );

  const fillPaymentDetails = () => {
    return (
      <div className="block border-2 border-gray-200 p-4 w-full md:w-3/4 rounded-lg shadow-md">
        <CustomLogoComopnent logoColor={logoColor} textColor={textColor} />
        <div className="grid grid-cols-12 mt-4">
          <div className="col-span-12 md:col-span-7 overflow-auto">
            <div className="flex">
              <h1 className={`text-5xl ${textColor}`}>{planCharges()}</h1>
              <span className="text-3xl mt-5 font-semibold text-red-500 line-through">
                {(cookieData?.expiry_date &&
                  new Date(cookieData?.expiry_date) > new Date()) ||
                (cookieData?.discount && !cookieData?.expiry_date)
                  ? state.price + "$"
                  : null}
              </span>
            </div>
            <div className="flex text-gray2 text-xs text-justify font-semibold ml-1">
              Per {state.plan}
            </div>
            <div className="flex text-gray2 text-sm text-justify font-regular ml-1">
              {state.plan === "year"
                ? yearlyPlanCalculation()
                : state.plan === "quarter"
                ? quarterlyPlanCalculation()
                : state.plan === "month"
                ? monthlyPlanCalculation()
                : null}
            </div>
          </div>
          <div className="col-span-12 md:col-span-5">
            <div className="flex justify-end items-center h-full">
              <select
                name="plan"
                className="text-gray-500 font-regular p-2 border-none w-full md:w-3/4 focus:outline-none underline cursor-pointer"
                value={state.plan}
                onChange={(e) => handleChange("plan", e.target.value)}
              >
                <option value="year">Billed Yearly</option>
                <option value="quarter">Billed Quarterly</option>
                <option value="month">Billed Monthly</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center text-center items-center p-3 my-2">
          <img
            src={minusIcon}
            alt="audotask decrease icon"
            className="w-6 h-6 mx-3 cursor-pointer"
            onClick={() =>
              handleUsersCountChange("noOfUsers", state.noOfUsers - 1)
            }
          />
          <input
            type="number"
            name="noOfUsers"
            value={state.noOfUsers}
            className="border-2 border-gray-300 w-24 h-12 text-center text-xl font-medium text-gray-600 focus:outline-none"
            onChange={(e: any) =>
              handleUsersCountChange("noOfUsers", Number(e.target.value))
            }
          />
          <div
            className="flex justify-center items-center rounded-lg shadow-xl cursor-pointer  w-10 h-10 bg-black mx-3"
            onClick={() =>
              handleUsersCountChange("noOfUsers", state.noOfUsers + 1)
            }
          >
            <img
              src={plusIcon}
              alt="audotask plus icon"
              className="absolute mt-1 "
            />
          </div>
        </div>
        <p className="text-center text-sm font-medium text-gray1">
          Number of Users which are going to use this workspace
        </p>
        <div className="flex justify-center items-center w-full mt-9">
          <Select
            name="country"
            className="text-gray1 p-2 w-full md:w-3/4 focus:outline-none underline cursor-pointer"
            options={paymentOptions}
            isSearchable={true}
            onChange={(e: any) => handleChange("country", e.value)}
            placeholder="Select your country"
          />
        </div>
        <div className="flex flex-col items-center mt-6">
          <button
            className={`p-3 w-full text-gray-100 font-bold rounded-md focus:outline-none ${
              !state.noOfUsers || !state.country || !state.plan
                ? "opacity-25"
                : "transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-95"
            }`}
            style={{ background: "#00CC66" }}
            onClick={() => displayRazorpay()}
            disabled={buttonDisabledStatus}
          >
            MAKE PAYMENT{" "}
          </button>
          <p className="text-center text-sm font-semibold text-gray1 pt-1">
            For International Payments, we accept Paypal and for payments based
            out of India we accept all major Debit/Credit cards.
          </p>
        </div>
        <div className="flex justify-center mt-4">
          <img
            src={creditCardIcon}
            alt="audotask credit card icon"
            className="w-12"
          />
          <img
            src={lockIcon}
            alt="audotask credit card icon"
            className="w-12 ml-3"
          />
          <img
            src={guardIcon}
            alt="audotask credit card icon"
            className="w-12 ml-3"
          />
        </div>
        <div className="flex justify-center mt-3 mb-2">
          <p className="text-center text-sm font-medium text-gray1">
            All payments made here are 100% Safe & Secure
          </p>
        </div>
      </div>
    );
  };

  const paymentProcessing = () => {
    return (
      <>
        <Loader />
        <span className="text-gray-600 text-center">
          Please wait, we are confirming your payment...
        </span>
      </>
    );
  };

  const paymentResultSuccess = () => {
    return (
      <div className="block border-2 border-gray-200 p-4 w-full md:w-2/4 rounded-lg shadow-md ">
        <div className="flex justify-center">
          <img
            src={verifiedBadge}
            alt="audotask payment success"
            className="w-20"
          />
        </div>
        <div className="flex justify-center py-3">
          <h1
            className="text-3xl text-center font-semibold"
            style={{ color: "#00CC66" }}
          >
            Your Payment is Successful
          </h1>
        </div>
        <div className="flex flex-col pt-8 pb-4 text-sm">
          <div className="flex text-gray-500 py-1">
            <span className="font-semibold">Amount:</span>&nbsp; {amount}
          </div>
          <div className="flex text-gray-500 py-1">
            <span className="font-semibold">Plan Type:</span>&nbsp;
            {state.plan.slice(0, 1).toUpperCase() +
              state.plan.slice(1, state.plan.length) +
              "ly"}
          </div>
          <div className="flex text-gray-500 py-1">
            <span className="font-semibold">Transaction Date:</span>&nbsp;
            {transactionDate}
          </div>
          <div className="flex text-gray-500 py-1">
            <span className="font-semibold">Payment ID:</span>&nbsp; {paymentId}
          </div>
        </div>
        <div className="flex flex-col text-gray-500 text-sm py-1 pb-8">
          <span>
            You can now start using PRO plan, and enjoy its exciting Features !
          </span>
          <span className="py-2">
            We will email you the invoice for the transaction within next 72
            hours.
          </span>
          <span>
            For any queries or support, you can reach out to us{" "}
            <Link
              to="/contact-support"
              className="font-medium underline text-wecest-pink"
              onClick={() =>
                Event(
                  paymentSuccessContactSupportClick.category,
                  paymentSuccessContactSupportClick.action,
                  paymentSuccessContactSupportClick.label
                )
              }
            >
              {" "}
              here{" "}
            </Link>
            and we will get back to you.
          </span>
        </div>
      </div>
    );
  };

  const paymentResultFailed = () => {
    return (
      <div className="block border-2 border-gray-200 p-4 w-full md:w-2/4 rounded-lg shadow-md ">
        <div className="flex justify-center">
          <img
            src={paymentFailedIcon}
            alt="audotask payment success"
            className="w-16"
          />
        </div>
        <div className="flex justify-center py-3">
          <h1 className="text-3xl text-center font-semibold text-red-400">
            Your Payment is Not Successful
          </h1>
        </div>
        <div className="flex flex-col pt-8 pb-4 text-sm">
          <div className="flex text-gray-500 py-1">
            <span className="font-semibold">Amount:</span>&nbsp; {planCharges()}
          </div>
          <div className="flex text-gray-500 py-1">
            <span className="font-semibold">Plan Type:</span>&nbsp;{" "}
            {state.plan.slice(0, 1).toUpperCase() +
              state.plan.slice(1, state.plan.length) +
              "ly"}
          </div>
        </div>
        <div className="flex flex-col text-gray-500 text-sm py-1 pb-8">
          <span>
            If you are unable to make payment, you can contact us by visiting
            Get Help page on Audotask, our Team will help you with it.
          </span>
          <span className="py-2">
            For international Payments(outside India) we only support Paypal
            currently and users based out of India can only make payment via all
            major Debit Cards & Credit Cards.
          </span>
          <span>
            For any queries or support, you can reach out to us{" "}
            <Link
              to="/contact-support"
              className="font-medium underline text-wecest-pink"
              onClick={() =>
                Event(
                  paymentFailureContactSupportClick.category,
                  paymentFailureContactSupportClick.action,
                  paymentFailureContactSupportClick.label
                )
              }
            >
              {" "}
              here{" "}
            </Link>
            and we will get back to you.
          </span>
        </div>
      </div>
    );
  };

  const handlePaymentSuccess = () => {
    if (
      state.paymentSuccess !== null &&
      typeof state.paymentSuccess === "object"
    ) {
      const paymentSuccess: any = state.paymentSuccess;
      if (paymentSuccess) {
        if (paymentSuccess.status === 200) {
          transactionDate = new Date(
            paymentSuccess.transaction_date
          ).toUTCString();
          paymentId = paymentSuccess.paymentId;
          amount = paymentSuccess.amount;
          return paymentResultSuccess();
        }
        return paymentResultFailed();
      }
      return paymentResultFailed();
    }
    return paymentResultFailed();
  };

  const proPlan = () => {
    return (
      <div className="flex flex-col justify-center items-center w-full h-screen">
        <img
          src={proPlanActiveImage}
          alt="pro plan active image"
          className="w-32"
        />
        <h1
          className="text-3xl text-center font-semibold mt-3"
          style={{ color: "#00CC66" }}
        >
          Your Pro Plan is already Active!
        </h1>
        {proPlanActive && (
          <Link
            to="/add-pro-members"
            className="underline mt-5"
            onClick={() =>
              Event(
                addProMembersToCurrentPlan.category,
                addProMembersToCurrentPlan.action,
                addProMembersToCurrentPlan.label
              )
            }
          >
            Add More Users to Your PRO plan here
          </Link>
        )}
      </div>
    );
  };

  const errorHandle = () => {
    return (
      <div className="flex flex-col justify-center items-center w-full h-screen">
        <img
          src={paymentFailedIcon}
          alt="pro plan active image"
          className="w-16"
        />
        <h1 className="text-2xl text-center font-semibold mt-3 text-red-400">
          OOPS! We couldn’t load your details.Please refresh the page
        </h1>
      </div>
    );
  };

  return (
    <>
      {pageLoading ? (
        <div className="flex flex-col justify-center items-center w-full h-screen">
          <PageLoadingComponent />
        </div>
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2">
            <SideBarType1 image={ForgotPasswordImage} url="" />
          </div>
          <div className="col-span-5 md:col-span-4 h-screen m-2">
            <div className="flex flex-col justify-center items-center w-full h-screen">
              {proPlanActive
                ? proPlan()
                : errorHappened
                ? errorHandle()
                : state.paymentProcessing && state.country
                ? paymentProcessing()
                : typeof state.paymentSuccess === "object" &&
                  state.paymentSuccess !== null
                ? handlePaymentSuccess()
                : fillPaymentDetails()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentPage;
