export const SIDEBAR_COLLAPSE = "SIDEBAR_COLLAPSE";
export const ADD_FOLDER = "ADD_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const GET_FOLDERS = "GET_FOLDERS";
export const ADD_SHEET = "ADD_SHEET";
export const UPDATE_SHEET = "UPDATE_SHEET";
export const DELETE_SHEET = "DELETE_SHEET";
export const SAVE_DATA = "SAVE_DATA";
export const ERROR_OCCURED = "ERROR_OCCURED";
export const SELECT_FOLDER_FOR_MEMBERS = "SELECT_FOLDER_FOR_MEMBERS";
export const SELECTED_SHEET = "SELECTED_SHEET";
export const UPDATE_SHEET_DESCRIPTION = "UPDATE_SHEET_DESCRIPTION";
export const SHEET_DESCRIPTION_CHANGED = "SHEET_DESCRIPTION_CHANGED";
