// Failure Respose Component

import React from "react";

interface ModalComponentProps {
  component: any;
}

const ModalComponent: React.FC<ModalComponentProps> = ({ component }) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto max-w-sm md:max-w-xl lg:max-w-3xl w-full">
          <div className="border-0 shadow-lg rounded-md relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {component}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
export default ModalComponent;
