// Labels for High, medium & Low priority

import React, { useEffect, useState } from "react";
import { Event } from "../utils/eventTracking";
import { watchAudotaskTutorialClickedDashboard } from "../constants/analytics";
interface data {
  image: string;
  quote: string;
  author: string;
  watchAudotaskDemo?: boolean;
  toggleModelState?: any;
}

const SelectImageQuote: React.FC<data> = ({
  image,
  quote,
  author,
  watchAudotaskDemo = false,
  toggleModelState,
}) => {
  return (
    <>
      <div className="flex flex-col justify-center items-center w-full h-screen">
        <img
          src={image}
          alt="wecest no task available Illustration"
          className="w-48 h-56"
        />
        <div className="flex flex-col text-center w-4/5 md:w-72 mt-2 text-xs md:text-sm">
          <span className="font-medium text-gray-500 leading-relaxed">
            {quote}
          </span>
          <span className="font-bold text-gray-700 leading-relaxed">
            - {author}
          </span>
        </div>
        {watchAudotaskDemo && (
          <button
            className={`max-w-48 mt-2 shadow-md hover:shadow-xl bg-wecest-pink text-white font-extrabold uppercase text-xs px-6 py-3 rounded focus:outline-none hover:bg-blue-400 duration-300`}
            type="button"
            onClick={() => {
              Event(
                watchAudotaskTutorialClickedDashboard.category,
                watchAudotaskTutorialClickedDashboard.action,
                watchAudotaskTutorialClickedDashboard.label
              );
              toggleModelState();
            }}
          >
            <span className="animate-pulse">▶ </span> Watch How to Use Audotask
          </button>
        )}
      </div>
    </>
  );
};

export default SelectImageQuote;
