import React, { Fragment, ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createUserAccount } from "../actions/userActions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactGA from "react-ga";
import publicIp from "public-ip";

import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import checkEmail from "../utils/isEmail";
import checkPassword from "../utils/isPassword";

import signup from "../images/illustration_signup.png";
import loader from "../images/loader.gif";
import PageLoadingComponent from "../components/PageLoadingComponent";
import {
  signupButton,
  LoginPageLinkOnSignupPage,
} from "../constants/analytics";
import { Event } from "../utils/eventTracking";

const initialState = {
  phoneNo: "",
  countryCode: "us",
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  gender: "",
  org_name: "",
  isLoading: false,
};
const Signup: React.FC = (): ReactElement => {
  const dispatch = useDispatch();

  // state initialize
  const [state, setState] = useState(initialState);
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [validWorkSpace, setValidWorkSpace] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [accountSubmitted, setAccountSubmitted] = useState(false);
  const [country, setCountry] = useState("");
  const [
    accountCreationSuccessNotification,
    setAccountCreationSuccessNotification,
  ] = useState(false);
  const [
    accountCreationFailedNotification,
    setAccountCreationFailedNotification,
  ] = useState(false);

  const [accountCreationResponse, setAccountCreationResponse] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    if (accountSubmitted) {
      setButtonDisabled(true);
    } else if (
      checkEmail(state.email) &&
      checkPassword(state.password) &&
      state.firstName.length &&
      state.org_name.length >= 4
    )
      setButtonDisabled(false);
    else setButtonDisabled(true);
  });

  useEffect(() => {
    ReactGA.pageview("/signup");
    setIsPageLoading(false);
  }, []);

  // Create user account by submitting details
  const createAccount = async (e: any) => {
    Event(signupButton.category, signupButton.action, signupButton.label);
    e.preventDefault();
    const userIp = "await publicIp.v4()";
    const userData = {
      first_name: state.firstName,
      email: state.email,
      last_name: state.lastName,
      password: state.password,
      gender: state.gender,
      org_name: state.org_name,
      country: userIp || "",
      phoneNo: state.phoneNo,
    };

    setState({ ...state, isLoading: true });
    setAccountSubmitted(true);
    const accountCreated = await createUserAccount(dispatch, userData);
    if (accountCreated.status <= 206) {
      setAccountCreationFailedNotification(false);
      setAccountCreationSuccessNotification(true);
      setAccountCreationResponse(accountCreated.message);
    } else {
      setAccountCreationSuccessNotification(false);
      setAccountCreationFailedNotification(true);
      setAccountCreationResponse(accountCreated.message);
    }
    setState({ ...state, isLoading: false });
    setAccountSubmitted(false);
    setState({ ...state, ...initialState });
  };

  const validateEmail = (e: any) => {
    setState({ ...state, email: e.target.value });
    const emailCorrect = checkEmail(state.email);
    if (emailCorrect) {
      setValidEmail(true);
      return true;
    } else {
      setValidEmail(false);
      return false;
    }
  };

  const validatePassword = (e: any) => {
    setState({ ...state, password: e.target.value });
    const passwordCorrect = checkPassword(state.password);
    if (passwordCorrect) {
      setValidPassword(true);
      return true;
    } else {
      setValidPassword(false);
      return false;
    }
  };

  const validateWorkSpace = (e: any) => {
    setState({ ...state, org_name: e.target.value });
    // Workspace(Organisation) name should be >= 4
    const workSpaceCodeCorrect = state.org_name.length >= 4;
    if (workSpaceCodeCorrect) {
      setValidWorkSpace(true);
      return true;
    } else {
      setValidWorkSpace(false);
      return false;
    }
  };

  // Phone Input Styling
  const phoneInputStyles = {
    border: "2px solid #e5e7eb",
    width: "100%",
    height: "45px",
    borderRadius: "0px",
    fontFamily: "inherit",
    fontSize: "100%",
  };

  return (
    <Fragment>
      {isPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2">
            <SideBarType1 image={signup} url="/" />
          </div>
          <div className="col-span-5 md:col-span-4">
            <div className="w-5/6 h-screen m-auto top-12 p-4">
              <h2 className="text-2xl md:text-3xl text-black font-bold">
                Complete the Form Below to Signup
              </h2>
              <div className="pt-4">
                <label
                  htmlFor="email"
                  className="text-xl font-medium text-gray-500"
                >
                  Email
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-200  p-2 w-full focus:outline-none ${
                    validEmail
                      ? "focus:border-green-400"
                      : "focus:border-red-400"
                  } `}
                  placeholder="Enter email address..."
                  value={state.email}
                  onChange={validateEmail}
                  onSelect={validateEmail}
                  name="email"
                />
              </div>
              <div className="flex flex-wrap pt-4">
                <div className="w-full md:w-2/4 md:pr-2">
                  <label
                    htmlFor="firstName"
                    className="text-xl font-medium text-gray-500 font-sans"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    className="border-2 border-gray-200 p-2 w-full focus:outline-none  focus:border-blue-400"
                    placeholder="First Name"
                    name="firstName"
                    value={state.firstName}
                    onChange={(e) =>
                      setState({ ...state, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="w-full md:w-2/4 md:pl-2 mt-4 md:mt-0">
                  <label
                    htmlFor="lastName"
                    className="text-xl font-medium  text-gray-500"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="border-2 border-gray-200 p-2 w-full focus:outline-none  focus:border-blue-400"
                    placeholder="Last Name"
                    name="lastName"
                    value={state.lastName}
                    onChange={(e) =>
                      setState({ ...state, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-wrap pt-4">
                <label
                  htmlFor=""
                  className="text-xl font-medium  text-gray-500"
                >
                  Phone No
                </label>
                <PhoneInput
                  country={state.countryCode}
                  value={state.phoneNo}
                  onChange={(phoneNo) =>
                    setState({ ...state, phoneNo: phoneNo })
                  }
                  inputStyle={phoneInputStyles}
                  enableLongNumbers={true}
                  placeholder="Enter Phone No"
                />
              </div>
              <div className="pt-4">
                <label
                  htmlFor="password"
                  className="text-xl font-medium text-gray-500"
                >
                  Password
                </label>
                <input
                  type="password"
                  className={`border-2 border-gray-200  p-2 w-full focus:outline-none ${
                    validPassword
                      ? "focus:border-green-400"
                      : "focus:border-red-400"
                  } `}
                  placeholder="Minimum 8 characters with 1 Number, 1 Small and 1 Capital Letter"
                  name="password"
                  value={state.password}
                  onChange={validatePassword}
                  onSelect={validatePassword}
                />
              </div>
              <div className="flex flex-wrap pt-4">
                <div className="w-full md:w-2/4 md:pr-2">
                  <label
                    htmlFor="gender"
                    className="text-xl font-medium  text-gray-500"
                  >
                    Gender
                  </label>
                  <select
                    name="gender"
                    className="border-2 border-gray-200 p-2 w-full focus:outline-none  focus:border-blue-400"
                    onChange={(e) =>
                      setState({ ...state, gender: e.target.value })
                    }
                    defaultValue=""
                  >
                    <option disabled value="">
                      Select Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="w-full md:w-2/4 md:pl-2 mt-4 md:mt-0">
                  <label
                    htmlFor="workSpaceCode"
                    className="text-xl font-medium text-gray-500"
                  >
                    Workspace Name
                  </label>

                  <input
                    type="text"
                    className={`border-2 border-gray-200  p-2 w-full focus:outline-none ${
                      validWorkSpace
                        ? "focus:border-green-400"
                        : "focus:border-red-400"
                    } `}
                    placeholder="Join or Create Workspace..."
                    name="workSpaceCode"
                    onChange={validateWorkSpace}
                    onSelect={validateWorkSpace}
                    value={state.org_name}
                  />
                  <small className="font-bold text-wecest-pink">
                    This is your Workspace Name. All your team members will join
                    using this unique Workspace Name or if you have permission
                    of existing workspace enter that here.
                  </small>
                </div>
              </div>
              <button
                className={`button-type-2 mt-2 w-full ${
                  buttonDisabled
                    ? "cursor-default opacity-50"
                    : "cursor-pointer"
                }`}
                onClick={createAccount}
                disabled={buttonDisabled}
              >
                CREATE ACCOUNT
              </button>
              <div className="flex w-5/6  md:w-4/6 pt-6">
                {!state.isLoading ? (
                  <Link
                    to="/login"
                    className="underline font-semibold"
                    onClick={() =>
                      Event(
                        LoginPageLinkOnSignupPage.category,
                        LoginPageLinkOnSignupPage.action,
                        LoginPageLinkOnSignupPage.label
                      )
                    }
                  >
                    Login To Your Account
                  </Link>
                ) : (
                  <img
                    src={loader}
                    alt="wecest loader"
                    className="w-12 m-auto"
                  />
                )}
              </div>
              {accountCreationSuccessNotification ? (
                <SuccessResponse response={accountCreationResponse} />
              ) : accountCreationFailedNotification ? (
                <FailureResponse response={accountCreationResponse} />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Signup;
