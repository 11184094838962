import React from "react";
import twitterIcon from "../images/twitter.png";
import linkedinIcon from "../images/linkedin.png";
import { Link, useLocation } from "react-router-dom";
import {
  privacyPolicyLinkHomepage,
  tryForFreeFooterHomePage,
  featuresLinkFooterHomepage,
  pricingLinkFooterHomepage,
  contactUsLinkHomepage,
  termsOfServiceLinkHomepage,
  tryForFreeFooterFeaturesPage,
  featuresLinkFooterFeaturesPage,
  pricingLinkFooterFeaturesPage,
  contactUsLinkFeaturesPage,
  termsOfServiceLinkFeaturesPage,
  privacyPolicyLinkFeaturesPage,
  tryForFreeFooterPricingPage,
  featuresLinkFooterPricingPage,
  pricingLinkFooterPricingPage,
  contactUsLinkPricingPage,
  termsOfServiceLinkPricingPage,
  privacyPolicyLinkPricingPage,
} from "../constants/analytics";
import { Event } from "../utils/eventTracking";

const FooterComponent: React.FC = () => {
  const location = useLocation();
  let privacyPolicyLink: any;
  let tryForFreeLink: any;
  let featuresPageLink: any;
  let pricingPageLink: any;
  let contactUsLink: any;
  let termsOfServiceLink: any;
  if (location.pathname.substring(1) === "") {
    tryForFreeLink = { ...tryForFreeFooterHomePage };
    featuresPageLink = { ...featuresLinkFooterHomepage };
    pricingPageLink = { ...pricingLinkFooterHomepage };
    contactUsLink = { ...contactUsLinkHomepage };
    privacyPolicyLink = { ...privacyPolicyLinkHomepage };
    termsOfServiceLink = { ...termsOfServiceLinkHomepage };
  } else if (location.pathname.substring(1) === "features") {
    tryForFreeLink = { ...tryForFreeFooterFeaturesPage };
    featuresPageLink = { ...featuresLinkFooterFeaturesPage };
    pricingPageLink = { ...pricingLinkFooterFeaturesPage };
    contactUsLink = { ...contactUsLinkFeaturesPage };
    privacyPolicyLink = { ...privacyPolicyLinkFeaturesPage };
    termsOfServiceLink = { ...termsOfServiceLinkFeaturesPage };
  } else if (location.pathname.substring(1) === "pricing") {
    tryForFreeLink = { ...tryForFreeFooterPricingPage };
    featuresPageLink = { ...featuresLinkFooterPricingPage };
    pricingPageLink = { ...pricingLinkFooterFeaturesPage };
    contactUsLink = { ...contactUsLinkPricingPage };
    privacyPolicyLink = { ...privacyPolicyLinkPricingPage };
    termsOfServiceLink = { ...termsOfServiceLinkPricingPage };
  }
  return (
    <>
      <div className="grid grid-cols-12 h-auto p-9">
        <div className="col-span-12  md:col-span-5">
          <div className="flex flex-wrap justify-start items-center py-12">
            <Link
              to="/signup"
              className="underline cursor-pointer px-5 py-3 md:py-4"
              onClick={() =>
                Event(
                  tryForFreeLink.category,
                  tryForFreeLink.action,
                  tryForFreeLink.label
                )
              }
            >
              Get Started For Free
            </Link>
            <Link
              to="/features"
              className="underline cursor-pointer px-5 py-3 md:py-4"
              onClick={() =>
                Event(
                  featuresPageLink.category,
                  featuresPageLink.action,
                  featuresPageLink.label
                )
              }
            >
              Features
            </Link>
            <Link
              to="/pricing"
              className="underline cursor-pointer px-5 py-3 md:py-4"
              onClick={() =>
                Event(
                  pricingPageLink.category,
                  pricingPageLink.action,
                  pricingPageLink.label
                )
              }
            >
              Pricing
            </Link>
            <Link
              to="/contact-support"
              className="underline cursor-pointer px-5 py-3 md:py-4"
              onClick={() =>
                Event(
                  contactUsLink.category,
                  contactUsLink.action,
                  contactUsLink.label
                )
              }
            >
              Contact Us
            </Link>
            <Link
              to="/privacy-policy"
              className="underline cursor-pointer px-5 py-3 md:py-4"
              onClick={() =>
                Event(
                  privacyPolicyLink.category,
                  privacyPolicyLink.action,
                  privacyPolicyLink.label
                )
              }
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-of-service"
              className="underline cursor-pointer px-5 py-3 md:py-4"
              onClick={() =>
                Event(
                  termsOfServiceLink.category,
                  termsOfServiceLink.action,
                  termsOfServiceLink.label
                )
              }
            >
              Terms of Service
            </Link>
          </div>
        </div>
        <div className="col-span-12 md:col-span-7">
          <div className="flex flex-col justify-center items-center w-full h-full">
            <span className="font-semibold">Follow us on:</span>
            <div className="flex flex-row mt-3">
              <a
                href="https://www.linkedin.com/company/audotask/"
                target="_blank"
                onClick={() =>
                  Event(
                    "LINKEDIN_CLICK",
                    "Linkedin Icon clicked in Footer",
                    "LINKEDIN"
                  )
                }
              >
                <img
                  src={linkedinIcon}
                  alt="audotask linkedin icon"
                  className="w-10 cursor-pointer"
                />
              </a>
              <a
                href="https://twitter.com/audotask"
                target="_blank"
                onClick={() =>
                  Event(
                    "TWITTER_CLICK",
                    "Twitter Icon clicked in Footer",
                    "TWITTER"
                  )
                }
              >
                <img
                  src={twitterIcon}
                  alt="audotask twitter icon"
                  className="w-10 ml-5 cursor-pointer"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="flex justify-center items-center w-full p-6">
        <span className="text-gray2 font-semibold">
          {" "}
          ©2021, AudoTask, All Rights Reserved
        </span>
      </div>
    </>
  );
};

export default FooterComponent;
