export const iconHoveredHomepage = {
  category: "NAVBAR_ICON_HOVER",
  action: "Navbar Rotating Icon being Hovered",
  label: "HOMEPAGE",
};

export const audoTaskClickHomepage = {
  category: "AUDOTASK_CLICK",
  action: "AudoTask Logo clicked on Navbar",
  label: "HOMEPAGE",
};
export const iconHoveredFeaturesPage = {
  category: "NAVBAR_ICON_HOVER",
  action: "Navbar Rotating Icon being Hovered",
  label: "FEATURESPAGE",
};

export const audoTaskClickFeaturesPage = {
  category: "AUDOTASK_CLICK",
  action: "AudoTask Logo clicked on Navbar",
  label: "FEATURESPAGE",
};

export const iconHoveredPricingPage = {
  category: "NAVBAR_ICON_HOVER",
  action: "Navbar Rotating Icon being Hovered",
  label: "PRICINGPAGE",
};

export const audoTaskClickPricingPage = {
  category: "AUDOTASK_CLICK",
  action: "AudoTask Logo clicked on Navbar",
  label: "PRICINGPAGE",
};

export const featuresNavLinkClicked = {
  category: "AUDOTASK_CLICK",
  action: "Features Navlink clicked",
  label: "NAVBAR",
};

export const pricingNavLinkClicked = {
  category: "AUDOTASK_CLICK",
  action: "Pricing Navlink clicked",
  label: "NAVBAR",
};

export const getHelpNavLinkClicked = {
  category: "AUDOTASK_CLICK",
  action: "Get Help Navlink clicked",
  label: "NAVBAR",
};

export const loginNavLinkClicked = {
  category: "AUDOTASK_CLICK",
  action: "Login Navlink clicked",
  label: "NAVBAR",
};

export const dashboardNavLinkClicked = {
  category: "AUDOTASK_CLICK",
  action: "Dashboard Navlink clicked",
  label: "NAVBAR",
};

export const menuOpenClicked = {
  category: "AUDOTASK_CLICK",
  action: "Menu open clicked",
  label: "NAVBAR",
};
export const menuCloseClicked = {
  category: "AUDOTASK_CLICK",
  action: "Menu close clicked",
  label: "NAVBAR",
};

export const openDashboard = {
  category: "DASHBAORD_OPEN",
  action: "Open the Dashbaord page",
  label: "HOMEPAGE",
};

export const tryForFree = {
  category: "SIGNUP",
  action: "Try For Free button clicked",
  label: "HOMEPAGE",
};

export const dashboardFlowImage = {
  category: "WORKFLOW_HOVER",
  action: "Mouse Hover on Workflow image on Homepage",
  label: "HOMEPAGE",
};

export const freePlanHomepage = {
  category: "FREE_PLAN_START_BUTTON",
  action: "Get Started button for Free plan clicked on Homepage",
  label: "HOMEPAGE",
};

export const proPlanMonthlyHomepage = {
  category: "PRO_PLAN_MONTHLY_PAYMENT",
  action: "Get Started button for Monthly Pro plan clicked on Homepage",
  label: "HOMEPAGE",
};

export const proPlanQuarterlyHomepage = {
  category: "PRO_PLAN_QUARTERLY_PAYMENT",
  action: "Get Started button for Quarterly Pro plan clicked on Homepage",
  label: "HOMEPAGE",
};

export const proPlanYearlyHomepage = {
  category: "PRO_PLAN_YEARLY_PAYMENT",
  action: "Get Started button for Yearly Pro plan clicked on Homepage",
  label: "HOMEPAGE",
};

export const freePlanPricingPage = {
  category: "FREE_PLAN_START_BUTTON",
  action: "Get Started button for Free plan clicked on Pricing plan page",
  label: "PRICING_PAGE",
};

export const proPlanMonthlyPricingPage = {
  category: "PRO_PLAN_MONTHLY_PAYMENT",
  action:
    "Get Started button for Monthly Pro plan clicked on Pricing plan page",
  label: "PRICING_PAGE",
};

export const proPlanQuarterlyPricingPage = {
  category: "PRO_PLAN_QUARTERLY_PAYMENT",
  action:
    "Get Started button for Quarterly Pro plan clicked on Pricing plan page",
  label: "PRICING_PAGE",
};

export const proPlanYearlyPricingPage = {
  category: "PRO_PLAN_YEARLY_PAYMENT",
  action: "Get Started button for Yearly Pro plan clicked on Pricing plan page",
  label: "PRICING_PAGE",
};

export const getDetailInformationFeatures = {
  category: "FEATURES_INFORMATION",
  action: "Get Detailed Information about Features Link clicked Homepage",
  label: "HOMEPAGE",
};

export const talkToUsButtonHomepageClicked = {
  category: "TALKTOUS_HOMEPAGE",
  action: "Talk To us Button on Homepage Clicked",
  label: "HOMEPAGE",
};

export const talkToUsButtonFeaturespageClicked = {
  category: "TALKTOUS_FEATURESPAGE",
  action: "Talk To us Button on Features Clicked",
  label: "FEATURESPAGE",
};

export const talkToUsButtonPricingpageClicked = {
  category: "TALKTOUS_PRICINGPAGE",
  action: "Talk To us Button on Pricing Clicked",
  label: "PRICING_PAGE",
};

export const tryForFreeFooterHomePage = {
  category: "SIGNUP_FOOTER_HOMEPAGE",
  action: "Get Started For Free link clicked on Footer Homepage",
  label: "FOOTER_HOMEPAGE",
};
export const featuresLinkFooterHomepage = {
  category: "AUDOTASK_CLICK_FOOTER_HOMEPAGE",
  action: "Features link in footer clicked",
  label: "FOOTER_HOMEPAGE",
};

export const pricingLinkFooterHomepage = {
  category: "AUDOTASK_CLICK_FOOTER_HOMEPAGE",
  action: "Pricing link in footer clicked",
  label: "FOOTER_HOMEPAGE",
};

export const contactUsLinkHomepage = {
  category: "AUDOTASK_CLICK_FOOTER_HOMEPAGE",
  action: "Get Help link in footer clicked",
  label: "FOOTER_HOMEPAGE",
};

export const privacyPolicyLinkHomepage = {
  category: "AUDOTASK_CLICK_FOOTER_HOMEPAGE",
  action: "Privacy policy link in footer clicked",
  label: "FOOTER_HOMEPAGE",
};

export const termsOfServiceLinkHomepage = {
  category: "AUDOTASK_CLICK_FOOTER_HOMEPAGE",
  action: "Terms of Service link in footer clicked",
  label: "FOOTER_HOMEPAGE",
};

export const tryForFreeFooterFeaturesPage = {
  category: "SIGNUP_FOOTER_FEATURESPAGE",
  action: "Get Started For Free link clicked on Footer Features Page",
  label: "FOOTER_FEATURESPAGE",
};
export const featuresLinkFooterFeaturesPage = {
  category: "AUDOTASK_CLICK_FOOTER_FEATURESPAGE",
  action: "Features link in footer clicked",
  label: "FOOTER_FEATURESPAGE",
};

export const pricingLinkFooterFeaturesPage = {
  category: "AUDOTASK_CLICK_FOOTER_FEATURESPAGE",
  action: "Pricing link in footer clicked",
  label: "FOOTER_FEATURESPAGE",
};

export const contactUsLinkFeaturesPage = {
  category: "AUDOTASK_CLICK_FOOTER_FEATURESPAGE",
  action: "Get Help link in footer clicked",
  label: "FOOTER_FEATURESPAGE",
};

export const privacyPolicyLinkFeaturesPage = {
  category: "AUDOTASK_CLICK_FOOTER_FEATURESPAGE",
  action: "Privacy policy link in footer clicked",
  label: "FOOTER_FEATURESPAGE",
};

export const termsOfServiceLinkFeaturesPage = {
  category: "AUDOTASK_CLICK_FOOTER_FEATURESPAGE",
  action: "Terms of Service link in footer clicked",
  label: "FOOTER_FEATURESPAGE",
};

export const tryForFreeFooterPricingPage = {
  category: "SIGNUP_FOOTER_PRICINGPAGE",
  action: "Get Started For Free link clicked on Footer Pricing Page",
  label: "FOOTER_PRICINGPAGE",
};
export const featuresLinkFooterPricingPage = {
  category: "AUDOTASK_CLICK_FOOTER_PRICINGPAGE",
  action: "Features link in footer clicked",
  label: "FOOTER_PRICINGPAGE",
};

export const pricingLinkFooterPricingPage = {
  category: "AUDOTASK_CLICK_FOOTER_PRICINGPAGE",
  action: "Pricing link in footer clicked",
  label: "FOOTER_PRICINGPAGE",
};

export const contactUsLinkPricingPage = {
  category: "AUDOTASK_CLICK_FOOTER_PRICINGPAGE",
  action: "Get Help link in footer clicked",
  label: "FOOTER_PRICINGPAGE",
};

export const privacyPolicyLinkPricingPage = {
  category: "AUDOTASK_CLICK_FOOTER_PRICINGPAGE",
  action: "Privacy policy link in footer clicked",
  label: "FOOTER_PRICINGPAGE",
};

export const termsOfServiceLinkPricingPage = {
  category: "AUDOTASK_CLICK_FOOTER_PRICINGPAGE",
  action: "Terms of Service link in footer clicked",
  label: "FOOTER_PRICINGPAGE",
};

export const freePlanGetStartedFeaturesPage = {
  category: "FREE_PLAN_START_BUTTON",
  action: "Get Started For Free button clicked on Features page",
  label: "FEATURESPAGE",
};

export const proPlanGetStartedFeaturesPage = {
  category: "PRO_PLAN_PAYMENT",
  action: "Get Started Pro plan button clicked on Features page",
  label: "FEATURESPAGE",
};

export const freePlanGetStartedPricingPage = {
  category: "FREE_PLAN_START_BUTTON",
  action: "Get Started For Free button clicked on Pricing page",
  label: "PRICING_PAGE",
};

export const proPlanGetStartedPricingPage = {
  category: "PRO_PLAN_PAYMENT",
  action: "Get Started Pro plan button clicked on Pricing page",
  label: "PRICING_PAGE",
};

export const forgotPassword = {
  category: "FORGOT_PASSWORD_SEND_EMAIL",
  action: "Send password reset email button clicked",
  label: "FORGOT-PASSWORD",
};

export const verifyAccount = {
  category: "VERIFY_ACCOUNT_SEND_EMAIL",
  action: "Account verification send email button clicked",
  label: "VERIFY-ACCOUNT",
};

export const signupButton = {
  category: "CREATE_ACCOUNT",
  action: "Signup i.e Create Account button clicked",
  label: "SIGNUP",
};
export const loginButton = {
  category: "LOGIN_ACCOUNT",
  action: "Login button clicked by the user",
  label: "LOGIN",
};

export const LoginPageLinkOnSignupPage = {
  category: "LOGIN_TO_YOUR_ACCOUNT_LINK_SIGNUP_PAGE",
  action: "Login to your account link on signup page clicked",
  label: "SIGNUP",
};

export const SignupPageLinkOnLoginPage = {
  category: "CREATE_NEW_ACCOUNT_LINK_LOGIN_PAGE",
  action: "Create New Account Link clicked on Login page",
  label: "LOGIN",
};

export const forgotPasswordLinkOnLoginPage = {
  category: "FORGOT_PASSWORD_LINK_LOGIN_PAGE",
  action: "Forgot Password Link clicked on Login page",
  label: "LOGIN",
};

export const accountVerifyLinkonForgotPasswordPage = {
  category: "ACCOUNT_VERIFY_LINK_FORGOT_PASSWORD_PAGE",
  action: "Account verify link clicked on Forgot password page",
  label: "FORGOT-PASSWORD",
};

export const LoginPageLinkOnVerifyAccountPage = {
  category: "LOGIN_TO_YOUR_ACCOUNT_LINK_VERIFY_ACCOUNT_PAGE",
  action: "Login to your account link on verify account page clicked",
  label: "VERIFY-ACCOUNT",
};

export const audoTaskClickSidebar1 = {
  category: "AUDOTASK_CLICK_SIDEBAR1",
  action: "AudoTask Logo clicked on Sidebar type 1",
  label: "SIDEBAR1",
};

export const audoTaskClickSidebar2 = {
  category: "AUDOTASK_CLICK_SIDEBAR2",
  action: "AudoTask Logo clicked on Sidebar type 2 which is of Dashboard",
  label: "SIDEBAR2",
};

export const updateAccountInfo = {
  category: "UPDATE_ACCOUNT_INFO",
  action: "User Account Information button clicked",
  label: "ACCOUNT-INFO",
};

export const updateMemberPermissionIcon = {
  category: "UPDATE_MEMBER_PERMISSION_ICON_CLICK",
  action: "Update Member permission icon with the link clicked",
  label: "MEMBERS",
};

export const deleteMemberIcon = {
  category: "DELETE_MEMBER_ICON_CLICK",
  action: "Delete Member icon clicked",
  label: "MEMBERS",
};

export const addNewMember = {
  category: "ADD_MEMBER_CLICK",
  action: "Add Member button clicked",
  label: "ADD-MEMBER",
};

export const membersNotYetJoined = {
  category: "MEMBERS_NOT_YET_JOINED_CLICK",
  action: "Members not yet joined link clicked",
  label: "ADD-MEMBER",
};

export const addNewMemberLink = {
  category: "ADD_NEW_MEMBER_PAGE_LINK_CLICK",
  action: "Link to Add Member page clicked",
  label: "PENDING-MEMBERS",
};

export const addNewSheetClick = {
  category: "ADD_NEW_SHEET_ICON_CLICK",
  action: "Add new sheet clicked by user",
  label: "DASHBOARD",
};

export const addNewFolderClick = {
  category: "ADD_NEW_FOLDER_ICON_CLICK",
  action: "Add new Folder clicked by user",
  label: "DASHBOARD",
};

export const addNewTaskButtonClick = {
  category: "ADD_NEW_TASK_CLICK",
  action: "Add New Task button clicked",
  label: "DASHBOARD",
};

export const newTaskSubmitButtonClick = {
  category: "NEW_TASK_SUBMIT_BUTTON_CLICK",
  action: "New Task submit button clicked",
  label: "DASHBOARD",
};

export const newTaskVoiceRecordButtonClick = {
  category: "NEW_TASK_VOICE_RECORD_CLICK",
  action: "New task voice recording button clicked button clicked",
  label: "DASHBOARD",
};

export const markTaskDoneClick = {
  category: "MARK_TASK_DONE_CLICK",
  action: "Mark task done clicked by user",
  label: "DASHBOARD",
};

export const markTaskUnDoneClick = {
  category: "MARK_TASK_UNDONE_CLICK",
  action: "Mark task undone clicked by user",
  label: "DASHBOARD",
};

export const taskDeleteClick = {
  category: "TASK_DELETE_CLICK",
  action: "Task Delete clicked",
  label: "DASHBOARD",
};

export const taskDetailsOpen = {
  category: "TASK_DETAILS_MODAL_OPEN",
  action: "Modal having taskdetails opened",
  label: "DASHBOARD",
};

export const audioListenTaskDetails = {
  category: "AUDIO_LISTEN_CARDTASK_INFO_CLICK",
  action: "Audio attached to the task listened from card view on dashboard",
  label: "DASHBOARD",
};

export const audioListenTaskDetailsModalOpen = {
  category: "AUDIO_LISTEN_MODAL_OPEN",
  action:
    "Audio attached to the task listened from modal opened by the user having all task details",
  label: "MODAL_OEPN",
};

export const taskEditIconClick = {
  category: "TASK_EDIT_ICON_CLICK",
  action: "Task Edit Icon inside the modal clicked",
  label: "MODAL_OEPN",
};

export const taskInfoModalCloseIconClick = {
  category: "TASK_INFO_MODAL_CLOSE",
  action: "Modal having task information closed",
  label: "MODAL_OPEN",
};

export const editTaskModalClose = {
  category: "EDIT_TASK_MODAL_CLOSE",
  action: "Edit task modal close icon clicked",
  label: "EDIT_TASK",
};

export const editTaskSubmitClick = {
  category: "EDIT_TASK_UPDATE",
  action: "Save button on Edit task modal clicked",
  label: "EDIT_TASK",
};

export const audioListenEditTaskButtonClick = {
  category: "NEW_TASK_VOICE_RECORD_CLICK",
  action: "Voice Record Icon in Edit Task Modal click",
  label: "EDIT_TASK",
};

export const foldersMembersModalOpen = {
  category: "FOLDER_MEMBERS_MODAL_OPEN",
  action: "Modal having folder members opened",
  label: "DASHBOARD",
};

export const paymentButtonClick = {
  category: "PAYMENT_BUTTON_CLICK",
  action: "Payment button on payment page clicked",
  label: "PAYMENTS",
};

export const addProMembersPaymentButtonClick = {
  category: "ADD_PRO_USERS_PAGE_PAYMENT_BUTTON_CLICK",
  action: "Payment button on add pro members page clicked",
  label: "PAYMENTS",
};

export const paymentSuccessContactSupportClick = {
  category: "PAYMENT_SUCCESS_SUPPORT_CONTACT_LINK_CLICK",
  action: "Contact support link in payment success click",
  label: "PAYMENTS",
};
export const paymentFailureContactSupportClick = {
  category: "PAYMENT_FAILURE_SUPPORT_CONTACT_LINK_CLICK",
  action: "Contact support link in payment failure click",
  label: "PAYMENTS",
};

export const addProMembersToCurrentPlan = {
  category: "ADD_MORE_USERS_TO_PRO_PLAN",
  action: "Add More user to Pro Plan link clicked on payments page",
  label: "PAYMENTS",
};

export const getProductDemoLinkClicked = {
  category: "GET_PRODUCT_DEMO_LINK_CLICK",
  action: "Get Product Demo Link on Hellobar clicked",
  label: "GET_PRODUCT_DEMO",
};

export const watchAudotaskTutorialClickedDashboard = {
  category: "WATCH_TUTORIAL_DASHBOARD",
  action: "Tutorial Video Play button on Dashboard Page clicked",
  label: "WATCH_DEMO",
};

export const watchAudotaskTutorialClickedFeaturesPage = {
  category: "WATCH_TUTORIAL_FEATURES",
  action: "Tutorial Video Play button on Features Page clicked",
  label: "WATCH_DEMO",
};
