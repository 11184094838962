import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style/main.css";
import "./App.css";
import ReactGA from "react-ga";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import PricingPage from "./pages/Pricing";
import SignupPage from "./pages/Signup";
import ForgotPasswordPage from "./pages/ForgotPassword";
import VerifyAccountPage from "./pages/VerifyAccount";
import VerifyEmailPage from "./pages/VerifyEmail";
import UpdatePasswordPage from "./pages/UpdatePassword";
import DashboardPage from "./pages/Dashboard";
import AccountInfo from "./pages/Account";
import WorkspaceMembers from "./pages/WorkspaceMembers";
import UpdateMemberPermission from "./pages/UpdateMemberPermission";
import AddMember from "./pages/AddMember";
import PendingMembers from "./pages/PendingMembers";
import FeaturesPage from "./pages/Features";
import PaymentsPage from "./pages/Payment";
import Support from "./pages/Support";
import { checkUserAuthorizedStatus } from "./utils/auth";
import PageLoadingComponent from "./components/PageLoadingComponent";
import { RootState } from "../src/reducers";
import { USER_AUTHORIZATION } from "./constants/user";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import TermsOfServicePage from "./pages/TermsofService";
import ProductLaunchPage from "./pages/ProductLaunch";
import AddProMembersPage from "./pages/AddProMembers";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-206139597-3");
} else {
  ReactGA.initialize("UA-206139597-2");
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      {" "}
      <App />
    </Provider>
  );
};

function App(props: any) {
  const [pageLoading, setPageLoading] = useState(true);
  const [pageStatus, setPageStatus] = useState(400);
  const dispatch = useDispatch();
  useEffect(() => {
    async function authUser() {
      try {
        const status = (await checkUserAuthorizedStatus()) || 500;
        dispatch({
          type: USER_AUTHORIZATION,
          payload: status === 200 ? true : false,
        });
        setPageStatus(status);
        setPageLoading(false);
      } catch (err) {
        dispatch({
          type: USER_AUTHORIZATION,
          payload: false,
        });
        setPageLoading(false);
      }
    }
    authUser();
  }, []);
  const isUserAuthorized = useSelector(
    (state: RootState) => state.user.isUserAuthorized
  );

  return (
    <Router>
      {process.env.REACT_APP_IS_PRODUCT_LAUCH === "active" ? (
        <Switch>
          <Route path="/" exact component={ProductLaunchPage} />
          <Route path="*" exact component={() => <Redirect to="/" />} />
        </Switch>
      ) : (
        <Switch>
          <Route
            path="/login"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : !isUserAuthorized ? (
                <LoginPage />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/signup"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : !isUserAuthorized ? (
                <SignupPage />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/forgot-password"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : !isUserAuthorized ? (
                <ForgotPasswordPage />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/verify-account"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : !isUserAuthorized ? (
                <VerifyAccountPage />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/verify-email"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : !isUserAuthorized ? (
                <VerifyEmailPage />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route path="/password-reset" exact component={UpdatePasswordPage} />

          <Route
            path="/payment"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : isUserAuthorized ? (
                <PaymentsPage />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/add-pro-members"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : isUserAuthorized ? (
                <AddProMembersPage />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/dashboard"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : isUserAuthorized ? (
                <DashboardPage />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/account-info"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : isUserAuthorized ? (
                <AccountInfo />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/members"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : isUserAuthorized ? (
                <WorkspaceMembers />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/update-permission/:id"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : isUserAuthorized ? (
                <UpdateMemberPermission />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/add-member"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : isUserAuthorized ? (
                <AddMember />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Route
            path="/pending-members"
            exact
            render={() =>
              pageLoading ? (
                <PageLoadingComponent />
              ) : isUserAuthorized ? (
                <PendingMembers />
              ) : (
                <Redirect to="/login" />
              )
            }
          />

          <Route path="/" exact component={HomePage} />
          <Route path="/contact-support" exact component={Support} />
          <Route path="/features" exact component={FeaturesPage} />
          <Route path="/pricing" exact component={PricingPage} />
          <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
          <Route
            path="/terms-of-service"
            exact
            component={TermsOfServicePage}
          />
          <Route path="*" exact component={() => <Redirect to="/" />} />
        </Switch>
      )}
    </Router>
  );
}

export { AppWrapper, App };
