import React from "react";
import { Link } from "react-router-dom";
import planExpiredImage from "../images/plan_expired.png";

interface planExpiredProps {
  creatorAccess: boolean;
  creatorEmail: string;
}

const PlanExpiredComponent: React.FC<planExpiredProps> = ({
  creatorAccess,
  creatorEmail,
}) => {
  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-full my-6 mx-auto max-w-sm md:max-w-4xl h-auto">
        <div className="border-0 rounded-lg shadow-2xl relative flex flex-col w-full bg-white outline-none focus:outline-none p-12">
          <div className="flex flex-col">
            <img src={planExpiredImage} alt="Plan Expiry Image" />
            <div className="block mt-7 text-sm">
              <p className="text-gray-700">
                Your AudoTask Pro Plan has Expired!
              </p>
              <p className="mt-2 text-gray-700">
                To Continue using AudoTask, please re-activate your Pro Plan!
              </p>
              <p className="mt-2 text-gray-700">
                User with the Creator Access(account from which workspace was
                created initially) for the workspace can re-activate the plan.
                For your workspace it’s{" "}
                <span className="underline font-medium text-wecest-pink">
                  {creatorEmail || ""}
                </span>
              </p>
              <p className="mt-2 text-gray-700">
                For any support or queries, please
                <Link
                  to="/contact-support"
                  className="underline font-medium text-wecest-pink px-1"
                >
                  contact us here
                </Link>
              </p>
              {creatorAccess ? (
                <Link
                  className={`button-type-2 w-48 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mt-5`}
                  to="/payment"
                >
                  ACTIVATE PLAN
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanExpiredComponent;
