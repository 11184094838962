import {
  VERIFY_COOKIE,
  PAYMENT_PAGE_LOADING,
  PRO_PLAN_ACTIVE,
  ERROR_HAPPENED,
} from "../constants/payments";

const initialState = {
  cookieData: {},
  errorMessage: "",
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case VERIFY_COOKIE:
      return {
        ...state,
        cookieData: action.payload,
      };
    case ERROR_HAPPENED:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
