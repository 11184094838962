import axios from "axios";
import _ from "lodash";
import { API_FAILURE, COOKIE_ALTERED } from "../constants/response";
import Cookies from "universal-cookie";
import { VERIFY_COOKIE } from "../constants/payments";
const cookie = new Cookies();
const secureApi = axios.create({
  withCredentials: true,
});

export const verifyCookie = async (dispatch: any) => {
  try {
    const discountCookie = cookie.get("discount") || null;

    if (discountCookie) {
      const uid = cookie.get("uid");
      let finalObj: any = { uid, discount: discountCookie };
      const { data } = await secureApi.post(
        `${process.env.REACT_APP_API_URL}/api/cookie/verify-cookie`,
        finalObj
      );
      dispatch({
        type: VERIFY_COOKIE,
        payload: data,
      });
    }
  } catch (err) {}
};
