import {
  USER_SIGNUP,
  USER_LOGIN,
  VERIFY_USER_LOGGEDIN,
  UPDATE_USER_LOGGEDIN,
  GET_USER_LOGGEDIN,
  USER_LOGOUT,
  PAGE_LOADING,
  CURRENT_MEMBER_CHANGE_ACCESS,
  CURRENT_PAGE_LOADING,
  USER_AUTHORIZATION,
} from "../constants/user";

const initialState = {
  user: {},
  loggedInUser: {},
  changeMemberAccess: {},
  isLoggedin: false,
  pageLoading: true,
  currentPageLoading: false,
  isUserAuthorized: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case USER_SIGNUP:
      return {
        ...state,
        user: action.payload,
      };
    case USER_LOGIN:
      return {
        ...state,
        loggedInUser: action.payload,
        isLoggedin: !state.isLoggedin,
        isUserAuthorized: true,
      };
    case VERIFY_USER_LOGGEDIN:
      return {
        ...state,
        isLoggedin: action.payload,
      };
    case GET_USER_LOGGEDIN:
      return {
        ...state,
        loggedInUser: action.payload,
        pageLoading: false,
      };
    case UPDATE_USER_LOGGEDIN:
      return {
        ...state,
        loggedInUser: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loggedInUser: {},
        isLoggedin: !state.isLoggedin,
        isUserAuthorized: false,
      };
    case CURRENT_MEMBER_CHANGE_ACCESS:
      return {
        ...state,
        changeMemberAccess: action.payload,
      };
    case PAGE_LOADING:
      return {
        ...state,
        pageLoading: !state.pageLoading,
      };
    case CURRENT_PAGE_LOADING:
      return {
        ...state,
        currentPageLoading: !state.currentPageLoading,
      };
    case USER_AUTHORIZATION:
      return {
        ...state,
        isUserAuthorized: action.payload,
      };
    default:
      return state;
  }
}
