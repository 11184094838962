import React from "react";
import closeIcon from "../images/close_icon_dark.svg";

interface tutorialComponentProps {
  toggleModelState?: () => void;
}

const TutorialCompoenent: React.FC<tutorialComponentProps> = ({
  toggleModelState,
}) => {
  return (
    <>
      <div className="flex p-3 w-full justify-between border-b border-solid border-slate-200">
        <h2 className="text-3xl font-semibold text-gray-600">
          Audotask Tutorial
        </h2>
        <img
          src={closeIcon}
          alt="Close Modal Icon"
          className="cursor-pointer"
          onClick={toggleModelState}
        />
      </div>
      <div className="block w-full">
        <iframe
          width="100%"
          height="420"
          src="https://www.youtube.com/embed/In2BZgyX0Cw?autoplay=1"
          title="YouTube video player"
          frameBorder="0"
          allowFullScreen
          allow="autoplay"
        ></iframe>
      </div>
    </>
  );
};
export default TutorialCompoenent;
