import axios from "axios";
import { API_FAILURE, COOKIE_ALTERED } from "../constants/response";
import {
  CREATE_NEW_TASK,
  SAVE_TASK,
  ERROR_MESSAGE,
  TASK_LOADING_COMPLETE,
  CLOSE_TASK_DETAILS_MODAL,
  TASK_DETAILS_LOADING_COMPLETE,
  TOGGLE_TASK_DONE,
  DELETE_TASK,
  UPDATE_TASK,
  LOAD_TASKS,
  SEARCH_TEXT,
} from "../constants/task";
import { SAVE_DATA, ERROR_OCCURED } from "../constants/workspace";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const secureApi = axios.create({
  withCredentials: true,
});

export const createTask: any = async (
  taskData: any,
  dispatch: any,
  audioFile: File,
  tasksDataObject: any
) => {
  try {
    dispatch({
      type: SAVE_TASK,
    });

    const uid = cookies.get("uid");
    taskData["uid"] = uid;
    if (audioFile.name === "user.mp3") {
      let formData = new FormData();
      formData.append("taskAudio", audioFile);
      for (let data in taskData) {
        if (taskData.hasOwnProperty(data)) {
          formData.append(data, taskData[data]);
        }
      }
      const {
        data: { taskCreated },
      } = await secureApi.post(
        `${process.env.REACT_APP_API_URL}/api/task/register`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      let tasksList = [...tasksDataObject.Tasks];
      tasksList.unshift(taskCreated);
      tasksDataObject.Tasks = tasksList;

      const tasksObj = {
        newTask: taskCreated,
        tasksData: tasksDataObject,
      };

      dispatch({
        type: CREATE_NEW_TASK,
        payload: tasksObj,
      });
    } else {
      const {
        data: { taskCreated },
      } = await secureApi.post(
        `${process.env.REACT_APP_API_URL}/api/task/register`,
        taskData
      );

      const tasksList = [...tasksDataObject.Tasks];
      tasksList.unshift(taskCreated);
      tasksDataObject.Tasks = tasksList;
      const tasksObj = {
        newTask: taskCreated,
        tasksData: tasksDataObject,
      };
      dispatch({
        type: CREATE_NEW_TASK,
        payload: tasksObj,
      });
    }
  } catch (error) {
    const message = "";
    dispatch({
      type: ERROR_MESSAGE,
      payload: message,
    });
  }
};

export const getTasks = async (sheetId: string, dispatch: any) => {
  try {
    dispatch({
      type: SEARCH_TEXT,
      payload: "",
    });
    const uid = cookies.get("uid");
    const {
      data: { tasksInSheet },
    } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/task/sheet/${sheetId}`,
      { uid },
      {
        params: sheetId,
      }
    );
    const tasks = tasksInSheet?.Tasks;
    let sortedTasksDescendingOrder =
      tasks &&
      tasks.sort((d1: any, d2: any) => (d2.createdAt > d1.createdAt ? 1 : -1));

    tasksInSheet.Tasks = sortedTasksDescendingOrder;
    dispatch({
      type: TASK_LOADING_COMPLETE,
      payload: tasksInSheet,
    });
  } catch (error) {
    const message = "";
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const getTaskDetails = async (taskId: string, dispatch: any) => {
  try {
    dispatch({
      type: CLOSE_TASK_DETAILS_MODAL,
    });

    const uid = cookies.get("uid");
    const {
      data: { task },
    } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/task/${taskId}`,
      { uid },
      {
        params: taskId,
      }
    );
    dispatch({
      type: TASK_DETAILS_LOADING_COMPLETE,
      payload: task,
    });
  } catch (error) {
    const message = "";
    dispatch({
      type: ERROR_MESSAGE,
      payload: message,
    });
  }
};

export const updateTaskDone = async (
  taskId: string,
  done: boolean,
  dispatch: any,
  tasksData: any
) => {
  try {
    dispatch({
      type: SAVE_DATA,
    });
    const uid = cookies.get("uid");
    const updatedTasks = tasksData.Tasks.map((task: any) => {
      if (task.task_id === taskId) task.done = done;
      return task;
    });

    dispatch({
      type: TOGGLE_TASK_DONE,
      payload: updatedTasks,
    });
    const taskData = {
      uid,
      task_id: taskId,
      done,
    };
    const { data } = await secureApi.patch(
      `${process.env.REACT_APP_API_URL}/api/task/update-task-done`,
      taskData
    );
    dispatch({
      type: SAVE_DATA,
    });
  } catch (error) {
    const message = "";
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

// Delete task

export const deleteTask = async (
  taskId: string,
  dispatch: any,
  tasksData: any
) => {
  try {
    dispatch({
      type: SAVE_DATA,
    });
    const uid = cookies.get("uid");
    const { data } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/task/delete/${taskId}`,
      { uid },
      {
        params: taskId,
      }
    );
    const updatedTasks = tasksData.Tasks.filter(
      (task: any) => task.task_id !== taskId
    );
    tasksData.Tasks = updatedTasks;
    dispatch({
      type: DELETE_TASK,
      payload: tasksData,
    });
    dispatch({
      type: SAVE_DATA,
    });
  } catch (error) {
    const message = "";
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const updateTask = async (
  taskData: any,
  dispatch: any,
  audioFile: File,
  tasksDataObject: any
) => {
  try {
    dispatch({
      type: SAVE_TASK,
    });
    const uid = cookies.get("uid");
    taskData["uid"] = uid;
    if (audioFile.name === "user.mp3") {
      let formData = new FormData();
      formData.append("taskAudio", audioFile);
      for (let data in taskData) {
        if (taskData.hasOwnProperty(data)) {
          formData.append(data, taskData[data]);
        }
      }
      const {
        data: { taskUpdated },
      } = await secureApi.patch(
        `${process.env.REACT_APP_API_URL}/api/task/edit`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      tasksDataObject.Tasks.forEach((task: any) => {
        if (task.task_id === taskUpdated.task_id) {
          Object.assign(task, taskUpdated);
        }
      });

      dispatch({
        type: UPDATE_TASK,
        payload: tasksDataObject,
      });
    } else {
      const {
        data: { taskUpdated },
      } = await secureApi.patch(
        `${process.env.REACT_APP_API_URL}/api/task/edit`,
        taskData
      );
      tasksDataObject.Tasks.forEach((task: any) => {
        if (task.task_id === taskUpdated.task_id) {
          Object.assign(task, taskUpdated);
        }
      });

      dispatch({
        type: UPDATE_TASK,
        payload: tasksDataObject,
      });
    }
  } catch (error) {
    const message = "";
    dispatch({
      type: ERROR_MESSAGE,
      payload: message,
    });
  }
};
