export const GET_MEMBERS = "GET_MEMBERS";
export const ADD_MEMBER = "ADD_MEMBER";
export const LOAD_MEMBERS = "LOAD_MEMBERS";
export const SHOW_MEMBERS_MODAL = "SHOW_MEMBERS_MODAL";
export const SAVE_MEMBERS = "SAVE_MEMBERS";
export const ERROR_OCCURED = "ERROR_OCCURED";
export const UPDATE_MEMBER_PERMISSION = "UPDATE_MEMBER_PERMISSION";
export const SUCCESS_MESSAGE = "SUCCESS_MESSAGE";
export const ERROR_MESSAGE_DELETE_MEMBER = "ERROR_MESSAGE_DELETE_MEMBER";
export const LOADING_DELETE_MEMBER = "LOADING_DELETE_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
