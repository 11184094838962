import {
  USER_SIGNUP,
  USER_LOGIN,
  VERIFY_USER_LOGGEDIN,
  UPDATE_USER_LOGGEDIN,
  GET_USER_LOGGEDIN,
  USER_LOGOUT,
  CURRENT_MEMBER_CHANGE_ACCESS,
  CURRENT_PAGE_LOADING,
} from "../constants/user";
import axios from "axios";
import Cookies from "universal-cookie";
import { API_FAILURE, COOKIE_ALTERED } from "../constants/response";

const cookies = new Cookies();
axios.defaults.withCredentials = true;

export const createUserAccount = async (dispatch: any, user: any) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/register`,
      user
    );
    const response = {
      status: result.status,
      message: result.data.message || "",
    };
    dispatch({
      type: USER_SIGNUP,
      payload: user,
    });
    return response;
  } catch (error) {
    const errorInfo = {
      status: error.response.status,
      message: error?.response?.data?.message || API_FAILURE,
    };
    return errorInfo;
  }
};

export const logInUser = async (dispatch: any, user: any) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/login`,
      user
    );
    cookies.set("uid", "", {
      path: "/",
      expires: new Date(Date.now() + 86400000 * 7),
    });
    dispatch({
      type: USER_LOGIN,
      payload: result.data.userData,
    });
    const response = {
      status: result.status,
      message: result.data.message,
    };
    localStorage.setItem("user", JSON.stringify(""));
    return response;
  } catch (error) {
    const errorInfo = {
      status: error.response.status,
      message: error?.response?.data?.message || API_FAILURE,
    };
    return errorInfo;
  }
};

export const verifyUserLoggedIn: any = async (dispatch: any) => {
  try {
    const uid = cookies.get("uid");

    const { data, status } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/loginverify/${uid}`,
      {
        uid,
      }
    );
    if (status === 200) {
      dispatch({
        type: VERIFY_USER_LOGGEDIN,
        payload: true,
      });
    }

    dispatch({
      type: CURRENT_PAGE_LOADING,
    });
  } catch (error) {
    dispatch({
      type: VERIFY_USER_LOGGEDIN,
      payload: false,
    });
    dispatch({
      type: CURRENT_PAGE_LOADING,
    });
  }
};

export const getloggedInUserDetails: any = async (dispatch: any) => {
  try {
    const uid = cookies.get("uid");
    if (!uid) {
      return;
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/${uid}`,
      { uid }
    );
    dispatch({
      type: GET_USER_LOGGEDIN,
      payload: data,
    });
    return { data, status: 200 };
  } catch (error) {
    const errorInfo = {
      status: error.response.status,
      message: error?.response?.data?.message || COOKIE_ALTERED,
    };
    return errorInfo;
  }
};

export const updateUserInfo: any = async (dispatch: any, userData: any) => {
  try {
    const userInfo = {
      uid: userData.uid,
      first_name: userData.first_name,
      last_name: userData.last_name,
      phoneNo: userData.phoneNo,
      gender: userData.gender,
    };
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/user/edit`,
      userInfo
    );
    dispatch({
      type: UPDATE_USER_LOGGEDIN,
      payload: userData,
    });
    return { data, status: 200 };
  } catch (error) {
    const errorInfo = {
      status: error.response.status,
      message: error?.response?.data?.message || COOKIE_ALTERED,
    };
    return errorInfo;
  }
};

export const logoutUser: any = async (dispatch: any) => {
  try {
    const uid = cookies.get("uid");
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/logout`,
      { uid }
    );
    cookies.remove("uid");

    dispatch({
      type: USER_LOGOUT,
    });
    localStorage.removeItem("user");
    return { data, status: 200 };
  } catch (error) {
    console.log(error.response);
    const errorInfo = {
      status: error.response.status,
      message: error?.response?.data?.message || COOKIE_ALTERED,
    };
    return errorInfo;
  }
};

export const getCurrentMember = async (memberId: string, dispatch: any) => {
  try {
    const uid = cookies.get("uid");
    if (!uid) {
      return;
    }
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/${memberId}`,
      { uid }
    );
    dispatch({
      type: CURRENT_MEMBER_CHANGE_ACCESS,
      payload: data,
    });
  } catch (error) {
    const errorInfo = {
      status: error.response.status,
      message: error?.response?.data?.message || COOKIE_ALTERED,
    };
    return errorInfo;
  }
};
