import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Event } from "../utils/eventTracking";

import FooterIllustration from "../images/footer_illustration.png";
import {
  talkToUsButtonHomepageClicked,
  talkToUsButtonFeaturespageClicked,
  talkToUsButtonPricingpageClicked,
} from "../constants/analytics";

const TalkToUS: React.FC = () => {
  const location = useLocation();

  let talkToUsButtonInfo: any;
  if (location.pathname.substring(1) === "") {
    talkToUsButtonInfo = { ...talkToUsButtonHomepageClicked };
  } else if (location.pathname.substring(1) === "features") {
    talkToUsButtonInfo = { ...talkToUsButtonFeaturespageClicked };
  } else if (location.pathname.substring(1) === "pricing") {
    talkToUsButtonInfo = { ...talkToUsButtonPricingpageClicked };
  }

  return (
    <div className="block h-auto">
      <div className="grid grid-cols-12 bg-wecest-pink p-12 h-full md:h-3/6">
        <div className="col-span-12 md:col-span-4">
          <div className="flex justify-center items-center w-full h-full">
            <img
              src={FooterIllustration}
              alt="audotask footer image"
              className="w-5/6"
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-8">
          <div className="flex flex-col justify-center w-full h-full items-start">
            <h1 className="text-4xl md:text-5xl font-medium text-white">
              Want to Discuss Something ?
            </h1>
            <span className="text-gray-200 py-5 pr-0 md:pr-8 font-medium">
              You can contact us by clicking the button below and filling the
              very basic form there. Once you do that, we will contact you very
              soon !
            </span>

            <Link
              to="/contact-support"
              className={`button-type-2 mt-2 w-56 cursor-pointer`}
              onClick={() =>
                Event(
                  talkToUsButtonInfo.category,
                  talkToUsButtonInfo.action,
                  talkToUsButtonInfo.label
                )
              }
            >
              TALK TO US
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TalkToUS;
