import { combineReducers } from "redux";
import userReducer from "./userReducer";
import workSpaceReducer from "./workSpaceReducer";
import memberReducer from "./memberReducer";
import folderReducer from "./folderReducer";
import voiceReducer from "./voiceRecord";
import taskReducer from "./taskReducer";
import paymentReducer from "./paymentReducer";

export const rootReducer = combineReducers({
  user: userReducer,
  workspace: workSpaceReducer,
  member: memberReducer,
  folder: folderReducer,
  voice: voiceReducer,
  task: taskReducer,
  payment: paymentReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
