export const CREATE_NEW_TASK_MODAL_OPEN = "CREATE_NEW_TASK_MODAL_OPEN";
export const CREATE_NEW_TASK = "CREATE_NEW_TASK";
export const SAVE_TASK = "SAVE_TASK";
export const ERROR_MESSAGE = "ERROR_MESSAGE";
export const TASK_LOADING_COMPLETE = "TASK_LOADING_COMPLETE";
export const LOAD_TASK_DETAILS = "LOAD_TASK_DETAILS";
export const TASK_DETAILS_LOADING_COMPLETE = "TASK_DETAILS_LOADING_COMPLETE";
export const CLOSE_TASK_DETAILS_MODAL = "CLOSE_TASK_DETAILS_MODAL";
export const TOGGLE_TASK_DONE = "TOGGLE_TASK_DONE";
export const DELETE_TASK = "DELETE_TASK";
export const EDIT_TASK_MODAL_OPEN = "EDIT_TASK_MODAL_OPEN";
export const CLOSE_EDIT_TASK_MODAL = "CLOSE_EDIT_TASK_MODAL";
export const UPDATE_TASK = "UPDATE_TASK";
export const SEARCH_TEXT = "SEARCH_TEXT";
export const LOAD_TASKS = "LOAD_TASKS";
