import React, { Fragment, ReactElement, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { getMembers, deleteMember } from "../actions/memberActions";
import { RootState } from "../reducers";
import SideBarType1 from "../components/SideBar1";
import { LOADING_DELETE_MEMBER, LOAD_MEMBERS } from "../constants/members";
import SelectImageQuote from "../components/selectImageQuote";

import signup from "../images/illustration_members.png";
import searchIcon from "../images/search_icon.svg";
import editIcon from "../images/edit_icon.svg";
import loadTasks from "../images/sound-waves.png";
import notAvailableIllustration from "../images/illustration_brainstorm.svg";
import deleteIcon from "../images/delete_icon.png";
import { DELTETE_MEMBER } from "../constants/response";
import loader from "../images/loader.gif";
import PageLoadingComponent from "../components/PageLoadingComponent";
import ReactGA from "react-ga";
import { Event } from "../utils/eventTracking";
import {
  updateMemberPermissionIcon,
  deleteMemberIcon,
} from "../constants/analytics";

const WorkspaceMembers: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    ReactGA.pageview("/members");
    dispatch({
      type: LOAD_MEMBERS,
    });
    getMembers(dispatch);
    setIsPageLoading(false);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentBgColor, setCurrentBgColor] = useState("bg-green-400");

  let membersList = useSelector((state: RootState) => state.member.members);
  const membersLoading = useSelector(
    (state: RootState) => state.member.membersLoading
  );
  const creator = useSelector((state: RootState) => state.member.creator);
  const user = JSON.parse(String(localStorage.getItem("user")));

  const loadingDeleteMember = useSelector(
    (state: RootState) => state.member.loadingMemberDelete
  );
  const errorDeletingMember: string = useSelector(
    (state: RootState) => state.member.errorDeletingMember
  );
  // search members by first name, last name or email
  membersList = membersList.filter((member: any) => {
    return (
      member.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      member.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const deleteMemberFromWorkspace = async (memberId: string) => {
    Event(
      deleteMemberIcon.category,
      deleteMemberIcon.action,
      deleteMemberIcon.label
    );
    const result = window.confirm(DELTETE_MEMBER);
    if (result) {
      dispatch({
        type: LOADING_DELETE_MEMBER,
        payload: true,
      });
      deleteMember(memberId, dispatch, membersList);
    }
  };

  return (
    <Fragment>
      {isPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2">
            <SideBarType1 image={signup} url="/dashboard" />
          </div>
          <div className="col-span-5 md:col-span-4">
            <div className="w-5/6  m-auto top-12 p-4">
              <h2 className="text-2xl md:text-3xl text-black font-bold">
                WORKSPACE MEMBERS
              </h2>
              {loadingDeleteMember && (
                <div className="flex justify-center w-full">
                  <img src={loader} alt="wecest laoder" className="w-7" />
                </div>
              )}
              <span className="flex justify-center w-full font-bold text-sm text-red-500">
                {errorDeletingMember}
              </span>
              <div className="flex mt-5 border-2 border-gray-300 shadow-sm mb-5">
                <input
                  type="text"
                  placeholder="Search Members..."
                  className=" w-full p-3 focus:outline-none"
                  onChange={(e: any) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                {!searchTerm ? (
                  <img
                    src={searchIcon}
                    alt="search icon"
                    className="w-5 md:w-7 mr-5"
                  />
                ) : null}
              </div>
              {membersLoading ? (
                <>
                  <div className="flex  justify-center items-center h-screen">
                    <PageLoadingComponent />
                  </div>
                </>
              ) : membersList.length ? (
                membersList.map((member: any) =>
                  member.email !== user.email ? (
                    <div
                      className="grid grid-cols-6 border-2 border-gray-200 mt-6 p-3 pt-5 pb-7 cursor-default h-32"
                      data-memberid={member.id}
                      key={member.id}
                    >
                      <div className="col-span-1">
                        <div className="flex justify-center items-center">
                          <div
                            style={{ borderRadius: "50%" }}
                            className={`mt-3 md:mt-2 text-white font-bold text-sm md:text-2xl w-9 md:w-12 p-3 md:p-4 ${currentBgColor} `}
                          >
                            {member.first_name.charAt(0).toUpperCase()}
                          </div>
                        </div>
                      </div>
                      <div className="col-span-4">
                        <div className="flex flex-col justify-center pl-5 ">
                          <h4 className="font-bold text-lg text-gray-700">
                            {member.first_name + " " + member.last_name}
                          </h4>

                          <span className="text-xs md:text-sm text-gray-700 pt-1">
                            {member.email}
                          </span>
                          <span className="text-xs md:text-sm pt-1">
                            {member.phoneNo}
                          </span>
                        </div>
                      </div>
                      {creator ? (
                        <>
                          <div className="col-span-1">
                            <Link
                              to={`/update-permission/${member.id}`}
                              className="text-md text-gray-500 font-medium"
                              onClick={() => {
                                Event(
                                  updateMemberPermissionIcon.category,
                                  updateMemberPermissionIcon.action,
                                  updateMemberPermissionIcon.label
                                );
                              }}
                            >
                              <img
                                src={editIcon}
                                alt="edit icon"
                                className="w-5 md:w-6 ml-auto cursor-pointer"
                              />
                            </Link>
                            <div className="text-md text-gray-500 font-medium">
                              <img
                                src={deleteIcon}
                                alt="edit icon"
                                className="w-5 mt-2 ml-auto cursor-pointer"
                                onClick={() =>
                                  deleteMemberFromWorkspace(member.id)
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ) : null
                )
              ) : (
                <SelectImageQuote
                  image={notAvailableIllustration}
                  quote="Happiness is the real sense of fulfillment that comes from hard work."
                  author="Joseph Barbara"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default WorkspaceMembers;
