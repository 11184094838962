import React from "react";
import expandIcon from "../images/expand_icon.png";
import collpaseIcon from "../images/collapse_icon.png";

const FAQComponent = () => {
  return (
    <>
      <div className="flex items-start p-6 py-12 w-full">
        <h1 className="text-3xl md:text-5xl text-center text-light-gray font-medium">
          FREQUENTLY ASKED QUESTIONS
        </h1>
      </div>

      <div className="block m-4">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-6 p-3">
            <div className="flex flex-col w-full py-2">
              <span className="text-2xl text-gray-700 font-medium">
                - What is the difference b/w Free plan & Pro plan ?
              </span>
              <p className="text-md text-gray1 py-3">
                In Free plan you have limits upto which you can use your
                audotask workspace, i.e you can add upto max of 5 members, you
                get upto 100MB storage for your workspace and upto 50 custom
                task reminders.
              </p>
              <p className="test-sm text-gray1">
                In Pro-plan there is no such limit, you can enjoy all these
                amazing utilities without worrying about the storage limit, etc.
                You pay for the number of users which are going to use this
                workspace.
              </p>

              <span className="text-2xl text-gray-700 font-medium">
                - I am asked to enter Workspace name, while signing up what does
                it mean?
              </span>
              <p className="text-md text-gray1 py-3">
                Every workspace on Audotask has unique name, and when any of
                your team member wants to join your workspace,so while signing
                up they will add your workspace name and would be able to
                collaborate with the team.
              </p>
              <p className="text-md text-gray1 py-3">
                <span className="font-semibold text-red-500">Note: </span>
                Workspace name cannot be changed later.
              </p>
              <span className="text-2xl text-gray-700 font-medium">
                - Can my AudoTask account be associated to multiple workspaces ?
              </span>
              <p className="text-md text-gray1 py-3">
                No, currently we do not allow using same AudoTask account to
                multiple workspaces.
              </p>
              <span className="text-2xl text-gray-700 font-medium">
                - What all payment methods does AudoTask support ?
              </span>
              <p className="text-md text-gray1 py-3">
                Currently, we accept all major Debit cards and Credit cards for
                users based out of India. For International users, they can make
                payment via Paypal on Audotask.
              </p>
              <p className="text-md text-gray1 py-3">
                We are working hard to bring more payment solutions for Audotask
                users.
              </p>
              <span className="text-2xl text-gray-700 font-medium">
                - I’m facing issues while making payment for Pro plan, what
                should I do now ?
              </span>

              <p className="text-md text-gray1 py-3">
                If you are facing any issue while making payment, please visit
                Get Help page on the website or reach out to us on our social
                media handles and we will surely help you out with it in a very
                short span of time.
              </p>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6 p-3">
            <div className="flex flex-col w-full py-2">
              <span className="text-2xl text-gray-700 font-medium">
                - Can I get refund for my AudoTask pro-plan?
              </span>
              <p className="text-md text-gray1 py-3">
                No, we don’t offer refunds, we have the Free plan available. We
                recommend that you first try out the Free plan and then upgrade
                to the PRO plan.
              </p>
              <span className="text-2xl text-gray-700 font-medium">
                - I’m currently on Quarterly Pro-plan, can I switch to Monthly/
                Yearly plan now ?
              </span>
              <p className="text-md text-gray1 py-3">
                Once your quarterly plan ends, you get to choose which plan you
                want to go ahead with, there you can select your desired plan.
                Nonetheless, you can visit Get Help page on the website and we
                will surely help you with it.
              </p>
              <span className="text-2xl text-gray-700 font-medium">
                - What if I want to add more members, after subscribing to
                AudoTask pro-plan, can I do that ?
              </span>
              <p className="text-md text-gray1 py-3">
                If you are already on PRO plan and want to add more users to
                your workspace, you can visit the payments page on the web app,
                there you will find a link for adding more users to PRO plan and
                you can follow the steps mentioned there, also User with
                <span className="font-semibold text-gray-500">
                  &nbsp; Creator Access
                </span>{" "}
                to the workspace can only make payment.
              </p>
              <span className="text-2xl text-gray-700 font-medium">
                - How can I use Audotask ?
              </span>
              <p className="text-md text-gray1 py-3">
                You can use Audotask by opening{" "}
                <span className="font-semibold text-gray-500">
                  audotask.app
                </span>{" "}
                in your browser and opening the Dashboard page after Logging in
                to your Audotask account.
              </p>
              <span className="text-2xl text-gray-700 font-medium">
                - What is Creator Access in Audotask ?
              </span>
              <p className="text-md text-gray1 py-3">
                Creator Access in the workspace is given to the user who signed
                up for the first time while for a particular workspace. For
                example:
              </p>
              <p className="text-md text-gray1">
                While Signing up if I use the Workspace name which didn't exist
                earlier in Audotask, then you will get the Creator Access for
                that particular workspace name. If you are joining some existing
                workspace then you get the access which has been given by the
                user with Creator Access to your Audotask account.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQComponent;
