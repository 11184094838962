import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import { useDispatch } from "react-redux";
import { addMemberToWorkspace } from "../actions/memberActions";
import checkEmail from "../utils/isEmail";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";

import signup from "../images/illustration_members.png";
import loader from "../images/loader.gif";
import PageLoadingComponent from "../components/PageLoadingComponent";
import { addNewMember, membersNotYetJoined } from "../constants/analytics";
import { Event } from "../utils/eventTracking";

const AddMember = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();

  let userData: any = JSON.parse(localStorage.getItem("user") || "{}");
  if (!userData.workspaceName) {
    // logout user functionality
  }

  const [state, setState] = useState({
    email: "",
    adminAccess: "",
  });
  const [validEmail, setValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [
    memberAdditionSuccessNotification,
    setmemberAdditionSuccessNotification,
  ] = useState(false);
  const [
    memberAdditionFailedNotification,
    setmemberAdditionFailedNotification,
  ] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [memberAdditionResponse, setmemberAdditionResponse] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    if (checkEmail(state.email) && state.adminAccess.length) {
      setButtonDisabled(false);
    } else setButtonDisabled(true);
  });
  useEffect(() => {
    ReactGA.pageview("/add-member");
    setIsPageLoading(false);
  }, []);
  const validateEmail = (e: any) => {
    setState({ ...state, email: e.target.value });
    const emailCorrect = checkEmail(state.email);
    if (emailCorrect) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const addMemberAndSendEmail = async () => {
    Event(addNewMember.category, addNewMember.action, addNewMember.label);
    setIsLoading(true);
    setButtonDisabled(true);
    setmemberAdditionFailedNotification(false);
    setmemberAdditionSuccessNotification(false);

    const uid = cookies.get("uid");
    if (!uid) {
      // logout user functionality
    }
    const memberData = {
      uid,
      email: state.email,
      adminAccess: state.adminAccess,
    };
    const memberAdded = await addMemberToWorkspace(memberData, dispatch);
    setIsLoading(false);
    setButtonDisabled(false);
    if (memberAdded.status <= 300) {
      setmemberAdditionFailedNotification(false);
      setmemberAdditionSuccessNotification(true);
      setmemberAdditionResponse(memberAdded.message);
    } else {
      setmemberAdditionFailedNotification(true);
      setmemberAdditionSuccessNotification(false);
      setmemberAdditionResponse(memberAdded.message);
    }
  };

  return (
    <Fragment>
      {isPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2">
            <SideBarType1 image={signup} url="/dashboard" />
          </div>
          <div className="col-span-5 md:col-span-4">
            <div className="w-5/6 h-screen m-auto top-12 p-4">
              <h2 className="text-2xl md:text-3xl text-black font-bold">
                ADD MEMBER TO WORKSPACE
              </h2>
              <div className="flex mt-24">
                <h2 className="text-lg md:text-2xl font-bold text-gray-800">
                  Workspace:{" "}
                </h2>
                <h4 className="text-sm md:text-xl pl-2 pt-1 font-medium text-gray-600">
                  {userData.organisation_name || "Not Available Currently"}
                </h4>
              </div>
              <div className="pt-4">
                <label
                  htmlFor="email"
                  className="text-xl font-medium text-gray-500"
                >
                  Email
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-200  p-2 w-full focus:outline-none ${
                    validEmail
                      ? "focus:border-green-400"
                      : "focus:border-red-400"
                  } `}
                  placeholder="Enter email address..."
                  value={state.email}
                  name="email"
                  onChange={validateEmail}
                  onSelect={validateEmail}
                />
              </div>
              <div className="pt-4">
                <label
                  htmlFor="permission"
                  className="text-xl font-medium  text-gray-500"
                >
                  Permission
                </label>
                <select
                  name="permission"
                  className="border-2 border-gray-200 p-2 w-full focus:outline-none  focus:border-blue-400"
                  defaultValue=""
                  onChange={(e) =>
                    setState({ ...state, adminAccess: e.target.value })
                  }
                >
                  <option value="" disabled>
                    Select Member access
                  </option>
                  <option value="true">
                    Member can update data in workspace
                  </option>
                  <option value="false">
                    Member can only view data in workspace
                  </option>
                </select>
              </div>
              <button
                className={`button-type-2 mt-8 w-full ${
                  buttonDisabled || isLoading
                    ? "cursor-default opacity-50"
                    : "cursor-pointer"
                }`}
                disabled={buttonDisabled || isLoading}
                onClick={addMemberAndSendEmail}
              >
                ADD MEMBER
              </button>
              <div className="flex w-full  md:w-full pt-6">
                {!isLoading ? (
                  <Link
                    to="/pending-members"
                    className="underline font-semibold"
                    onClick={() =>
                      Event(
                        membersNotYetJoined.category,
                        membersNotYetJoined.action,
                        membersNotYetJoined.label
                      )
                    }
                  >
                    Members Not yet Joined
                  </Link>
                ) : (
                  <img
                    src={loader}
                    alt="wecest loader"
                    className="w-12 m-auto"
                  />
                )}
              </div>
              <div className="flex w-full  md:w-full pt-6">
                {memberAdditionSuccessNotification ? (
                  <SuccessResponse response={memberAdditionResponse} />
                ) : memberAdditionFailedNotification ? (
                  <FailureResponse response={memberAdditionResponse} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AddMember;
