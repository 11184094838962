export const USER_SIGNUP = "USER_SIGNUP";
export const USER_LOGIN = "USER_LOGIN";
export const VERIFY_USER_LOGGEDIN = "VERIFY_USER_LOGGEDIN";
export const UPDATE_USER_LOGGEDIN = "UPDATE_USER_LOGGEDIN";
export const GET_USER_LOGGEDIN = "GET_USER_LOGGEDIN";
export const ADD_MEMBER = "ADD_MEMBER";
export const USER_LOGOUT = "USER_LOGOUT";
export const PAGE_LOADING = "PAGE_LOADING";
export const CURRENT_MEMBER_CHANGE_ACCESS = "CURRENT_MEMBER_CHANGE_ACCESS";
export const CURRENT_PAGE_LOADING = "CURRENT_PAGE_LOADING";
export const USER_AUTHORIZATION = "USER_AUTHORIZATION";
