import React from "react";
import "../style/pageLoad.css";

function PageLoadingComponent() {
  return (
    <div className="w-full h-full" style={{ background: "#f5f5f5" }}>
      <div id="loading-page">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}

export default PageLoadingComponent;
