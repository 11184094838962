import { SIDEBAR_COLLAPSE } from "../constants/workspace";

const initialState = {
  sidebarCollapsed: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SIDEBAR_COLLAPSE:
      return {
        ...state,
        sidebarCollapsed: action.payload,
      };
    default:
      return state;
  }
}
