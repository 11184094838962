import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers/index";

const initialState: any = {};
const middleware = [thunk];
const store = createStore(
  rootReducer,
  initialState
  //composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
