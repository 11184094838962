import React, { Fragment, ReactElement, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ReactGA from "react-ga";

import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";

import loader from "../images/loader.gif";
import ForgotPasswordImage from "../images/illustration_forgotpassword.svg";

const VerifyEmail: React.FC = (): ReactElement => {
  const location = window.location.search;
  const emailVerifyToken = location.split("=").pop();
  const history = useHistory();

  if (!emailVerifyToken) history.push("/");

  const [isLoading, setIsLoading] = useState(true);
  const [
    accountVerificationSuccessResponse,
    setAccountVerificationSuccessResponse,
  ] = useState("");
  const [
    accountVerificationFailureResponse,
    setAccountVerificationFailureResponse,
  ] = useState("");
  const [
    accountVerificationSuccessNotification,
    setAccountVerificationSuccessNotification,
  ] = useState(false);
  const [
    accountVerificationFailureNotification,
    setAccountVerificationFailureNotification,
  ] = useState(false);

  const verifyAccount = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/user/verify-email`,
        {
          params: { id: emailVerifyToken },
        }
      );
      const message = data.message;
      setIsLoading(false);
      setAccountVerificationSuccessResponse(message);
      setAccountVerificationSuccessNotification(true);
      setTimeout(() => {
        history.push("/login");
      }, 2000);
    } catch (error) {
      const response = error.response.data;
      const message =
        response.message || "Something went wrong! Please try Later";
      setIsLoading(false);
      setAccountVerificationFailureResponse(message);
      setAccountVerificationFailureNotification(true);
    }
  };

  useEffect(() => {
    ReactGA.pageview("/verify-email");
    verifyAccount();
  }, []);
  return (
    <Fragment>
      <div className="grid grid-cols-6">
        <div className="col-span-1 md:col-span-2">
          <SideBarType1 image={ForgotPasswordImage} url="/" />
        </div>
        <div className="col-span-5 md:col-span-4">
          <div className="w-full h-screen flex flex-col justify-center items-center top-12 p-4">
            <img
              src={loader}
              alt="wecest loader"
              className={`w-28 ${!isLoading ? "hidden" : ""} `}
            />
            {accountVerificationSuccessNotification ? (
              <SuccessResponse response={accountVerificationSuccessResponse} />
            ) : accountVerificationFailureNotification ? (
              <FailureResponse response={accountVerificationFailureResponse} />
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VerifyEmail;
