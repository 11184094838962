// No Sheet Selected Component
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SelectImageQuote from "./selectImageQuote";
import $ from "jquery";
import { logoutUser } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";

import noSheetSelectedImage from "../images/illustration_notavailable.svg";
import loader from "../images/loader.gif";
import ModalComponent from "./ModalComponent";
import TutorialCompoenent from "./TutorialComponent";

const NoSheet: React.FC = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const history = useHistory();
  const dataSaving = useSelector(
    (workspace: RootState) => workspace.folder.dataLoader
  );
  const errorMessage = useSelector(
    (workspace: RootState) => workspace.folder.errorMessage
  );
  $(document).mouseup(() => {
    setMenuOpened(false);
  });
  const userLogout = async () => {
    setIsLoading(true);
    setErrorResponse("");
    const userLoggedout = await logoutUser(dispatch);
    setIsLoading(false);
    if (userLoggedout.status === 200) {
      history.push("/");
    } else {
      setErrorResponse(userLoggedout.message);
    }
  };
  const adminAccess = useSelector(
    (workspace: RootState) => workspace.folder.adminAccess
  );
  const creatorAccess = useSelector(
    (workspace: RootState) => workspace.folder.creatorAccess
  );
  function toggleModelState() {
    setShowModal(!showModal);
  }
  return (
    <>
      <div className="grid grid-cols-12 p-3  z-auto ">
        <div className="col-span-11">
          <div className="grid grid-cols-12">
            <div className="col-span-9">
              {errorResponse || errorMessage ? (
                <div className="flex justify-end">
                  <b className="flex justify-start items-center text-sm text-bold text-red-600 mt-4 flex-wrap p-2">
                    {errorResponse || errorMessage}
                  </b>
                </div>
              ) : null}
            </div>
            <div className="col-span-3">
              {isLoading || dataSaving ? (
                <span className="flex text-sm justify-start">
                  <img
                    src={loader}
                    className="w-8 h-8 mr-2"
                    alt="wecest loader"
                  />
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div
            className="m-auto text-center text-white font-extrabold text-sm md:text-lg w-7 md:w-10 bg-yellow-300 p-1 md:p-2 -mt-1 rounded-3xl cursor-pointer"
            onClick={() => {
              setMenuOpened(!menuOpened);
            }}
          >
            H
          </div>
        </div>
      </div>
      <div className={`flex justify-end  ${!menuOpened ? "hidden" : ""}`}>
        <div className="block rounded-lg p-4 text-gray-600 shadow-xl bg-white border-gray-300 font-medium z-50 w-48 text-center absolute  mr-5">
          <div className="flex w-full justify-center border-b-2 border-gray-200 py-2">
            <Link to="/account-info" className="">
              Account
            </Link>
          </div>
          <div className="flex w-full justify-center border-b-2 border-gray-200 py-2">
            <Link to="/members" className="">
              Group Members
            </Link>
          </div>
          {creatorAccess ? (
            <div className="flex w-full justify-center border-b-2 border-gray-200 py-2">
              <Link to="/add-member">Add Member</Link>
            </div>
          ) : null}
          <div
            className="flex w-full justify-center pt-2 cursor-pointer"
            onClick={userLogout}
          >
            <span>Logout</span>
          </div>
        </div>
      </div>
      <SelectImageQuote
        image={noSheetSelectedImage}
        quote="Do the hard jobs first. The easy jobs will take care of
          themselves"
        author="Dale Carnegie"
        watchAudotaskDemo={true}
        toggleModelState={toggleModelState}
      />
      {showModal && (
        <ModalComponent
          component={<TutorialCompoenent toggleModelState={toggleModelState} />}
        />
      )}
    </>
  );
};
export default NoSheet;
