import React, { Fragment, ReactElement, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";

import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import checkPassword from "../utils/isPassword";
import { API_FAILURE } from "../constants/response";

import ForgotPasswordImage from "../images/illustration_forgotpassword.svg";
import loader from "../images/loader.gif";

const UpdatePassword: React.FC = (): ReactElement => {
  const location = window.location.search;
  const passwordUpdateToken = location.split("=").pop();
  const history = useHistory();
  if (!passwordUpdateToken) {
    setTimeout(() => {
      history.push("/");
    }, 100);
  }
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [
    updatePasswordSuccessNotification,
    setUpdatePaswordSuccessNotification,
  ] = useState(false);
  const [updatePasswordFailedNotification, setUpdatePaswordFailedNotification] =
    useState(false);

  const [updatePasswordResponse, setUpdatePaswordResponse] = useState("");
  useEffect(() => {
    ReactGA.pageview("/password-reset");
  }, []);
  useEffect(() => {
    if (checkPassword(newPassword) && newPassword === confirmPassword)
      setButtonDisabled(false);
    else setButtonDisabled(true);
  });

  const validatePassword = (e: any) => {
    if (e.target.name === "new-password") {
      setNewPassword(e.target.value);
    } else {
      setConfirmPassword(e.target.value);
    }
    const passwordCorrect = checkPassword(newPassword);
    passwordCorrect ? setValidPassword(true) : setValidPassword(false);
  };

  const updatePassword = async () => {
    try {
      setUpdatePaswordFailedNotification(false);
      setUpdatePaswordSuccessNotification(false);
      setIsLoading(true);
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/user/update-password`,
        {
          id: passwordUpdateToken,
          password: newPassword,
        }
      );
      setIsLoading(false);
      setUpdatePaswordSuccessNotification(true);
      setUpdatePaswordResponse(data.message);
    } catch (error) {
      const message = "";
      setIsLoading(false);
      setUpdatePaswordFailedNotification(true);
      setUpdatePaswordResponse(message);
    }
  };

  return (
    <Fragment>
      <div className="grid grid-cols-6">
        <div className="col-span-1 md:col-span-2">
          <SideBarType1 image={ForgotPasswordImage} url="/" />
        </div>
        <div className="col-span-5 md:col-span-4">
          <div className="w-full h-screen flex flex-col justify-center items-center top-12 p-4">
            <h2 className="text-xl md:text-3xl text-black font-bold">
              ENTER NEW PASSWORD
            </h2>
            <div className="flex flex-wrap w-5/6  md:w-4/6  pt-12">
              <label className="text-xl font-medium text-gray-500">
                New Password
              </label>

              <input
                type="password"
                className="border-2 border-gray-200  p-2 w-full focus:outline-none focus:ring focus:border-blue-400"
                placeholder="Minimum 8 characters with 1 number, 1
                small and 1 capital Letter"
                value={newPassword}
                onChange={validatePassword}
                onSelect={validatePassword}
                name="new-password"
              />
            </div>
            <div className="flex flex-wrap w-5/6  md:w-4/6  pt-6">
              <label className="text-xl font-medium text-gray-500">
                Re-enter New Password
              </label>

              <input
                type="password"
                className="border-2 border-gray-200  p-2 w-full focus:outline-none focus:ring focus:border-blue-400"
                placeholder="Minimum 8 characters with 1 number, 1
                small and 1 capital Letter"
                value={confirmPassword}
                onChange={validatePassword}
                onSelect={validatePassword}
                name="confirm-password"
              />
            </div>
            <div className="flex w-5/6  md:w-4/6">
              <button
                className={`button-type-2 mt-2 w-full ${
                  buttonDisabled
                    ? "cursor-default opacity-50"
                    : "cursor-pointer"
                }`}
                disabled={buttonDisabled}
                onClick={updatePassword}
              >
                UPDATE PASSWORD
              </button>
            </div>
            <div className="flex w-5/6  md:w-4/6 pt-6">
              {!isLoading ? (
                <Link to="/login" className="underline font-semibold">
                  Login here
                </Link>
              ) : (
                <img src={loader} alt="wecest loader" className="w-12 m-auto" />
              )}
            </div>
            <div className="flex w-5/6  md:w-4/6 pt-6">
              {updatePasswordSuccessNotification ? (
                <SuccessResponse response={updatePasswordResponse} />
              ) : updatePasswordFailedNotification ? (
                <FailureResponse response={updatePasswordResponse} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdatePassword;
