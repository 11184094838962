import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { RootState } from "../reducers";
import {
  addFolder as newFolder,
  getFolders,
  updateFolder as updateExistingFolder,
  newSheet,
  updateSheet,
  deleteSheet,
} from "../actions/folderActions";
import {
  ADD_SHEET,
  ADD_FOLDER,
  SELECT_FOLDER_FOR_MEMBERS,
  SELECTED_SHEET,
} from "../constants/workspace";
import { LOAD_TASKS } from "../constants/task";
import { getTasks } from "../actions/taskActions";
import {
  SHEET_DELETE_CONFIRM,
  POLLING_DURATION_SHEETS,
} from "../constants/response";
import { SHOW_MEMBERS_MODAL } from "../constants/members";

import logo from "../images/logo.svg";
import arrowBack from "../images/arrow_back.svg";
import arrowDown from "../images/arrow_down.svg";
import folderIcon from "../images/folder_icon.svg";
import groupMembersIcon from "../images/group_members.svg";
import deleteIcon from "../images/close_icon.svg";
import fileIcon from "../images/file_icon.svg";
import addFileIcon from "../images/add_file_icon.svg";
import addFolderIcon from "../images/add_folder_icon.svg";
import filesLoader from "../images/loader2.gif";
import { checkValidUUID } from "../utils/checkValidUUID";
import ballonsIllustration from "../images/illustration_ballons.png";
import { Event } from "../utils/eventTracking";
import {
  audoTaskClickSidebar2,
  addNewSheetClick,
  addNewFolderClick,
  foldersMembersModalOpen,
} from "../constants/analytics";

let foldersData: any;
const SideBarType2: React.FC = () => {
  const dispatch = useDispatch();
  const [folderName, setFolderName] = useState("");
  const [sheetName, setSheetName] = useState("");
  const [hideSheets, setHideSheets] = useState(false);
  const [currentSheetIndex, setCurrentSheetIndex] = useState("-1");
  const [currentSheet, setCurrentSheet] = useState("-1");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getFolders(dispatch);
  }, []);

  const selectedSheet = useSelector(
    (workspace: RootState) => workspace.folder.selectedSheet
  );

  let userFolders = useSelector(
    (workspace: RootState) => workspace.folder.foldersWithSheets
  );
  let isLoading = useSelector(
    (workspace: RootState) => workspace.folder.isLoading
  );
  const dataSaving = useSelector(
    (workspace: RootState) => workspace.folder.dataLoader
  );
  const adminAccess = useSelector(
    (workspace: RootState) => workspace.folder.adminAccess
  );

  // Search through Folders and sheets
  /*userFolders = userFolders.filter((folder: any) =>
    folder.Sheets.some((sheet: any) => {
      if (folder.folder_name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return folder;
      }
      return sheet.sheet_name.toLowerCase().includes(searchTerm.toLowerCase());
    })
  );*/
  foldersData = [...userFolders];
  if (searchTerm) {
    const data = userFolders.filter((folder: any) =>
      folder.Sheets.some((sheet: any) => {
        if (
          folder.folder_name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return folder;
        }
        return sheet.sheet_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
    );
    foldersData = [...data];
  }

  // Add Files inside a folder
  const addFileToFolder = async (folderId: string) => {
    Event(
      addNewSheetClick.category,
      addNewSheetClick.action,
      addNewSheetClick.label
    );
    let folders = [...userFolders];

    let folder = folders.find((item) => item.folder_id === folderId);

    const sheet = {
      sheet_id: Math.random(),
      sheet_name: "",
      sheet_description: "TASKS LIST",
      folder_id: folderId,
      sheet_category: "task",
      public: true,
      s_id: uuidv4(),
    };
    if (folder.folder_id) {
      folder.Sheets.unshift(sheet);
      folders.map((data: any) =>
        data.folder_id === folderId ? (data.Sheets = folder.Sheets) : null
      );
      dispatch({
        type: ADD_SHEET,
        payload: folders,
      });
    }
  };

  // s_id -> sheet id's which are not yet saved
  const createOrUpdateSheet = async (
    folderId: string,
    sheetId: string,
    s_id: string
  ) => {
    let folders = [...userFolders];
    let foldersWithSheets = [...userFolders];
    const folder = folders.find((item) => item.folder_id === folderId);
    const sheets = folder.Sheets;
    let sheet = sheets.find((item: any) => item.s_id === s_id);
    if (s_id && sheetName) {
      // create sheet
      sheet.sheet_name = sheetName;
      newSheet(dispatch, foldersWithSheets, sheet);
    } else {
      if (sheetName) {
        // update sheet
        const sheetData = {
          sheetId: sheetId,
          sheet_name: sheetName,
        };
        updateSheet(dispatch, sheetData, folders);
      }
    }
    setSheetName("");
  };

  // Add New Folder inside current Organisation
  const addFolder = async () => {
    Event(
      addNewFolderClick.category,
      addNewFolderClick.action,
      addNewFolderClick.label
    );
    let folders = [...userFolders];
    folders.unshift({
      folder_id: "",
      folder_name: "",
      Sheets: [],
      f_id: uuidv4(),
    });
    dispatch({
      type: ADD_FOLDER,
      payload: folders,
    });
  };

  const createOrUpdateFolder = async (f_id: string, folderId: string) => {
    let foldersWithSheets = [...userFolders];
    const user = JSON.parse(String(localStorage.getItem("user")));

    if (f_id && folderName) {
      if (user.email) {
        const folderData = {
          folder_name: folderName,
          members: [user.email],
          f_id,
        };
        newFolder(dispatch, foldersWithSheets, folderData);
      } else {
        // implement logout user functionality
      }
    } else {
      // update folder name functioanlity
      if (user.email && folderName) {
        const folderData = {
          folderId,
          folderName,
        };
        updateExistingFolder(dispatch, foldersWithSheets, folderData);
      }
    }
    setFolderName("");
  };

  const deleteSheetFromFolder = async (
    sheetId: string,
    folderId: string,
    folders: any
  ) => {
    const result = window.confirm(SHEET_DELETE_CONFIRM);
    if (result) {
      deleteSheet(dispatch, sheetId, folderId, folders);
      const sheetFolderId = {
        folder_id: "",
        sheet_id: "",
      };
      dispatch({
        type: SELECTED_SHEET,
        payload: sheetFolderId,
      });
    }
  };

  return (
    <Fragment>
      <div
        className={`block bg-wecest-pink shadow-xl
       w-full overflow-x-hidden sticky h-screen  overflow-y-auto  -top-0  -bottom-0  text-white sidebar-transition`}
        id="sidebar"
      >
        <Link
          to="/"
          onClick={() =>
            Event(
              audoTaskClickSidebar2.category,
              audoTaskClickSidebar2.action,
              audoTaskClickSidebar2.label
            )
          }
        >
          <div className="flex w-full justify-center items-center mt-5">
            <h1 className="text-3xl font-bold hidden md:block">AUD</h1>
            <span className="bg-white p-1 rounded-3xl">
              <img
                src={logo}
                className="spin-animation w-6"
                alt="wecest logo"
              />
            </span>
            <h1 className="text-3xl font-bold hidden md:block">TASK</h1>
          </div>
        </Link>
        <div className="flex justify-center pt-5 text-gray-700">
          <input
            type="text"
            placeholder="Search Sheets..."
            className="p-2 w-11/12 focus:outline-none placeholder-gray-400"
            name="search-folders"
            onChange={(e: any) => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm}
          />
        </div>
        {isLoading ? (
          <div className="flex h-full justify-center items-center">
            <img src={filesLoader} alt="wecest loader files" className="w-14" />
          </div>
        ) : (
          <>
            <div className="flex justify-start mt-2">
              {adminAccess && !searchTerm ? (
                <img
                  src={addFolderIcon}
                  alt="folder icon"
                  className={`w-7 md:w-9 pl-1 pt-2  cursor-pointer`}
                  onClick={addFolder}
                />
              ) : null}
            </div>
            {foldersData.length ? (
              foldersData.map((data: any) => {
                return (
                  <div key={data.folder_id}>
                    <div
                      className="grid grid-cols-12 pl-2 mt-4"
                      data-folder={data.folder_id}
                    >
                      <div className="col-span-2 ">
                        <div className="flex items-center justify-start">
                          <img
                            src={folderIcon}
                            alt="Wecest Folder Icon"
                            className="w-4 md:w-7"
                          />
                        </div>
                      </div>
                      <div className="col-span-8 md:col-span-7 cursor-pointer">
                        <div className="flex items-center justify-start">
                          <span className="font-bold text-xs md:text-lg mt-1 pl-1">
                            <input
                              type="text"
                              placeholder="Folder Name"
                              className="bg-transparent outline-none text-white text-md font-bold w-full"
                              name={data.folder_id}
                              defaultValue={data.folder_name}
                              onChange={(e) => {
                                setFolderName(e.target.value);
                              }}
                              onBlur={() =>
                                createOrUpdateFolder(data.f_id, data.folder_id)
                              }
                              readOnly={!adminAccess}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="col-span-1 md:col-span-2 cursor-pointer">
                        <div className="flex items-center justify-end">
                          <img
                            src={groupMembersIcon}
                            alt="Wecest group members"
                            className="w-7 hidden md:block"
                            onClick={() => {
                              Event(
                                foldersMembersModalOpen.category,
                                foldersMembersModalOpen.action,
                                foldersMembersModalOpen.label
                              );
                              dispatch({
                                type: SELECT_FOLDER_FOR_MEMBERS,
                                payload: data.folder_id,
                              });
                              dispatch({
                                type: SHOW_MEMBERS_MODAL,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-white">
                      {data.Sheets.map((item: any, index: number) => (
                        <div
                          className={`grid grid-cols-12 pt-2 ${
                            item.sheet_id === currentSheet
                              ? "text-gray-100 font-semibold py-1 opacity-95 bg-wecest-blue"
                              : "text-gray-300"
                          } 
                    `}
                          key={item.sheet_id}
                          data-sheet={item.sheet_id}
                          onMouseEnter={() =>
                            setCurrentSheetIndex(item.sheet_id)
                          }
                          onMouseLeave={() => setCurrentSheetIndex("-1")}
                        >
                          <div className="col-span-3">
                            <div className="flex items-center justify-end">
                              <img
                                src={fileIcon}
                                alt="File Icon"
                                className="invisible md:visible w-11 pl-5"
                              />
                            </div>
                          </div>
                          <div className="col-span-7 cursor-pointer">
                            <div className="flex items-center">
                              <span className="font-medium pl-2 text-sm md:text-lg cursor-pointer">
                                <input
                                  type="text"
                                  placeholder="Sheet Name"
                                  className={`bg-transparent outline-none cursor-pointer  text-md font-medium w-full`}
                                  name={item.sheet_id}
                                  defaultValue={item.sheet_name}
                                  readOnly={!adminAccess}
                                  onChange={(e) => {
                                    setSheetName(e.target.value);
                                  }}
                                  onClick={() => {
                                    if (checkValidUUID(item.sheet_id)) {
                                      const sheetFolderId = {
                                        folder_id: data.folder_id,
                                        sheet_id: item.sheet_id,
                                      };
                                      if (selectedSheet !== item.sheet_id) {
                                        dispatch({
                                          type: SELECTED_SHEET,
                                          payload: sheetFolderId,
                                        });
                                        dispatch({
                                          type: LOAD_TASKS,
                                        });
                                        setCurrentSheet(item.sheet_id);
                                        getTasks(item.sheet_id, dispatch);
                                      }
                                    }
                                  }}
                                  onBlur={() =>
                                    createOrUpdateSheet(
                                      data.folder_id,
                                      item.sheet_id,
                                      item.s_id
                                    )
                                  }
                                />
                              </span>
                            </div>
                          </div>
                          <div className="col-span-2 ">
                            <div className="flex items-center justify-center">
                              {adminAccess && item.sheet_name ? (
                                <img
                                  src={deleteIcon}
                                  alt="Delete File Icon"
                                  className={`w-5 pt-1 md:pt-0 cursor-pointer  ${
                                    currentSheetIndex === item.sheet_id
                                      ? "visible"
                                      : "invisible"
                                  }
                        `}
                                  onClick={() =>
                                    deleteSheetFromFolder(
                                      item.sheet_id,
                                      data.folder_id,
                                      userFolders
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div
                        className="flex justify-center mt-2"
                        onClick={() => addFileToFolder(data.folder_id)}
                      >
                        {adminAccess ? (
                          <img
                            src={addFileIcon}
                            alt="file icon"
                            className="w-6 cursor-pointer"
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex justify-center items-center h-full px-2">
                <span className="font-bold text-white text-xs md:text-lg">
                  <img src={ballonsIllustration} />
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default SideBarType2;
