import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";

import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import checkEmail from "../utils/isEmail";
import checkPassword from "../utils/isPassword";
import { logInUser } from "../actions/userActions";

import loader from "../images/loader.gif";
import loginImage from "../images/illustration_login.png";
import PageLoadingComponent from "../components/PageLoadingComponent";
import {
  loginButton,
  SignupPageLinkOnLoginPage,
  forgotPasswordLinkOnLoginPage,
} from "../constants/analytics";
import { Event } from "../utils/eventTracking";

const Login: React.FC = (): ReactElement => {
  const dispatch = useDispatch();

  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const history = useHistory();
  useEffect(() => {
    ReactGA.pageview("/login");
    setIsPageLoading(false);
  }, []);
  const [accountLoginFailureResponse, setAccountLoginFailureResponse] =
    useState("");
  const [accountLoginFailureNotification, setAccountLoginFailureNotification] =
    useState(false);
  const [accountLoginSuccessResponse, setAccountLoginSuccessResponse] =
    useState("");
  const [accountLoginSuccessNotification, setAccountLoginSuccessNotification] =
    useState(false);

  const validateEmail = (e: any) => {
    setState({ ...state, email: e.target.value });
    const emailCorrect = checkEmail(state.email);
    if (emailCorrect) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const validatePassword = (e: any) => {
    setState({ ...state, password: e.target.value });
    const passwordCorrect = checkPassword(state.password);
    if (passwordCorrect) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };

  const loginUserRequest = async () => {
    try {
      Event(loginButton.category, loginButton.action, loginButton.label);
      setAccountLoginFailureNotification(false);
      setIsLoading(true);
      const requestSuccess = await logInUser(dispatch, state);
      if (requestSuccess.status === 200) {
        const message = requestSuccess.message;
        setAccountLoginSuccessResponse(message);
        setAccountLoginSuccessNotification(true);
        setTimeout(() => {
          history.push("/dashboard");
        }, 1000);
      } else {
        const message = requestSuccess.message;
        setAccountLoginFailureResponse(message);
        setAccountLoginFailureNotification(true);
      }
      setIsLoading(false);
    } catch (err) {}
  };

  return (
    <Fragment>
      {isPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2">
            <SideBarType1 image={loginImage} url="/" />
          </div>
          <div className="col-span-5 md:col-span-4">
            <div className="w-full h-screen flex flex-col justify-center items-center top-12 p-4">
              <h2 className="text-2xl md:text-3xl text-black font-bold">
                LOGIN TO YOUR ACCOUNT
              </h2>
              <div className="flex flex-wrap w-5/6  md:w-4/6  pt-4">
                <label htmlFor="" className="text-xl font-medium text-gray-500">
                  Email
                </label>

                <input
                  type="text"
                  className={`border-2 border-gray-200  p-2 w-full focus:outline-none ${
                    validEmail
                      ? "focus:border-green-400"
                      : "focus:border-red-400"
                  } `}
                  placeholder="Enter email address..."
                  value={state.email}
                  onChange={validateEmail}
                  onSelect={validateEmail}
                  name="email"
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-wrap w-5/6  md:w-4/6 pt-4">
                <label htmlFor="" className="text-xl font-medium text-gray-500">
                  Password
                </label>

                <input
                  type="password"
                  className={`border-2 border-gray-200  p-2 w-full focus:outline-none ${
                    validPassword
                      ? "focus:border-green-400"
                      : "focus:border-red-400"
                  } `}
                  placeholder="Enter Password"
                  name="password"
                  value={state.password}
                  onChange={validatePassword}
                  onSelect={validatePassword}
                  autoComplete="off"
                />
              </div>
              <div className="flex w-5/6  md:w-4/6">
                <button
                  className={`button-type-2 mt-2 w-full cursor-pointer focus:outline-none ${
                    validEmail && validPassword && !isLoading
                      ? ""
                      : "cursor-default opacity-50 "
                  }`}
                  onClick={loginUserRequest}
                  disabled={!(validEmail && validPassword) && !isLoading}
                >
                  LOGIN
                </button>
              </div>
              <div className="flex w-5/6  md:w-4/6 pt-6">
                <Link
                  to="/forgot-password"
                  className="underline font-semibold"
                  onClick={() =>
                    Event(
                      forgotPasswordLinkOnLoginPage.category,
                      forgotPasswordLinkOnLoginPage.action,
                      forgotPasswordLinkOnLoginPage.label
                    )
                  }
                >
                  Forgot Password
                </Link>
              </div>
              <div className="flex w-5/6  md:w-4/6 pt-3">
                <Link
                  to="/signup"
                  className="underline font-semibold"
                  onClick={() =>
                    Event(
                      SignupPageLinkOnLoginPage.category,
                      SignupPageLinkOnLoginPage.action,
                      SignupPageLinkOnLoginPage.label
                    )
                  }
                >
                  Create New Account - Click Here
                </Link>
              </div>
              <div className="flex w-5/6 justify-center  md:w-4/6 pt-3">
                <img
                  src={loader}
                  alt="wecest loader"
                  className={`w-16 h-16 ${!isLoading ? "hidden" : ""}`}
                />
                {accountLoginSuccessNotification ? (
                  <SuccessResponse response={accountLoginSuccessResponse} />
                ) : accountLoginFailureNotification ? (
                  <FailureResponse response={accountLoginFailureResponse} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Login;
