import axios from "axios";
import {
  ADD_MEMBER,
  GET_MEMBERS,
  LOAD_MEMBERS,
  SAVE_MEMBERS,
  ERROR_OCCURED,
  UPDATE_MEMBER_PERMISSION,
  SHOW_MEMBERS_MODAL,
  SUCCESS_MESSAGE,
  LOADING_DELETE_MEMBER,
  ERROR_MESSAGE_DELETE_MEMBER,
  DELETE_MEMBER,
} from "../constants/members";
import {
  SELECT_FOLDER_FOR_MEMBERS,
  UPDATE_FOLDER,
} from "../constants/workspace";
import { API_FAILURE, COOKIE_ALTERED } from "../constants/response";

import _ from "lodash";

import Cookies from "universal-cookie";

const cookies = new Cookies();
const secureApi = axios.create({
  withCredentials: true,
});

export const addMemberToWorkspace: any = async (
  memberData: any,
  dispatch: any
) => {
  try {
    const result = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/org_member/register`,
      memberData
    );
    const member = {
      email: memberData.email,
      adminAccess: memberData.adminAccess,
    };
    dispatch({
      type: ADD_MEMBER,
      payload: member,
    });
    return {
      status: result.status,
      message: result.data.message,
    };
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    return {
      status: 500,
      message: message,
    };
  }
};

export const getMembers: any = async (dispatch: any) => {
  try {
    dispatch({
      type: LOAD_MEMBERS,
    });
    const uid = cookies.get("uid");
    const {
      data: { members, creator, loggedInUserEmail },
    } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/organisation/list`,
      { uid }
    );
    const usersInfo = {
      users: members.Users,
      org_name: members.organisation_name,
      creator,
      loggedInUserEmail,
    };
    dispatch({
      type: GET_MEMBERS,
      payload: usersInfo,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const updateFolderMembers = async (
  dispatch: any,
  members: any,
  folders: any
) => {
  try {
    const uid = cookies.get("uid");
    members["uid"] = uid;
    const {
      data: { folderUpdated },
    } = await secureApi.patch(
      `${process.env.REACT_APP_API_URL}/api/folder/edit`,
      members
    );
    const folderId = folderUpdated.folder_id;
    let updateFolder = folders.find((item: any) => folderId === item.folder_id);
    updateFolder.members = folderUpdated.members;
    dispatch({
      type: SAVE_MEMBERS,
    });
    dispatch({
      type: UPDATE_FOLDER,
      payload: folders,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const deleteFolderWithSheets: any = async (
  dispatch: any,
  folderId: any,
  folders: any
) => {
  try {
    const uid = cookies.get("uid");
    const { data } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/folder/delete`,
      {
        uid,
        folderId,
      }
    );
    dispatch({
      type: SHOW_MEMBERS_MODAL,
    });

    folders = folders.filter((item: any) => item.folder_id !== folderId);
    dispatch({
      type: SAVE_MEMBERS,
    });
    dispatch({
      type: SELECT_FOLDER_FOR_MEMBERS,
      payload: "",
    });

    dispatch({
      type: UPDATE_FOLDER,
      payload: folders,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

export const updateMemberPermission = async (
  memberData: any,
  dispatch: any
) => {
  try {
    const uid = cookies.get("uid");
    memberData["uid"] = uid;
    const {
      data: { message },
    } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/org_member/edit`,
      memberData
    );
    dispatch({
      type: SUCCESS_MESSAGE,
      payload: message,
    });
  } catch (error) {
    const message = error?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_OCCURED,
      payload: message,
    });
  }
};

// deletes member from workspace -> only creator can do it
export const deleteMember = async (
  userId: string,
  dispatch: any,
  members: any
) => {
  try {
    dispatch({
      type: ERROR_MESSAGE_DELETE_MEMBER,
      payload: "",
    });
    const uid = cookies.get("uid");
    const { data, status } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/org_member/delete/${userId}`,
      {
        uid,
      }
    );
    if (status === 200) {
      const newMembersList: any = members.filter(
        (member: any) => member.id !== userId
      );
      dispatch({
        type: DELETE_MEMBER,
        payload: newMembersList,
      });
    } else {
      const message = data?.message || API_FAILURE;
      dispatch({
        type: ERROR_MESSAGE_DELETE_MEMBER,
        payload: message,
      });
    }
  } catch (err) {
    const message = err?.response?.data?.message || API_FAILURE;
    dispatch({
      type: ERROR_MESSAGE_DELETE_MEMBER,
      payload: message,
    });
  }
};
