import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();
const secureApi = axios.create({
  withCredentials: true,
});

export const checkUserAuthorizedStatus = async () => {
  const uid = cookies.get("uid");
  if (uid) {
    const { status } = await secureApi.post(
      `${process.env.REACT_APP_API_URL}/api/user/loginverify/${uid}`,
      {
        uid,
      }
    );
    return status;
  }
  return 208;
};
