import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import Select from "react-select";
import {
  SHOW_VOICE_RECORDING_MODAL,
  START_RECORDING,
  STORE_STREAM_DATA,
} from "../constants/voiceRecord";
import { VOICE_PERMISSION, TASK_PRIORITY } from "../constants/response";
import { CREATE_NEW_TASK_MODAL_OPEN } from "../constants/task";
import { createTask } from "../actions/taskActions";
import RichTextEditor from "./TextEditor";

import closeIcon from "../images/close_icon_dark.svg";
import loader from "../images/loader.gif";
import micIcon from "../images/mic.svg";
import soundWaves from "../images/sound-waves.png";
import speechImage from "../images/illustration_speaking.png";
import { Event } from "../utils/eventTracking";
import {
  newTaskSubmitButtonClick,
  newTaskVoiceRecordButtonClick,
} from "../constants/analytics";

const NewTask: React.FC = () => {
  const dispatch = useDispatch();
  const [audioSource, setAudioSource] = useState("");
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [notifyByEmail, setNotifyByEmail] = useState(false);
  const [priority, setPriority] = useState("");
  const [assignTo, setAssignTo] = useState([]);
  const [notifyDate, setNotifyDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [audioFile, setAudioFile] = useState(new File([], "test.mp3"));
  const [permissionGiven, setPermissionGiven] = useState(true);

  useEffect(() => {
    taskNameValid ? setButtonDisabled(false) : setButtonDisabled(true);
  });
  const taskSaving = useSelector((state: RootState) => state.task.saveTask);
  const errorInNewTask = useSelector(
    (state: RootState) => state.task.errorMessage
  );
  const tasksDataObject = useSelector(
    (state: RootState) => state.task.tasksData
  );

  let showVoiceRecording = useSelector(
    (state: RootState) => state.voice.showVoiceRecordingModal
  );
  const members = useSelector(
    (state: RootState) => state.folder.foldersWithSheets
  );
  const selectedFolder = useSelector(
    (state: RootState) => state.folder.selectedFolder
  );
  const selectedSheet = useSelector(
    (state: RootState) => state.folder.selectedSheet
  );
  const folderusers = members.filter((data: any) => {
    if (data.folder_id === selectedFolder) {
      return data.members;
    }
  });
  let folderMembers;
  if (folderusers.length === 1) {
    const users = folderusers[0].members;
    folderMembers = users;
  }
  let taskNameValid =
    taskName.length >= 3 && taskName.length <= 45 ? true : false;

  const recording = useSelector((state: RootState) => state.voice.recorder);
  const streamData = useSelector((state: RootState) => state.voice.streamData);
  const [recordingTime, setRecordingTime] = useState("00:00");
  const [intervalId, setIntervalId] = useState();

  let seconds: any = 0;
  let minutes: any = 0;
  let interval: any;
  let finalRecordingTime;
  const recordAudio = async () => {
    try {
      Event(
        newTaskVoiceRecordButtonClick.category,
        newTaskVoiceRecordButtonClick.action,
        newTaskVoiceRecordButtonClick.label
      );
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        alert("This browser does not support Voice Recording");
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      dispatch({
        type: SHOW_VOICE_RECORDING_MODAL,
      });
      const recorder = new MediaRecorder(stream);
      dispatch({
        type: STORE_STREAM_DATA,
        payload: stream,
      });
      dispatch({
        type: START_RECORDING,
        payload: { recorder },
      });
      interval = setInterval(startTimer, 1000);
      setIntervalId(interval);
      recorder.start();
    } catch (error) {
      setPermissionGiven(false);
      alert(VOICE_PERMISSION);
    }
  };
  const stopRecordAudio = async () => {
    let chunks: any = [];
    clearInterval(intervalId);
    dispatch({
      type: SHOW_VOICE_RECORDING_MODAL,
    });
    const record = recording;
    if (record) {
      record.stop();
      streamData.getTracks()[0].stop();
      record.ondataavailable = function (e: any) {
        chunks.push(e.data);
        let blob = new Blob(chunks, { type: `audio/mp3` });
        setAudioSource(URL.createObjectURL(blob));
        let file = new File([blob], "user.mp3", { type: "audio/mp3" });
        setAudioFile(file);
      };
      seconds = 0;
      minutes = 0;

      setRecordingTime("00:00");
    }
  };
  const startTimer = () => {
    seconds = parseInt(seconds);
    minutes = parseInt(minutes);
    seconds += 1;
    if (seconds === 60) {
      minutes += 1;
      seconds = 0;
      seconds = "0" + seconds;
      minutes = "0" + minutes;
      finalRecordingTime = minutes + ":" + seconds;
      setRecordingTime(finalRecordingTime);
      let stopBtn: HTMLElement = document.querySelector(
        "#stopBtn"
      ) as HTMLElement;
      if (stopBtn) {
        stopBtn.click();
      }
      clearInterval(interval);
    } else {
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      finalRecordingTime = minutes + ":" + seconds;
      setRecordingTime(finalRecordingTime);
    }
  };
  const onchange = (data: string) => {
    setTaskDescription(data);
  };
  const createNewTask = async () => {
    Event(
      newTaskSubmitButtonClick.category,
      newTaskSubmitButtonClick.action,
      newTaskSubmitButtonClick.label
    );
    var assignedMembers: any;
    if (assignTo.length) {
      assignedMembers = assignTo.map((user: any) => user.label);
    }
    const taskData = {
      task_name: taskName,
      description: taskDescription,
      priority: priority,
      assignedTo: JSON.stringify(assignedMembers || []),
      notify_email: notifyByEmail,
      done: false,
      notify_date: notifyDate,
      due_date: dueDate,
      sheet_id: selectedSheet,
    };
    createTask(taskData, dispatch, audioFile, tasksDataObject);
  };

  const voiceRecord = () => (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-sm md:max-w-lg h-auto ">
          <div className="border-0 p-5 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-600 outline-none focus:outline-none">
            <div className="flex justify-between">
              <h2 className="text-2xl font-bold text-white animate-pulse">
                Recording...
              </h2>
              <img
                src={closeIcon}
                alt="wecest close icon"
                className="cursor-pointer"
                onClick={() => {
                  streamData?.getTracks()[0].stop();

                  dispatch({
                    type: SHOW_VOICE_RECORDING_MODAL,
                  });
                }}
              />
            </div>
            <div className="grid grid-cols-12 p-5">
              <div className="col-span-7">
                <img
                  src={soundWaves}
                  alt="wecest sound waves animation"
                  className="animate-bounce"
                />
              </div>
              <div className="col-span-5">
                <div className="flex justify-center items-center">
                  <img
                    src={speechImage}
                    alt="wecest speech user "
                    className="w-64"
                  />
                </div>
              </div>
            </div>
            <div className="flex">
              <h2 className="text-3xl text-gray-100 font-medium">
                {recordingTime}
              </h2>
            </div>
            <div className="flex flex-col mt-2">
              <button
                className={`button-type-2 w-48 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 `}
                type="button"
                onClick={stopRecordAudio}
                id="stopBtn"
              >
                STOP RECORDING
              </button>
              <small className="text-white">You can record upto 1 minute</small>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
    </>
  );
  let memberOptions: [] = [];
  if (members.length && members[0].members) {
    memberOptions = members[0].members.map((item: string) => {
      return { label: item, value: item };
    });
  }
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-sm md:max-w-3xl h-3/4">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex justify-between items-start  p-3 pl-5 rounded-t ">
              <h1 className="text-2xl font-bold text-gray-600">CREATE TASK</h1>
              <img
                src={closeIcon}
                alt="wecest modal close"
                className="w-8 cursor-pointer p-1"
                onClick={() =>
                  dispatch({
                    type: CREATE_NEW_TASK_MODAL_OPEN,
                  })
                }
              />
            </div>
            <div className="relative px-6 flex-auto">
              <div className="block h-auto overflow-auto">
                <div className="flex flex-wrap w-full">
                  <label htmlFor="" className="text-md font-bold text-gray-600">
                    Name
                  </label>

                  <input
                    type="text"
                    className={`border-2 border-gray-200 placeholder-resize p-1 w-full focus:outline-none ${
                      taskNameValid && taskName.length
                        ? "border-green-500"
                        : taskName.length
                        ? "border-red-500"
                        : null
                    }`}
                    placeholder="Max 45 Characters for Task Name"
                    name="taskName"
                    onChange={(e) => setTaskName(e.target.value)}
                  />
                </div>
                <div className="flex flex-wrap w-full mt-4">
                  <label htmlFor="" className="text-md font-bold text-gray-600">
                    Description
                  </label>
                  <RichTextEditor onchange={onchange} />
                </div>
                <div className="flex flex-col justify-start items-start w-full mt-4">
                  <label htmlFor="" className="text-md font-bold text-gray-600">
                    Assign To
                  </label>
                  <Select
                    className=" w-full focus:outline-none focus:border-blue-400"
                    options={memberOptions}
                    isMulti={true}
                    isSearchable={true}
                    placeholder="Select Members"
                    onChange={(e: any) => setAssignTo(e)}
                    value={assignTo}
                  />
                </div>
                <div className="grid grid-cols-12 pt-4">
                  <div className="col-span-12 md:col-span-6">
                    <div className="flex flex-col md:pr-4">
                      <label
                        htmlFor=""
                        className="text-md font-bold text-gray-600"
                      >
                        Priority
                      </label>

                      <select
                        name="priority"
                        className="border-2 border-gray-200 p-1 w-full focus:outline-none  focus:border-blue-400"
                        defaultValue=""
                        id="priority"
                        onChange={(e: any) => setPriority(e.target.value)}
                      >
                        <option value="" disabled>
                          {" "}
                          Select Priority
                        </option>
                        {TASK_PRIORITY.map((task, index) => (
                          <option value={task} key={index}>
                            {task}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="flex mt-5">
                      <label
                        htmlFor=""
                        className="text-md font-bold text-gray-600 mr-4"
                      >
                        Notify By Email
                      </label>
                      <input
                        type="radio"
                        name="notifyEmail"
                        className="px-2 cursor-pointer mt-2 mx-2"
                        onClick={() => setNotifyByEmail(true)}
                      />{" "}
                      <label htmlFor=""> Yes</label>
                      <input
                        type="radio"
                        name="notifyEmail"
                        className="ml-5 mx-2 cursor-pointer mt-2"
                        defaultChecked
                        onClick={() => setNotifyByEmail(false)}
                      />{" "}
                      <label htmlFor="">No</label>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 pt-4">
                  <div className="col-span-12 md:col-span-6">
                    <div className="flex flex-col md:pr-4">
                      <label
                        htmlFor=""
                        className="text-md font-bold text-gray-600"
                      >
                        Date & TIme of Notification
                      </label>
                      <DatePicker
                        selected={notifyDate}
                        onChange={(date: any, e: any) => {
                          setNotifyDate(date);
                        }}
                        showTimeSelect
                        minDate={new Date()}
                        placeholderText="Select Date & Time of Notification"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="border-2 border-gray-200 p-1 w-full placeholder-resize focus:outline-none focus:border-blue-400"
                        disabled={notifyByEmail ? false : true}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="flex flex-col">
                      <label
                        htmlFor=""
                        className="text-md font-bold text-gray-600"
                      >
                        Due Date
                      </label>
                      <DatePicker
                        selected={dueDate}
                        onChange={(date: any) => setDueDate(date)}
                        placeholderText="Select Due Date & Time"
                        minDate={new Date()}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className="border-2 border-gray-200 p-1 w-full placeholder-resize focus:outline-none focus:border-blue-400"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-center items-center mt-6 p-1">
                  <div
                    onClick={recordAudio}
                    className="flex bg-gray-600 p-3 rounded-full cursor-pointer shadow hover:shadow-lg  transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-125 "
                  >
                    <img
                      src={micIcon}
                      alt="wecest mic icon"
                      className="w-6 animate-pulse"
                    />
                  </div>
                  {audioSource ? (
                    <audio
                      src={audioSource}
                      controls
                      className="ml-2 outline-none	w-full block"
                    ></audio>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-start p-6 rounded-b">
              <button
                className={`button-type-2 w-48 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${
                  buttonDisabled || taskSaving
                    ? "cursor-default opacity-50"
                    : "cursor-pointer"
                }`}
                type="button"
                disabled={buttonDisabled}
                onClick={createNewTask}
              >
                CREATE
              </button>
              {taskSaving ? (
                <img src={loader} alt="wecest laoder" className="w-10" />
              ) : errorInNewTask ? (
                <>
                  <span className="font-bold text-sm text-red-500">
                    {errorInNewTask}
                  </span>
                </>
              ) : null}
            </div>
            {showVoiceRecording ? voiceRecord() : null}
          </div>
        </div>
      </div>
      <div className="opacity-40 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default NewTask;
