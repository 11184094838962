import {
  CREATE_NEW_TASK_MODAL_OPEN,
  CREATE_NEW_TASK,
  SAVE_TASK,
  ERROR_MESSAGE,
  TASK_LOADING_COMPLETE,
  CLOSE_TASK_DETAILS_MODAL,
  TASK_DETAILS_LOADING_COMPLETE,
  TOGGLE_TASK_DONE,
  DELETE_TASK,
  EDIT_TASK_MODAL_OPEN,
  CLOSE_EDIT_TASK_MODAL,
  UPDATE_TASK,
  SEARCH_TEXT,
  LOAD_TASKS,
} from "../constants/task";

const initialState = {
  createTaskModalOpen: false,
  editTaskModalOpen: false,
  newTask: {},
  saveTask: false,
  tasksLoading: false,
  taskDetailsLoading: false,
  taskDetailsModalOpen: false,
  errorMessage: "",
  tasksData: {},
  currentTask: {},
  searchTerm: "",
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case CREATE_NEW_TASK_MODAL_OPEN:
      return {
        ...state,
        createTaskModalOpen: !state.createTaskModalOpen,
        errorMessage: "",
      };
    case SAVE_TASK:
      return {
        ...state,
        saveTask: !state.saveTask,
      };
    case CREATE_NEW_TASK:
      return {
        ...state,
        newTask: action.payload.newTask,
        tasksData: action.payload.tasksData,
        saveTask: !state.saveTask,
        createTaskModalOpen: !state.createTaskModalOpen,
        errorMessage: "",
      };
    case TASK_LOADING_COMPLETE:
      return {
        ...state,
        tasksData: action.payload,
        tasksLoading: false,
      };
    case CLOSE_TASK_DETAILS_MODAL:
      return {
        ...state,
        taskDetailsModalOpen: !state.taskDetailsModalOpen,
        taskDetailsLoading: !state.taskDetailsLoading,
      };
    case TASK_DETAILS_LOADING_COMPLETE:
      return {
        ...state,
        currentTask: action.payload,
        taskDetailsLoading: false,
        errorMessage: "",
      };
    case TOGGLE_TASK_DONE:
      return {
        ...state,
        taskData: action.payload,
      };
    case DELETE_TASK:
      return {
        ...state,
        taskData: action.payload,
      };
    case EDIT_TASK_MODAL_OPEN:
      return {
        ...state,
        taskDetailsModalOpen: !state.taskDetailsModalOpen,
        editTaskModalOpen: !state.editTaskModalOpen,
      };
    case CLOSE_EDIT_TASK_MODAL:
      return {
        ...state,
        editTaskModalOpen: !state.editTaskModalOpen,
      };
    case UPDATE_TASK:
      return {
        ...state,
        tasksData: action.payload,
        saveTask: !state.saveTask,
        errorMessage: "",
        editTaskModalOpen: !state.editTaskModalOpen,
      };
    case SEARCH_TEXT:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case LOAD_TASKS:
      return {
        ...state,
        tasksLoading: !state.tasksLoading,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
        saveTask: !state.saveTask,
      };
    default:
      return state;
  }
}
