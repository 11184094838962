import React, { Fragment } from "react";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";
import { Event } from "../utils/eventTracking";
import { audoTaskClickSidebar1 } from "../constants/analytics";

interface imageProps {
  image: string;
  url: string;
}

const SideBarType1: React.FC<imageProps> = ({ image, url }) => {
  return (
    <Fragment>
      <div className="bg-wecest-pink w-full lg:h-full relative text-white">
        {url ? (
          <Link
            to={url}
            onClick={() =>
              Event(
                audoTaskClickSidebar1.category,
                audoTaskClickSidebar1.action,
                audoTaskClickSidebar1.label
              )
            }
          >
            <div className="flex w-full justify-center items-center pt-5">
              <h1 className="text-3xl font-bold hidden md:block">AUD</h1>
              <span className="bg-white p-1 rounded-3xl">
                <img
                  src={logo}
                  className="spin-animation w-6"
                  alt="wecest logo"
                />
              </span>
              <h1 className="text-3xl font-bold hidden md:block">TASK</h1>
            </div>
          </Link>
        ) : (
          <div className="flex w-full justify-center items-center pt-5">
            <h1 className="text-3xl font-bold hidden md:block">AUD</h1>
            <span className="bg-white p-1 rounded-3xl">
              <img
                src={logo}
                className="spin-animation w-6"
                alt="wecest logo"
              />
            </span>
            <h1 className="text-3xl font-bold hidden md:block">TASK</h1>
          </div>
        )}
        <div className="flex justify-center items-center min-h-screen">
          <img
            src={image}
            alt="wecest signup illustration"
            className="w-auto"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SideBarType1;
