export const API_FAILURE = "Something went wrong! Please try again";
export const COOKIE_ALTERED = "Please Login again";
export const SHEET_DELETE_CONFIRM =
  "Are you sure you want to Delete the sheet, on clicking OK all tasks inside the sheet will be deleted ?";
export const MEMBERS_FOLDER =
  "Selected Members inside the Folder will have access to it";
export const MEMBERS = "Members";
export const SAVE_CHANGES = "Save Changes";
export const DELETE = "DELETE";
export const VIEW_ACCESS = "View";
export const EDIT_ACCESS = "Edit";
export const CREATOR = "Creator";
export const FOLDER_DELETE_CONFIRM =
  "Are you sure you want to Delete Folder ? All files inside it will be removed.";
export const POLLING_DURATION = 30000;
export const POLLING_DURATION_SHEETS = 60000;
export const VOICE_PERMISSION =
  "You haven't given permission to record your voice yet. Please allow microphone permission to use this feature.";
export const NOT_YET_ADDED = "Not yet added";
export const NO_DESCRIPTION_AVAILABLE = "No Description Available...";
export const TASK_PRIORITY = [
  "Low Priority",
  "Medium Priority",
  "High Priority",
];

export const DELTETE_MEMBER =
  "Are you sure, you want to remove this member from workspace permanently ? This action can't be undone and it might affect some tasks which are associated to this user";
