// Success Respose Component

import React from "react";

interface successResponseProps {
  response: string;
}

const SuccessResponse: React.FC<successResponseProps> = ({ response }) => {
  return (
    <div className="flex p-5 w-full justify-center items-center text-white bg-green-400">
      <p className=" text-sm  md:text-md font-bold">{response}</p>
    </div>
  );
};
export default SuccessResponse;
