export const UNLIMITED_FOLDER_SHEETS_TOOLTIP =
  "You can create unlimited Folders & Sheets inside your workspace.";
export const PRIORITY_SUPPORT_TOOLTIP =
  "You will priority support in PRO Plans, our team will get back to you within 24-48 hours.";
export const UNLIMITED_TASKS_TOOLTIP =
  "You can create unlimited tasks in your sheets and assign to the team members.";
export const USER_IN_WORKSPACE_TOOLTIP =
  "Number of users which can be added to a workspace.";
export const STORAGE_TOOLTIP = "Amount of storage which you will get";
export const CUSTOM_REMINDERS_TOOLTIP =
  "Number of custom task reminders which can be added";
export const CHANGE_MEMBER_ACCESS_TOOLTIP =
  "You can edit access of members who are present in a workspace";
