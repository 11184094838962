// Failure Respose Component

import React, { Fragment } from "react";

interface failureResponseProps {
  response: string;
}

const FailureResponse: React.FC<failureResponseProps> = ({ response }) => {
  return (
    <div className="flex p-5 w-full justify-center items-center text-white bg-red-400">
      <p className="font-bold text-sm  md:text-md">{response}</p>
    </div>
  );
};
export default FailureResponse;
