import {
  ADD_FOLDER,
  UPDATE_FOLDER,
  GET_FOLDERS,
  ADD_SHEET,
  SAVE_DATA,
  UPDATE_SHEET,
  DELETE_SHEET,
  ERROR_OCCURED,
  SELECTED_SHEET,
  SELECT_FOLDER_FOR_MEMBERS,
  SHEET_DESCRIPTION_CHANGED,
} from "../constants/workspace";

const initialState = {
  folder: {},
  foldersWithSheets: [],
  selectedFolderForMembers: "",
  selectedSheet: "",
  selectedFolder: "",
  sheetDescription: "",
  isLoading: true,
  adminAccess: false,
  dataLoader: false,
  sheetDescriptionChanged: false,
  planExpiryDate: "",
  errorMessage: "",
  creatorAccess: false,
  creatorEmail: "",
  no_of_members: 1,
  plan_type: "",
  no_of_tasks: 1,
};
export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_FOLDERS:
      return {
        ...state,
        foldersWithSheets: action.payload.folders,
        adminAccess: action.payload.adminAccess,
        planExpiryDate: action.payload.planExpiryDate,
        creatorAccess: action.payload.creator,
        creatorEmail: action.payload.creatorEmail,
        no_of_members: action.payload.no_of_members,
        plan_type: action.payload.plan_type,
        no_of_tasks: action.payload.no_of_tasks || 1,
        isLoading: false,
      };
    case ADD_FOLDER:
      return {
        ...state,
        foldersWithSheets: action.payload,
        isLoading: false,
        dataLoader: false,
      };
    case UPDATE_FOLDER:
      return {
        ...state,
        foldersWithSheets: action.payload,
        dataLoader: false,
      };
    case ADD_SHEET:
      return {
        ...state,
        foldersWithSheets: action.payload,
        isLoading: false,
        dataLoader: false,
      };
    case UPDATE_SHEET:
      return {
        ...state,
        foldersWithSheets: action.payload,
        dataLoader: false,
      };
    case DELETE_SHEET:
      return {
        ...state,
        foldersWithSheets: action.payload,
        dataLoader: false,
      };
    case ADD_FOLDER:
      return {
        ...state,
        foldersWithSheets: action.payload,
        isLoading: false,
      };
    case SELECT_FOLDER_FOR_MEMBERS:
      return {
        ...state,
        selectedFolderForMembers: action.payload,
      };
    case SAVE_DATA:
      return {
        ...state,
        dataLoader: !state.dataLoader,
      };
    case SELECTED_SHEET:
      const folder: any = state.foldersWithSheets.filter(
        (data: any) => data.folder_id === action.payload.folder_id
      );
      let description: string = "";
      folder[0]?.Sheets.forEach((sheet: any) => {
        if (sheet.sheet_id === action.payload.sheet_id) {
          description = sheet.sheet_description;
        }
      });
      return {
        ...state,
        selectedSheet: action.payload.sheet_id,
        selectedFolder: action.payload.folder_id,
        sheetDescription: description,
        sheetDescriptionChanged: false,
        errorMessage: "",
      };
    case SHEET_DESCRIPTION_CHANGED:
      return {
        ...state,
        sheetDescriptionChanged: !state.sheetDescriptionChanged,
      };
    case ERROR_OCCURED:
      return {
        ...state,
        errorMessage: action.payload,
        dataLoader: !state.dataLoader,
      };
    default:
      return state;
  }
}
