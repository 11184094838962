import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { CREATE_NEW_TASK_MODAL_OPEN } from "../constants/task";
import { POLLING_DURATION } from "../constants/response";
import {
  getTasks,
  getTaskDetails,
  updateTaskDone,
  deleteTask,
} from "../actions/taskActions";
import { getDateTime } from "../utils/getDate&Time";

import LabelType from "./Label";
import NewTask from "./NewTask";
import TaskDetails from "./TaskDetails";
import EditTask from "./EditTask";
import SelectImageQuote from "./selectImageQuote";

import plusIcon from "../images/plus_icon.svg";
import verticalDotsIcon from "../images/vertical-dots-icon.svg";
import notAvailableIllustration from "../images/illustration_brainstorm.svg";
import loader from "../images/loader.gif";
import ReactPaginate from "react-paginate";
import usePreviousHook from "../utils/usePrevious";
import { Event } from "../utils/eventTracking";
import {
  addNewTaskButtonClick,
  markTaskDoneClick,
  markTaskUnDoneClick,
  taskDeleteClick,
  taskDetailsOpen,
  audioListenTaskDetails,
} from "../constants/analytics";

const SheetType: React.FC = ({}) => {
  const dispatch = useDispatch();
  const [taskUpdateCard, showTaskUpdateCard] = useState(false);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [listOfTask, setListOfTask] = useState([]);

  const [state, setState] = useState({
    tasks: [],
    offset: 0,
    data: [],
    perPage: 12,
    currentPage: 0,
    pageCount: 1,
    filterType: "",
    slicedData: [],
  });
  const [startDateRange, setStartDateRange] = useState(new Date());
  const [endDateRange, setEndDateRange] = useState(new Date());
  const selectedSheet = useSelector(
    (state: RootState) => state.folder.selectedSheet
  );
  const taskDetailsModelOpen = useSelector(
    (state: RootState) => state.task.taskDetailsModalOpen
  );
  const editTaskModalOpen = useSelector(
    (state: RootState) => state.task.editTaskModalOpen
  );
  const searchTermValue = useSelector(
    (state: RootState) => state.task.searchTerm
  );
  const tasksLoading = useSelector(
    (state: RootState) => state.task.tasksLoading
  );
  let userInfo: any;
  let selectOptions = [
    { value: "", label: "Select Filter" },
    { value: "AssignedToMe", label: "Assigned To Me" },
    { value: "CompletedTasks", label: "Completed Tasks" },
    { value: "DateRange", label: "Date Range" },
  ];

  try {
    userInfo = JSON.parse(String(localStorage.getItem("user")));
    if (!userInfo.email) throw "Email Not Found";
  } catch (e) {
    selectOptions = [
      { value: "", label: "Select Filter" },
      { value: "CompletedTasks", label: "Completed Tasks" },
      { value: "DateRange", label: "Date Range" },
    ];
  }

  useEffect(() => {
    getTasks(selectedSheet, dispatch);
  }, []);
  const [taskListType, setTaskListType] = useState("0");
  const adminAccess = useSelector(
    (workspace: RootState) => workspace.folder.adminAccess
  );

  const errorMessage = useSelector(
    (workspace: RootState) => workspace.folder.errorMessage
  );

  const taskCreationModal = useSelector(
    (state: RootState) => state.task.createTaskModalOpen
  );
  let tasksData = useSelector((state: RootState) => state.task.tasksData);
  const searchTermInTasks = (task: any) => {
    return task.task_name.toLowerCase().includes(searchTermValue.toLowerCase());
  };

  let tasksListData = tasksData.Tasks;

  function isSameDay(d1: Date, d2: Date) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth()
    );
  }
  useEffect(() => {
    const tasksList = tasksData?.Tasks;
    setState({
      ...state,
      tasks: tasksList,
      slicedData: tasksList.slice(state.offset, state.offset + state.perPage),
    });
  }, [tasksData.Tasks]);

  useEffect(() => {
    if (state.filterType === "DateRange") {
      let tasksList = tasksListData.filter((task: any) => {
        if (
          (new Date(task.createdAt) > startDateRange &&
            new Date(task.createdAt) < endDateRange) ||
          isSameDay(new Date(task.createdAt), startDateRange)
        ) {
          return task;
        }
      });
      setState({
        ...state,
        tasks: tasksList,
        pageCount: Math.ceil(tasksList.length / state.perPage),
        slicedData: tasksList.slice(state.offset, state.offset + state.perPage),
      });
    } else if (state.filterType === "") {
      setState({
        ...state,
        tasks: tasksListData,
        slicedData: tasksListData.slice(
          state.offset,
          state.offset + state.perPage
        ),
      });
    } else if (state.filterType === "AssignedToMe") {
      if (userInfo && userInfo.email) {
        let tasksList = [];
        tasksList = tasksListData.filter((task: any) => {
          const members: any = task?.assignedTo
            ?.slice(1, -1)
            .replace(/['"]+/g, "");
          const membersList: any = members?.split(",");
          if (
            membersList &&
            membersList.length &&
            membersList.indexOf(userInfo.email) >= 0
          ) {
            return task;
          }
        });

        setState({
          ...state,
          tasks: tasksList,
          pageCount: Math.ceil(tasksList.length / state.perPage),
          slicedData: tasksList.slice(
            state.offset,
            state.offset + state.perPage
          ),
        });
      }
    } else if (state.filterType === "CompletedTasks") {
      if (tasksListData && tasksListData.length) {
        let tasksList = tasksListData.filter((task: any) => task.done === true);
        setState({
          ...state,
          tasks: tasksList,
          pageCount: Math.ceil(tasksList.length / state.perPage),
          slicedData: tasksList.slice(
            state.offset,
            state.offset + state.perPage
          ),
        });
      }
    }
  }, [state.filterType, startDateRange, endDateRange]);

  useEffect(() => {
    const slicedData = state.tasks.filter((task: any) =>
      searchTermInTasks(task)
    );
    setState({
      ...state,
      slicedData: slicedData.slice(state.offset, state.offset + state.perPage),
    });
  }, [searchTermValue]);

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    const offset = selectedPage * state.perPage;
    const filterType = state.filterType;

    switch (filterType) {
      case "":
        setState({
          ...state,
          currentPage: selectedPage,
          offset: offset,
          slicedData: tasksListData.slice(offset, offset + state.perPage),
        });
        break;
      case "AssignedToMe":
        if (userInfo && userInfo.email) {
          let tasksList = [];
          tasksList = tasksListData.filter((task: any) => {
            const members: any = task?.assignedTo
              ?.slice(1, -1)
              .replace(/['"]+/g, "");
            const membersList: any = members?.split(",");
            if (
              membersList &&
              membersList.length &&
              membersList.indexOf(userInfo.email) >= 0
            ) {
              return task;
            }
          });
          setState({
            ...state,
            tasks: tasksList,
            currentPage: selectedPage,
            offset: offset,
            slicedData: tasksList.slice(offset, offset + state.perPage),
          });
        }
        break;
      case "CompletedTasks":
        if (tasksListData && tasksListData.length) {
          let tasksList = tasksListData.filter(
            (task: any) => task.done === true
          );
          setState({
            ...state,
            tasks: tasksList,
            currentPage: selectedPage,
            offset: offset,
            slicedData: tasksList.slice(offset, offset + state.perPage),
          });
        }
        break;
      case "DateRange":
        let tasksList = tasksListData.filter((task: any) => {
          if (
            (new Date(task.createdAt) > startDateRange &&
              new Date(task.createdAt) < endDateRange) ||
            isSameDay(new Date(task.createdAt), startDateRange)
          ) {
            return task;
          }
        });
        setState({
          ...state,
          tasks: tasksList,
          currentPage: selectedPage,
          offset: offset,
          slicedData: tasksList.slice(offset, offset + state.perPage),
        });
        break;
    }
  };

  const taskMembers = (membersString: string) => {
    const members: any = membersString.slice(1, -1).replace(/['"]+/g, "");
    const membersList: any = members.split(",");

    return (
      <div className="flex flex-wrap justify-start items-center">
        <span className="font-bold text-gray-600 pr-1">Assigned To:</span>
        <div className="block p-2 pl-3 pr-3 bg-green-400 text-xs text-white rounded-2xl text-center font-bold ml-1">
          {members.length ? members.charAt(0).toUpperCase() : ""}
        </div>
        {membersList.length > 1 ? (
          <span className="block p-2 pl-3 pr-3 bg-yellow-400 text-xs text-white rounded-2xl text-center font-bold ml-1">
            +{membersList.length - 1} More
          </span>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-4">
            <div className="flex flex-wrap items-center text-center mt-4">
              <Select
                options={selectOptions}
                placeholder="Select Filter"
                className="w-48 text-gray-500"
                isSearchable={false}
                onChange={(e: any) =>
                  setState({
                    ...state,
                    filterType: e.value,
                    offset: 0,
                    currentPage: 0,
                  })
                }
                isDisabled={
                  state.filterType === "AssignedToMe" &&
                  !userInfo &&
                  !userInfo.email
                    ? true
                    : false
                }
              />
              {state.filterType === "DateRange" ? (
                <div className="flex items-center justify-between w-full mt-4">
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-500 font-semibold text-left">
                      Start Date
                    </span>
                    <DatePicker
                      className="border-2 border-gray-200 p-1 w-full placeholder-resize focus:outline-none focus:border-blue-400 text-gray-500"
                      onChange={(date: any) => setStartDateRange(date)}
                      placeholderText="Start Date"
                      selected={startDateRange}
                      dateFormat="MM/dd/yyyy"
                    />{" "}
                  </div>

                  <p className="px-2 mt-5">-</p>
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-500 font-semibold text-left">
                      End Date
                    </span>

                    <DatePicker
                      className="border-2 border-gray-200 p-1 w-full placeholder-resize focus:outline-none focus:border-blue-400 text-gray-500"
                      onChange={(date: any) => setEndDateRange(date)}
                      placeholderText="End Date"
                      selected={endDateRange}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <div className="flex justify-center  mt-2 w-full">
              {adminAccess ? (
                <div
                  onClick={() => {
                    Event(
                      addNewTaskButtonClick.category,
                      addNewTaskButtonClick.action,
                      addNewTaskButtonClick.label
                    );
                    dispatch({
                      type: CREATE_NEW_TASK_MODAL_OPEN,
                    });
                  }}
                  className="flex justify-center items-center cursor-pointer w-10 h-10 ml-3 mt-2 bg-gray-900 rounded-full shadow-md"
                >
                  <img
                    src={plusIcon}
                    alt="wecest add task icon"
                    className="absolute mt-1 "
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-span-12 md:col-span-4">
            <b className="flex justify-start items-center text-sm text-bold text-red-600 mt-4 flex-wrap p-2">
              {errorMessage}
            </b>
          </div>
        </div>
        {taskDetailsModelOpen ? <TaskDetails /> : null}
        {editTaskModalOpen ? <EditTask /> : null}
        {tasksLoading ? (
          <div className="flex justify-center items-center">
            <img src={loader} alt="wecest task loading" />
          </div>
        ) : !tasksLoading && state.tasks && state.tasks.length ? (
          <>
            <div className="grid grid-cols-12 pt-6">
              {state.slicedData.length ? (
                state.slicedData.map((task: any, index: number) => (
                  <div
                    className="col-span-12 md:col-span-4"
                    data-taskid={task.task_id}
                    key={task.task_id}
                  >
                    <div
                      className="flex flex-col border-2 border-gray-200 rounded-3xl m-3 bg-white cursor-pointer h-auto hover:shadow-lg p-2"
                      onClick={() =>
                        taskUpdateCard ? showTaskUpdateCard(false) : null
                      }
                    >
                      <div className="relative">
                        <div className="grid grid-cols-12 px-3 pt-1">
                          <div className="col-span-11">
                            <div className="block w-full h-auto break-words">
                              <h2
                                className={`text-left text-sm md:text-lg font-bold text-gray-500  ${
                                  task.done
                                    ? "transition ease-linear duration-1000 line-through"
                                    : ""
                                }`}
                                style={{
                                  textDecorationThickness: "2px",
                                  textDecorationColor: "#909090",
                                }}
                              >
                                {task.task_name}
                              </h2>
                            </div>
                          </div>
                          <div className="col-span-1">
                            {adminAccess ? (
                              <img
                                src={verticalDotsIcon}
                                alt="wecest vertical dots icon"
                                className="w-6 mt-1"
                                onClick={() => {
                                  setCurrentTaskIndex(index);
                                  showTaskUpdateCard(!taskUpdateCard);
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                        {currentTaskIndex === index && taskUpdateCard ? (
                          <div className="block absolute top-7 right-0 p-2 w-1/4 border-2 border-gray-200 ml-auto shadow-sm z-40">
                            <div
                              className="flex justify-center items-center pb-2 cursor-pointer"
                              style={{ borderBottom: "1px solid #e0e0e0" }}
                            >
                              {!task.done ? (
                                <span
                                  className="text-green-500 text-xs md:text-sm font-bold"
                                  onClick={() => {
                                    Event(
                                      markTaskDoneClick.category,
                                      markTaskDoneClick.action,
                                      markTaskDoneClick.label
                                    );
                                    updateTaskDone(
                                      task.task_id,
                                      true,
                                      dispatch,
                                      tasksData
                                    );
                                  }}
                                >
                                  DONE
                                </span>
                              ) : (
                                <span
                                  className="text-yellow-500 text-xs md:text-sm font-bold"
                                  onClick={() => {
                                    Event(
                                      markTaskUnDoneClick.category,
                                      markTaskUnDoneClick.action,
                                      markTaskUnDoneClick.label
                                    );

                                    updateTaskDone(
                                      task.task_id,
                                      false,
                                      dispatch,
                                      tasksData
                                    );
                                  }}
                                >
                                  UNDO
                                </span>
                              )}
                            </div>
                            <div className="flex justify-center items-center pt-2 cursor-pointer">
                              <span
                                className="text-red-500 text-xs md:text-sm font-medium"
                                onClick={() => {
                                  Event(
                                    taskDeleteClick.category,
                                    taskDeleteClick.action,
                                    taskDeleteClick.label
                                  );

                                  deleteTask(task.task_id, dispatch, tasksData);
                                }}
                              >
                                DELETE
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="block mx-3 pb-3"
                        onClick={() => {
                          getTaskDetails(task.task_id, dispatch);
                          Event(
                            taskDetailsOpen.category,
                            taskDetailsOpen.action,
                            taskDetailsOpen.label
                          );
                        }}
                      >
                        <div className="flex pt-5 md:pt-2">
                          <LabelType labelText={task.priority} />
                        </div>
                        <div className="flex mt-3 text-sm text-left justify-start items-center p-1 flex-wrap">
                          {task?.assignedTo?.length > 2
                            ? taskMembers(task.assignedTo)
                            : null}
                        </div>
                        {task.due_date ? (
                          <div className="flex mt-2 text-xs md:text-sm justify-start">
                            <span className="font-bold text-gray-600">
                              Due Date:
                            </span>
                            <span className="ml-2">
                              {task.due_date
                                ? getDateTime(task.due_date)
                                : "Not Available"}
                            </span>
                          </div>
                        ) : null}

                        <div className="flex mt-2 text-xs md:text-sm justify-start">
                          <span className="font-bold text-gray-600">
                            Created on:
                          </span>
                          <span className="ml-2">
                            {task.createdAt
                              ? getDateTime(task.createdAt)
                              : "Not Available"}
                          </span>
                        </div>
                      </div>
                      {task.s3_bucket_files.length ? (
                        <div className="flex w-full justify-center pt-2 pb-2">
                          <audio
                            controls
                            className="block w-11/12"
                            controlsList="nodownload"
                            onPlay={() =>
                              Event(
                                audioListenTaskDetails.category,
                                audioListenTaskDetails.action,
                                audioListenTaskDetails.label
                              )
                            }
                          >
                            <source
                              src={task.s3_bucket_files[0]}
                              type="audio/mp3"
                            />
                          </audio>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-12">
                  <SelectImageQuote
                    image={notAvailableIllustration}
                    quote="Happiness is the real sense of fulfillment that comes from hard work."
                    author="Joseph Barbara"
                  />
                </div>
              )}
            </div>
            {state.slicedData && state.slicedData.length ? (
              <div className="flex justify-center items-center max-w-full mt-12">
                <ReactPaginate
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(state.tasks.length / state.perPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  onPageChange={handlePageClick}
                />
              </div>
            ) : null}
          </>
        ) : (
          <SelectImageQuote
            image={notAvailableIllustration}
            quote="Happiness is the real sense of fulfillment that comes from hard work."
            author="Joseph Barbara"
          />
        )}

        {taskCreationModal ? <NewTask /> : null}
      </div>
    </>
  );
};

export default SheetType;
