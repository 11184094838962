import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import { getCurrentMember } from "../actions/userActions";
import { updateMemberPermission } from "../actions/memberActions";
import { RootState } from "../reducers";
import ReactGA from "react-ga";

import signup from "../images/illustration_members.png";
import loader from "../images/loader.gif";
import PageLoadingComponent from "../components/PageLoadingComponent";

const UpdateMemberPermission = () => {
  let userData: any = JSON.parse(localStorage.getItem("user") || "{}");
  const [permissionChanged, setPermissionChanged] = useState(false);
  const [permission, setPermission] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const savingPermission = useSelector(
    (state: RootState) => state.member.membersLoading
  );
  const successMessage = useSelector(
    (state: RootState) => state.member.successMessage
  );
  const errorMessage = useSelector(
    (state: RootState) => state.member.errorMessage
  );

  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    ReactGA.pageview("/update-permission/:id");
    getCurrentMember(id, dispatch);
    setIsPageLoading(false);
  }, []);

  const currentMemberInfo = useSelector(
    (state: RootState) => state.user.changeMemberAccess
  );

  const updatePermission = async () => {
    const memberData = {
      email: currentMemberInfo.email,
      adminAccess: permission,
    };
    updateMemberPermission(memberData, dispatch);
  };

  return (
    <Fragment>
      {isPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2">
            <SideBarType1 image={signup} url="/dashboard" />
          </div>
          <div className="col-span-5 md:col-span-4">
            <div className="w-5/6 h-screen m-auto top-12 p-4">
              <h2 className="text-2xl md:text-3xl text-black font-bold">
                CHANGE PERMISSION
              </h2>
              <div className="flex mt-24">
                <h2 className="text-2xl font-bold text-gray-800">
                  Workspace:{" "}
                </h2>
                <h2 className="text-2xl font-medium text-gray-600">
                  {" "}
                  {userData.organisation_name || "Not Available Currently"}
                </h2>
              </div>
              <div className="pt-4">
                <label
                  htmlFor="email"
                  className="text-xl font-medium text-gray-500"
                >
                  Email
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-200  p-2 w-full focus:outline-none ${
                    "validEmail"
                      ? "focus:border-green-400"
                      : "focus:border-red-400"
                  } `}
                  placeholder="Enter email address..."
                  value={currentMemberInfo.email}
                  name="email"
                  readOnly
                  disabled
                />
              </div>
              <div className="pt-4">
                <label
                  htmlFor="permission"
                  className="text-xl font-medium  text-gray-500"
                >
                  Permission
                </label>
                <select
                  name="permission"
                  className="border-2 border-gray-200 p-2 w-full focus:outline-none  focus:border-blue-400"
                  value={
                    !permissionChanged
                      ? currentMemberInfo?.adminAccess
                      : permission
                  }
                  onChange={(e: any) => {
                    setPermissionChanged(true);
                    setPermission(e.target.value);
                  }}
                >
                  <option value="false">
                    Member can only view data in workspace
                  </option>
                  <option value="true">
                    Member can update data in workspace
                  </option>
                </select>
              </div>
              <button
                className={`button-type-2 mt-8 w-full ${
                  !permissionChanged
                    ? "cursor-default opacity-50"
                    : "cursor-pointer"
                }`}
                disabled={!permissionChanged || savingPermission}
                onClick={updatePermission}
              >
                UPDATE PERMISSION
              </button>
              <div className="flex flex-col justify-center items-center mt-6">
                {savingPermission ? (
                  <img src={loader} alt="wecest loader" className="w-12" />
                ) : null}
                {successMessage ? (
                  <SuccessResponse response={successMessage} />
                ) : errorMessage ? (
                  <FailureResponse response={errorMessage} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UpdateMemberPermission;
