import React, { useEffect, useState } from "react";

import freePlan from "../images/pricing-free.png";
import monthlyPlan from "../images/pricing-monthly.png";
import yearlyPlan from "../images/pricing-yearly.png";
import quarterlyPlan from "../images/premium.gif";
import greenTick from "../images/green_tick.png";
import closeIconRed from "../images/close_icon_red.png";
import { Link, useLocation } from "react-router-dom";
import { Event } from "../utils/eventTracking";
import {
  proPlanMonthlyHomepage,
  freePlanHomepage,
  proPlanQuarterlyHomepage,
  proPlanYearlyHomepage,
  freePlanPricingPage,
  proPlanMonthlyPricingPage,
  proPlanQuarterlyPricingPage,
  proPlanYearlyPricingPage,
} from "../constants/analytics";

const PricingComponent: React.FC = () => {
  const location = useLocation();

  let freePlanButtonClick: any;
  let monthlyProPlanButtonClick: any;
  let quarterlyProPlanButtonClick: any;
  let yearlyProPlanButtonClick: any;

  if (location.pathname.substring(1) === "") {
    freePlanButtonClick = { ...freePlanHomepage };
    monthlyProPlanButtonClick = { ...proPlanMonthlyHomepage };
    quarterlyProPlanButtonClick = { ...proPlanQuarterlyHomepage };
    yearlyProPlanButtonClick = { ...proPlanYearlyHomepage };
  } else if (location.pathname.substring(1) === "pricing") {
    freePlanButtonClick = { ...freePlanPricingPage };
    monthlyProPlanButtonClick = { ...proPlanMonthlyPricingPage };
    quarterlyProPlanButtonClick = { ...proPlanQuarterlyPricingPage };
    yearlyProPlanButtonClick = { ...proPlanYearlyPricingPage };
  }

  return (
    <div className="flex flex-col items-center h-auto md:h-screen w-full mt-0 md:mt-20 mb-16">
      <div className="flex justify-center w-full md:w-2/4">
        <h1 className="text-3xl md:text-6xl text-center text-light-gray font-medium">
          Select the Perfect Plan For Your Business
        </h1>
      </div>
      <div className="flex items-center justify-center w-full h-full">
        <div className="grid grid-cols-12 gap-2 place-items-center h-full w-full">
          <div className="col-span-12 md:col-span-3">
            <div className="block w-full border-2 border-gray-200 cursor-default rounded-2xl h-auto hover:shadow-2xl hover:pointer">
              <div className="w-full">
                <img src={freePlan} alt="audotask free plan" className="h-48" />
              </div>
              <div className="block p-4">
                <h1 className="text-6xl text-gray1">0$</h1>
                <span className="text-gray2 font-semibold ml-1">
                  Free For Lifetime
                </span>
              </div>
              <div className="block mb-5 pl-1">
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={closeIconRed}
                        alt="audotask free plan features"
                        className="w-4"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Tasks
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Folders & Sheets
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Upto 5 Users in a Workspace
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={closeIconRed}
                        alt="audotask free plan features"
                        className="w-4"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Storage
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-2">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={closeIconRed}
                        alt="audotask free plan features"
                        className="w-4"
                      />
                    </div>
                  </div>
                  <div className="col-span-5">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Custom Task Reminders
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center w-full px-5 pb-5">
                <Link
                  to="/dashboard"
                  className="p-3 w-full text-center text-white font-bold rounded-md focus:outline-none orange1 hover:orange2 transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                  onClick={() => {
                    Event(
                      freePlanButtonClick.category,
                      freePlanButtonClick.action,
                      freePlanButtonClick.label
                    );
                  }}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3">
            <div className="block w-full border-2 border-gray-200 rounded-2xl h-auto hover:shadow-2xl hover:pointer">
              <div className="w-full">
                <img
                  src={yearlyPlan}
                  alt="audotask free plan"
                  className="h-48"
                />
              </div>
              <div className="block p-4">
                <h1 className="text-6xl" style={{ color: "#32FFC8" }}>
                  3.99$
                </h1>
                <span className="text-gray2 font-semibold ml-1">
                  Per User/month when Billed Yearly
                </span>
              </div>
              <div className="block mb-5 pl-1">
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Tasks
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Folders & Sheets
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Users in a Workspace
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Storage
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-2">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Custom Task Reminders
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center w-full px-5 pb-5">
                <Link
                  to="/payment"
                  className="p-3 w-full text-center text-white font-bold rounded-md focus:outline-none  transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                  style={{ background: "#32FFC8" }}
                  onClick={() => {
                    Event(
                      yearlyProPlanButtonClick.category,
                      yearlyProPlanButtonClick.action,
                      yearlyProPlanButtonClick.label
                    );
                  }}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>

          <div className="col-span-12 md:col-span-3">
            <div className="block w-full border-2 border-gray-200 rounded-3xl h-auto hover:shadow-2xl hover:pointer">
              <div className="w-full border-0">
                <img
                  src={quarterlyPlan}
                  alt="audotask free plan"
                  className="h-48 w-80 rounded-xl"
                />
              </div>
              <div className="block p-4">
                <div className="flex">
                  <h1
                    className="text-6xl text-gray1"
                    style={{ color: "#F683CA" }}
                  >
                    4.99$
                  </h1>
                </div>
                <span className="text-gray2 font-semibold ml-1">
                  Per User/month when Billed Quarterly
                </span>
              </div>
              <div className="block mb-5 pl-1">
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Tasks
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Folders & Sheets
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Users in a Workspace
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Storage
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-2">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Custom Task Reminders
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center w-full px-5 pb-5">
                <Link
                  to="/payment"
                  className="p-3 w-full text-center text-white font-bold rounded-md focus:outline-none  transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                  style={{ background: "#F683CA" }}
                  onClick={() => {
                    Event(
                      quarterlyProPlanButtonClick.category,
                      quarterlyProPlanButtonClick.action,
                      quarterlyProPlanButtonClick.label
                    );
                  }}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-3">
            <div className="block w-full border-2 border-gray-200 rounded-3xl h-auto hover:shadow-2xl hover:pointer">
              <div className="w-full border-0">
                <img
                  src={monthlyPlan}
                  alt="audotask free plan"
                  className="h-48"
                />
              </div>
              <div className="block p-4">
                <h1 className="text-6xl text-blue-400">5.99$</h1>
                <span className="text-gray2 font-semibold ml-1">
                  Per User/month when Billed Monthly
                </span>
              </div>
              <div className="block mb-5 pl-1">
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Tasks
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Folders & Sheets
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Users in a Workspace
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-1">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5 pr-2">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Storage
                    </span>
                  </div>
                </div>
                <div className="grid grid-cols-6 py-2">
                  <div className="col-span-1">
                    <div className="flex w-full justify-center">
                      <img
                        src={greenTick}
                        alt="audotask free plan features"
                        className="w-6"
                      />
                    </div>
                  </div>
                  <div className="col-span-5">
                    <span className="flex justify-start text-gray1 font-medium text-sm">
                      Unlimited Custom Task Reminders
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center w-full px-5 pb-5">
                <Link
                  to="/payment"
                  className="p-3 w-full text-center text-white font-bold rounded-md focus:outline-none bg-blue-400 transition duration-500 ease-in-out  transform hover:-translate-y-1 hover:scale-110"
                  onClick={() => {
                    Event(
                      monthlyProPlanButtonClick.category,
                      monthlyProPlanButtonClick.action,
                      monthlyProPlanButtonClick.label
                    );
                  }}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
