import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import logo from "../images/logo.svg";
import menuIcon from "../images/menu-icon.svg";
import menuClose from "../images/menu-close.svg";
import closeIcon from "../images/close_icon_dark.svg";

import { Event } from "../utils/eventTracking";
import {
  iconHoveredHomepage,
  audoTaskClickHomepage,
  iconHoveredFeaturesPage,
  audoTaskClickFeaturesPage,
  iconHoveredPricingPage,
  audoTaskClickPricingPage,
  featuresNavLinkClicked,
  pricingNavLinkClicked,
  getHelpNavLinkClicked,
  loginNavLinkClicked,
  dashboardNavLinkClicked,
  menuOpenClicked,
  menuCloseClicked,
  getProductDemoLinkClicked,
} from "../constants/analytics";

const Navbar = (props: any) => {
  const userLoggedin: boolean = props.userLoggedin || false;
  const navMenu: any = document.getElementById("navbar-expanded-menu");
  const location = useLocation();
  const [showHellobar, setShowHelloBar] = useState(true);
  let logoHover: any;
  let logoClick: any;

  if (location.pathname.substring(1) === "") {
    logoHover = { ...iconHoveredHomepage };
    logoClick = { ...audoTaskClickHomepage };
  } else if (location.pathname.substring(1) === "features") {
    logoHover = { ...iconHoveredFeaturesPage };
    logoClick = { ...audoTaskClickFeaturesPage };
  } else if (location.pathname.substring(1) === "pricing") {
    logoHover = { ...iconHoveredPricingPage };
    logoClick = { ...audoTaskClickPricingPage };
  }

  return (
    <Fragment>
      {showHellobar && (
        <div className="grid grid-cols-12 text-white w-full p-5 bg-wecest-blue sticky inset-x-0 top-0 left-0 z-50 shadow-md">
          <div className="col-span-11">
            <span className="flex justify-center">
              <p className="font-bold text-center">
                Intrested in a Product Demo ?{" "}
                <a
                  className="underline"
                  href="https://calendly.com/audotask/discussion"
                  target="_blank"
                  onClick={() => {
                    Event(
                      getProductDemoLinkClicked.category,
                      getProductDemoLinkClicked.action,
                      getProductDemoLinkClicked.label
                    );
                  }}
                >
                  Click Here
                </a>
              </p>
            </span>
          </div>
          <div className="col-span-1">
            <span className="flex justify-end">
              <img
                src={closeIcon}
                alt="Hellobar close icon"
                className="w-6 h-6 cursor-pointer"
                onClick={() => setShowHelloBar(false)}
              />
            </span>
          </div>
        </div>
      )}
      <div className="flex w-full justify-between bg-wecest-pink text-white mx-auto p-4 font-sans">
        <Link
          to="/"
          onClick={() =>
            Event(logoClick.category, logoClick.action, logoClick.label)
          }
        >
          <div className="flex items-center">
            <h1 className="text-4xl font-bold lg:pl-4">AUD</h1>
            <span className="bg-white p-2 rounded-3xl">
              <img
                src={logo}
                className="spin-animation"
                alt="wecest logo"
                onMouseEnter={() =>
                  Event(logoHover.category, logoHover.action, logoHover.label)
                }
              />
            </span>
            <h1 className="text-4xl font-bold">TASK</h1>
          </div>
        </Link>

        <ul className="lg:flex hidden items-center font-bold">
          <li className="pr-8">
            <Link
              to="/features"
              onClick={() => {
                Event(
                  featuresNavLinkClicked.category,
                  featuresNavLinkClicked.action,
                  featuresNavLinkClicked.label
                );
              }}
            >
              {" "}
              Features{" "}
            </Link>
          </li>
          <li className="pr-8">
            <Link
              to="/pricing"
              onClick={() => {
                Event(
                  pricingNavLinkClicked.category,
                  pricingNavLinkClicked.action,
                  pricingNavLinkClicked.label
                );
              }}
            >
              Pricing
            </Link>
          </li>
          <li className="pr-8">
            <Link
              to="/contact-support"
              onClick={() => {
                Event(
                  getHelpNavLinkClicked.category,
                  getHelpNavLinkClicked.action,
                  getHelpNavLinkClicked.label
                );
              }}
            >
              Get Help
            </Link>
          </li>
          <li className="pr-8">
            {userLoggedin ? (
              <Link
                to="/dashboard"
                onClick={() => {
                  Event(
                    dashboardNavLinkClicked.category,
                    dashboardNavLinkClicked.action,
                    dashboardNavLinkClicked.label
                  );
                }}
              >
                Dashboard
              </Link>
            ) : (
              <Link
                to="/login"
                onClick={() => {
                  Event(
                    loginNavLinkClicked.category,
                    loginNavLinkClicked.action,
                    loginNavLinkClicked.label
                  );
                }}
              >
                Login
              </Link>
            )}
          </li>
        </ul>

        {/* Responsive Menu */}
        <div className="flex items-center lg:hidden">
          <img
            src={menuIcon}
            className="w-10 "
            id="menu-icon"
            onClick={() => {
              Event(
                menuOpenClicked.category,
                menuOpenClicked.action,
                menuOpenClicked.label
              );
              navbarMenu();
            }}
          />
          <img
            src={menuClose}
            className="w-10 hidden "
            id="menu-close-icon"
            onClick={() => {
              Event(
                menuCloseClicked.category,
                menuCloseClicked.action,
                menuCloseClicked.label
              );
              navbarMenu();
            }}
          />
        </div>
      </div>
      <div
        className="block hidden lg:hidden w-full h-screen text-wecest-pink bg-white divide-y divide-gray-300 font-bold text-gray-100 absolute z-10"
        id="navbar-expanded-menu"
      >
        <div className="flex items-center justify-center p-3">
          <Link
            to="/features"
            onClick={() => {
              Event(
                featuresNavLinkClicked.category,
                featuresNavLinkClicked.action,
                featuresNavLinkClicked.label
              );
            }}
          >
            {" "}
            Features{" "}
          </Link>
        </div>
        <div className="flex items-center justify-center p-3">
          <Link
            to="/pricing"
            onClick={() => {
              Event(
                pricingNavLinkClicked.category,
                pricingNavLinkClicked.action,
                pricingNavLinkClicked.label
              );
            }}
          >
            {" "}
            Pricing{" "}
          </Link>
        </div>
        <div className="flex items-center justify-center p-3">
          <Link
            to="/contact-support"
            onClick={() => {
              Event(
                getHelpNavLinkClicked.category,
                getHelpNavLinkClicked.action,
                getHelpNavLinkClicked.label
              );
            }}
          >
            {" "}
            Get Help{" "}
          </Link>
        </div>
        <div className="flex items-center justify-center p-3 pb-4">
          {userLoggedin ? (
            <Link
              to="/dashboard"
              onClick={() => {
                Event(
                  dashboardNavLinkClicked.category,
                  dashboardNavLinkClicked.action,
                  dashboardNavLinkClicked.label
                );
              }}
            >
              Dashboard
            </Link>
          ) : (
            <Link
              to="/login"
              onClick={() => {
                Event(
                  loginNavLinkClicked.category,
                  loginNavLinkClicked.action,
                  loginNavLinkClicked.label
                );
              }}
            >
              Login
            </Link>
          )}
        </div>
      </div>
    </Fragment>
  );
};

// Responsive navbar menu
const navbarMenu = (): void => {
  $("#navbar-expanded-menu,#menu-close-icon, #menu-icon").toggleClass("hidden");
};

export default Navbar;
