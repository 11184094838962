import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.svg";
import checkEmail from "../utils/isEmail";
import loader from "../images/loader.gif";
import axios from "axios";
import PageLoadingComponent from "../components/PageLoadingComponent";
import greenTick from "../images/green_tick.png";
import redCross from "../images/close_icon_red.png";
import linkedIcon from "../images/linkedin_circular_icon.png";
import twitterIcon from "../images/twitter.png";
import ReactGA from "react-ga";
import { Event } from "../utils/eventTracking";

const ProductLaunch = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [savingUser, setSavingUser] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setPageLoading(false);
    ReactGA.pageview("/");
  }, []);

  const validateEmail = (e: any) => {
    const isEmail = checkEmail(e.target.value);
    setEmail(e.target.value);
    isEmail ? setValidEmail(true) : setValidEmail(false);
  };
  const registerUser = async () => {
    try {
      Event(
        "PRODUCT_LAUNCH",
        "User Clicked Register button For Product Launch",
        "PRODUCT_LAUNCH_REGISTER"
      );
      setSavingUser(true);
      setSuccessMessage("");
      setErrorMessage("");
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/support/product-launch`,
        {
          name: fullName,
          address: email,
          subscribed: true,
        }
      );
      setSuccessMessage(data);
      setFullName("");
      setEmail("");
      setValidEmail(false);
      setSavingUser(false);
    } catch (err) {
      setErrorMessage(
        err?.response?.data?.message ||
          "Something went wrong, Please refresh the page & try again!"
      );
      setSavingUser(false);
    }
  };
  const isButtonDisabled = savingUser || !validEmail || !fullName;

  return (
    <>
      {pageLoading ? (
        <PageLoadingComponent />
      ) : (
        <div className="block w-full h-auto md:h-screen">
          <div className="flex w-full justify-between bg-wecest-pink text-white mx-auto p-4 font-sans">
            <Link to="/">
              <div className="flex items-center">
                <h1 className="text-4xl font-bold lg:pl-4">AUD</h1>
                <span className="bg-white p-2 rounded-3xl">
                  <img
                    src={logo}
                    className="spin-animation"
                    alt="wecest logo"
                  />
                </span>
                <h1 className="text-4xl font-bold">TASK</h1>
              </div>
            </Link>
          </div>
          <div className="grid grid-cols-12 h-full w-full bg-audotask-gold">
            <div className="col-span-12 md:col-span-4">
              <div className="flex justify-center items-center h-auto md:h-full">
                <div
                  className="block h-auto w-3/4 shadow-2xl p-5 py-8 rounded-xl"
                  style={{ background: "#FFf" }}
                >
                  <div className="grid grid-cols-12 pb-5">
                    <div className="col-span-9">
                      <small className="font-semibold text-gray-500">
                        Help us spread the word on
                      </small>
                    </div>
                    <div className="col-span-3">
                      <div className="flex w-full justify-around">
                        <a
                          href="https://www.linkedin.com/sharing/share-offsite/?url=https://audotask.app"
                          target="_blank"
                          onClick={() =>
                            Event(
                              "PRODUCT_LAUNCH",
                              "User Clicked on Linkedin Share on product launch page",
                              "PRODUCT_LAUNCH_LINKEDIN_SHARE"
                            )
                          }
                        >
                          <img
                            src={linkedIcon}
                            alt="linkedIcon"
                            className="w-7 h-7"
                          />
                        </a>
                        <a
                          href="https://twitter.com/intent/tweet?text=Recently, I came across this work management platform @audotask where we can use our voice to manage our daily work. They are going live this November. Get yourself an invite here https://audotask.app"
                          target="_blank"
                          onClick={() =>
                            Event(
                              "PRODUCT_LAUNCH",
                              "User Clicked on Twitter Share on product launch page",
                              "PRODUCT_LAUNCH_TWITTER_SHARE"
                            )
                          }
                        >
                          <img
                            src={twitterIcon}
                            alt="twitterIcon"
                            className="w-7 h-7"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <span className="mt-7 font-bold text-xl  text-left text-light-gray pt-2">
                    GOING LIVE
                  </span>
                  <h1 className="pt-9 pb-6 text-2xl md:text-3xl font-bold text-red-400 text-left animate-bounce">
                    This November
                  </h1>
                  <span className="pt-3  text-left text-light-gray font-bold">
                    Your Audotask Invite is Here!
                  </span>
                  <br />
                  <div className="block pt-5 pb-6">
                    <input
                      type="text"
                      className={`border-2 border-gray-200  p-2 w-full  focus:outline-none`}
                      placeholder="Enter your Name Here"
                      name="fullName"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                    <input
                      type="text"
                      className={`border-2 border-gray-200  p-2 w-full  mt-5 focus:outline-none`}
                      placeholder="Enter your email address"
                      name="email"
                      value={email}
                      onChange={validateEmail}
                    />
                    <button
                      className={`button-type-2 mt-5 w-full cursor-pointer focus:outline-none ${
                        isButtonDisabled ? "cursor-default opacity-40" : ""
                      }`}
                      style={{ backgroundColor: "#00CC66" }}
                      onClick={registerUser}
                      disabled={isButtonDisabled}
                    >
                      GET INVITE
                    </button>
                    <div className="flex justify-center">
                      {savingUser ? (
                        <img
                          src={loader}
                          alt="audotask loader"
                          className="w-12 h-12 mt-6"
                        />
                      ) : successMessage ? (
                        <span className="flex w-full font-bold text-green-400 text-sm pt-5">
                          {successMessage}
                        </span>
                      ) : errorMessage ? (
                        <span className="flex w-full font-bold text-red-500 text-sm pt-5">
                          {errorMessage}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-8">
              <div className="flex justify-center md:justify-end items-center w-full md:w-full h-64 md:h-full">
                <iframe
                  width="90%"
                  height="65%"
                  src="https://www.youtube-nocookie.com/embed/K-DvbZSZ8Zc"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer;"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductLaunch;
