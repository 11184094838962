import {
  VOICE_RECORDING_ON,
  SHOW_VOICE_RECORDING_MODAL,
  RECORDING_STOPPED,
  START_RECORDING,
  STORE_STREAM_DATA,
} from "../constants/voiceRecord";

const initialState = {
  showVoiceRecordingModal: false,
  recordingStopped: false,
  recorder: null,
  streamData: null,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case SHOW_VOICE_RECORDING_MODAL:
      return {
        ...state,
        showVoiceRecordingModal: !state.showVoiceRecordingModal,
      };
    case RECORDING_STOPPED:
      return {
        ...state,
        recordingStopped: !state.recordingStopped,
      };
    case START_RECORDING:
      return {
        ...state,
        recorder: action.payload.recorder,
      };
    case STORE_STREAM_DATA:
      return {
        ...state,
        streamData: action.payload,
      };
    default:
      return state;
  }
}
