import React, { Fragment, ReactElement, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getloggedInUserDetails, updateUserInfo } from "../actions/userActions";
import { PAGE_LOADING } from "../constants/user";
import { RootState } from "../reducers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Cookies from "universal-cookie";
import ReactGA from "react-ga";

import SideBarType1 from "../components/SideBar1";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import checkEmail from "../utils/isEmail";
import checkPassword from "../utils/isPassword";

import signup from "../images/illustration_account.png";
import loader from "../images/loader.gif";
import PageLoadingComponent from "../components/PageLoadingComponent";
import { Event } from "../utils/eventTracking";
import { updateAccountInfo } from "../constants/analytics";

const cookies = new Cookies();

const Account: React.FC = (): ReactElement => {
  const dispatch = useDispatch();

  // state initialize
  const [state, setState] = useState({
    phoneNo: "",
    countryCode: "us",
    firstName: "",
    lastName: "",
    gender: "",
    password: "***************",
    isLoading: false,
  });
  const [isFirstNameChanged, setIsFirstNameChanged] = useState(false);
  const [isLastNameChanged, setIsLastNameChanged] = useState(false);
  const [isPhoneNoChanged, setIsPhoneNoChanged] = useState(false);
  const [isGenderChanged, setIsGenderChanged] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [
    accountUpdationSuccessNotification,
    setAccountUpdationSuccessNotification,
  ] = useState(false);
  const [
    accountUpdationFailedNotification,
    setAccountUpdationFailedNotification,
  ] = useState(false);

  const [accountUpdationResponse, setAccountUpdationResponse] = useState("");
  const pageLoading = useSelector((state: RootState) => state.user.pageLoading);
  useEffect(() => {
    ReactGA.pageview("/account-info");
    dispatch({
      type: PAGE_LOADING,
    });
    getloggedInUserDetails(dispatch);
    setIsPageLoading(false);
  }, []);

  const userLoggedin = useSelector(
    (state: RootState) => state.user.loggedInUser
  );

  const getUserCountry = async () => {
    const { data } = await axios.get("http://ip-api.com/json");
    if (data) {
      setState({ ...state, countryCode: data.countryCode.toLowerCase() });
    }
  };

  const updateAccount = async () => {
    if (
      isFirstNameChanged ||
      isLastNameChanged ||
      isPhoneNoChanged ||
      isGenderChanged
    ) {
      Event(
        updateAccountInfo.category,
        updateAccountInfo.action,
        updateAccountInfo.label
      );

      const uid = cookies.get("uid");
      const userData = {
        uid: uid,
        first_name: state.firstName.length
          ? state.firstName
          : userLoggedin.first_name,
        last_name: state.lastName.length
          ? state.lastName
          : userLoggedin.last_name,
        phoneNo: state.phoneNo.length ? state.phoneNo : userLoggedin.phoneNo,
        gender: state.gender.length ? state.gender : userLoggedin.gender,
        email: userLoggedin.email,
        workspaceName: userLoggedin.workspaceName,
      };
      setState({ ...state, isLoading: true });
      const result = await updateUserInfo(dispatch, userData);
      setState({ ...state, isLoading: false });
      if (result.status === 200) {
        setAccountUpdationSuccessNotification(true);
        setAccountUpdationResponse(result.data.message);
      } else {
        setAccountUpdationFailedNotification(true);
        setAccountUpdationResponse(result.message);
      }
    } else {
      alert("You haven't made any changes yet");
    }
  };

  // Phone Input Styling
  const phoneInputStyles = {
    border: "2px solid #e5e7eb",
    width: "100%",
    height: "45px",
    borderRadius: "0px",
    fontFamily: "inherit",
    fontSize: "100%",
  };

  return (
    <Fragment>
      {isPageLoading ? (
        <PageLoadingComponent />
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2">
            <SideBarType1 image={signup} url="/dashboard" />
          </div>

          <div className="col-span-5 md:col-span-4">
            {pageLoading ? (
              <div className="flex justify-center items-center h-screen w-full">
                <PageLoadingComponent />
              </div>
            ) : (
              <div className="w-5/6 h-screen m-auto top-12 p-4">
                <h2 className="text-2xl md:text-3xl text-black font-bold">
                  UPDATE ACCOUNT DETAILS
                </h2>
                <div className="pt-4">
                  <label
                    htmlFor="email"
                    className="text-xl font-medium text-gray-500"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className={`border-2 border-gray-200  p-2 w-full focus:outline-none`}
                    placeholder="Enter email address..."
                    value={userLoggedin.email || ""}
                    name="email"
                    readOnly
                    disabled
                  />
                </div>
                <div className="flex flex-wrap pt-4">
                  <div className="w-full md:w-2/4 md:pr-2">
                    <label
                      htmlFor="firstName"
                      className="text-xl font-medium text-gray-500 font-sans"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="border-2 border-gray-200 p-2 w-full focus:outline-none  focus:border-blue-400"
                      placeholder="First Name"
                      name="firstName"
                      defaultValue={
                        !isFirstNameChanged
                          ? userLoggedin.first_name
                          : state.firstName
                      }
                      onChange={(e) => {
                        setIsFirstNameChanged(true);

                        setState({ ...state, firstName: e.target.value });
                      }}
                    />
                  </div>
                  <div className="w-full md:w-2/4 md:pl-2 mt-4 md:mt-0">
                    <label
                      htmlFor="lastName"
                      className="text-xl font-medium  text-gray-500"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="border-2 border-gray-200 p-2 w-full focus:outline-none  focus:border-blue-400"
                      placeholder="Last Name"
                      name="lastName"
                      defaultValue={
                        !isLastNameChanged
                          ? userLoggedin.last_name
                          : state.lastName
                      }
                      onChange={(e) => {
                        setIsLastNameChanged(true);
                        setState({ ...state, lastName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap pt-4">
                  <label
                    htmlFor=""
                    className="text-xl font-medium  text-gray-500"
                  >
                    Phone No
                  </label>
                  <PhoneInput
                    country={state.countryCode}
                    value={
                      !isPhoneNoChanged ? userLoggedin.phoneNo : state.phoneNo
                    }
                    onChange={(phoneNo) => {
                      setIsPhoneNoChanged(true);
                      setState({ ...state, phoneNo: phoneNo });
                    }}
                    inputStyle={phoneInputStyles}
                    enableLongNumbers={true}
                    placeholder="Enter Phone No"
                    onClick={() => {
                      getUserCountry();
                    }}
                  />
                </div>
                <div className="pt-4">
                  <label
                    htmlFor="password"
                    className="text-xl font-medium text-gray-500"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    className={`border-2 border-gray-200  p-2 w-full focus:outline-none 
                 `}
                    placeholder="Minimum 8 characters with 1 Number, 1 Small and 1 Capital Letter"
                    name="password"
                    readOnly
                    disabled
                    value={state.password}
                    onClick={getUserCountry}
                  />
                </div>
                <div className="flex flex-wrap pt-4">
                  <div className="w-full md:w-2/4 md:pr-2">
                    <label
                      htmlFor="gender"
                      className="text-xl font-medium  text-gray-500"
                    >
                      Gender
                    </label>
                    <select
                      name="gender"
                      className="border-2 border-gray-200 p-2 w-full focus:outline-none  focus:border-blue-400"
                      onChange={(e) => {
                        setState({ ...state, gender: e.target.value });
                        setIsGenderChanged(true);
                      }}
                      value={
                        !isGenderChanged ? userLoggedin.gender : state.gender
                      }
                    >
                      <option disabled value="">
                        Select Gender
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="w-full md:w-2/4 md:pl-2 mt-4 md:mt-0">
                    <label
                      htmlFor="workSpaceCode"
                      className="text-xl font-medium text-gray-500"
                    >
                      Workspace Name
                    </label>

                    <input
                      type="text"
                      className={`border-2 border-gray-200  p-2 w-full focus:outline-none `}
                      placeholder="Join or Create Workspace..."
                      name="workSpaceCode"
                      value={userLoggedin.organisation_name}
                      readOnly
                      disabled
                      onClick={getUserCountry}
                    />
                    <small className="font-bold text-wecest-pink">
                      This is your Workspace Name. All your team members will
                      join using this Workspace Name
                    </small>
                  </div>
                </div>
                <button
                  className={`button-type-2 mt-2 w-full cursor-pointer"
              }`}
                  onClick={updateAccount}
                >
                  UPDATE ACCOUNT
                </button>
                <div className="flex w-5/6  md:w-4/6 pt-6">
                  {!state.isLoading ? null : (
                    <img
                      src={loader}
                      alt="wecest loader"
                      className="w-12 m-auto"
                    />
                  )}
                </div>
                {accountUpdationSuccessNotification ? (
                  <SuccessResponse response={accountUpdationResponse} />
                ) : accountUpdationFailedNotification ? (
                  <FailureResponse response={accountUpdationResponse} />
                ) : null}
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Account;
