import React, { Fragment, useState, useEffect } from "react";
import SideBarType1 from "../components/SideBar1";
import Select from "react-select";
import ReactGA from "react-ga";
import { publicIp } from "public-ip";

import ballonsImage from "../images/illustration_ballons.png";
import loader from "../images/loader.gif";
import checkEmail from "../utils/isEmail";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import SuccessResponse from "../components/SuccessResponse";
import FailureResponse from "../components/FailureResponse";
import PageLoadingComponent from "../components/PageLoadingComponent";

const initialState = {
  email: "",
  phoneNo: "",
  countryCode: "us",
  query_subject: {},
  query_description: "",
  country: "",
  pageLoading: true,
};
function Support() {
  const [validEmail, setValidEmail] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [successMessageResponse, setSuccessMessageResponse] = useState("");
  const [errorMessageResponse, setErrorMessageResponse] = useState("");

  const [state, setState] = useState(initialState);

  const queryOptions = [
    {
      label: "Product Demo and Walkthrough",
      value: "Product Demo and Walkthrough",
    },
    {
      label: "Add More users to Workspace in Pro plan",
      value: "Add More users to Workspace in Pro plan",
    },
    {
      label: "Facing Issues while making payment",
      value: "Facing Issues while making payment",
    },
    {
      label: "Report an Issue",
      value: "Report an Issue",
    },
    {
      label: "Give Feedback",
      value: "Give Feedback",
    },
    { label: "Others", value: "Others" },
  ];

  const validateEmail = (e: any) => {
    setState({ ...state, email: e.target.value });
    const emailCorrect = checkEmail(state.email);
    emailCorrect ? setValidEmail(true) : setValidEmail(false);
  };
  const getUserCountry = async () => {
    try {
      if (!state.country.length) {
        setState({ ...state, pageLoading: false });
      }
      setState({ ...state, pageLoading: false });
    } catch (err) {
      setState({ ...state, pageLoading: false });
    }
  };
  useEffect(() => {
    ReactGA.pageview("/contact-support");
    getUserCountry();
  }, []);

  useEffect(() => {
    if (
      state.email &&
      state.phoneNo &&
      state.query_description.length > 10 &&
      Object.keys(state.query_subject).length
    ) {
      setButtonDisabled(false);
    } else setButtonDisabled(true);
  }, [state]);

  // Phone Input Styling
  const phoneInputStyles = {
    border: "2px solid #e5e7eb",
    width: "100%",
    height: "45px",
    borderRadius: "0px",
    fontFamily: "inherit",
    fontSize: "100%",
  };

  const submitTicket = async () => {
    try {
      setErrorMessageResponse("");
      setSuccessMessageResponse("");
      setShowLoader(true);
      const countryData = await publicIp();
      const ticketData = {
        email: state.email,
        phoneNo: state.phoneNo,
        query_subject: state.query_subject,
        query_description: state.query_description,
        ticket_country_info: countryData || "",
      };
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/support/create-ticket`,
        ticketData
      );
      if (status === 200) {
        setSuccessMessageResponse(data);
        setErrorMessageResponse("");
        setShowLoader(false);
      } else {
        setErrorMessageResponse(data);
        setSuccessMessageResponse("");
        setShowLoader(false);
      }
      setState({ ...initialState, pageLoading: false });
      return;
    } catch (err) {
      const message =
        err?.response?.data?.message ||
        "Something went wrong, Please try again later!";
      setSuccessMessageResponse("");
      setErrorMessageResponse(message);
      setShowLoader(false);
      setState({ ...initialState, pageLoading: false });
    }
  };

  return (
    <Fragment>
      {state.pageLoading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <PageLoadingComponent />
        </div>
      ) : (
        <div className="grid grid-cols-6">
          <div className="col-span-1 md:col-span-2">
            <SideBarType1 image={ballonsImage} url="/" />
          </div>
          <div className="col-span-5 md:col-span-4">
            <h2 className="text-2xl md:text-3xl text-black font-bold text-center py-8">
              CONTACT US
            </h2>
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-wrap w-5/6  pt-4">
                <label htmlFor="" className="text-xl font-medium text-gray-500">
                  Email
                </label>

                <input
                  type="text"
                  className={`border-2 border-gray-200  p-2 w-full focus:outline-none ${
                    validEmail
                      ? "focus:border-green-400"
                      : "focus:border-red-400"
                  } `}
                  placeholder="Email address where we can contact you..."
                  value={state.email}
                  onChange={(e: any) =>
                    setState({ ...state, email: e.target.value })
                  }
                  onSelect={validateEmail}
                  name="email"
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-wrap pt-4 w-5/6">
                <label
                  htmlFor=""
                  className="text-xl font-medium  text-gray-500"
                >
                  Phone No
                </label>
                <PhoneInput
                  country={state.countryCode}
                  value={state.phoneNo}
                  onChange={(phoneNo) =>
                    setState({ ...state, phoneNo: phoneNo })
                  }
                  inputStyle={phoneInputStyles}
                  enableLongNumbers={true}
                  placeholder="Enter Phone No"
                  autocompleteSearch={false}
                />
              </div>
              <div className="flex flex-wrap pt-4 w-5/6">
                <label
                  htmlFor="query_subject"
                  className="text-xl font-medium  text-gray-500"
                >
                  Query Regarding
                </label>
                <Select
                  options={queryOptions}
                  className="text-gray1 p-1 w-full focus:outline-none underline cursor-pointer"
                  isSearchable={false}
                  onChange={(e: any) => {
                    setState({ ...state, query_subject: e });
                  }}
                  value={state.query_subject}
                  placeholder="Select your query subject"
                />
              </div>
              <div className="flex flex-wrap pt-4 w-5/6">
                <label
                  htmlFor="query_description"
                  className="text-xl font-medium  text-gray-500"
                >
                  Description
                </label>
                <textarea
                  name="query_description"
                  placeholder="Anything you would like to add!"
                  className="border-2 border-gray-200  p-2 w-full focus:outline-none"
                  onChange={(e: any) =>
                    setState({ ...state, query_description: e.target.value })
                  }
                  value={state.query_description}
                ></textarea>
              </div>
              <div className="flex flex-wrap pt-6 w-5/6">
                <button
                  className={`button-type-2 mt-2 w-full ${
                    buttonDisabled
                      ? "cursor-default opacity-50"
                      : "cursor-pointer"
                  }`}
                  onClick={submitTicket}
                  disabled={buttonDisabled}
                >
                  SUBMIT
                </button>
              </div>
              {showLoader ? (
                <div className="flex justify-center">
                  <img
                    src={loader}
                    alt="loader audotask ticket submit"
                    className="w-12 h-12"
                  />
                </div>
              ) : null}
              <div className="flex flex-wrap w-5/6 justify-center items-center pt-3">
                {successMessageResponse.length ? (
                  <SuccessResponse response={successMessageResponse} />
                ) : errorMessageResponse.length ? (
                  <FailureResponse response={errorMessageResponse} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Support;
